import React, { useContext, useState } from "react";
import styles from "./settings_tabs.module.css";
import { toast } from "react-toastify";
import { AdminContext } from "../../../../context/AdminContext";

const BannedPhrasesSettings = () => {
  const { bannedPhrases, addBannedPhrase, deleteBannedPhrase } =
    useContext(AdminContext);
  const [newPhrase, setNewPhrase] = useState("");

  const handleAddPhrase = async () => {
    if (!newPhrase.trim()) return;
    await addBannedPhrase(newPhrase.trim());
    setNewPhrase("");
    toast.success("Banned Phrase Added!");
  };

  const handleDeletePhrase = async (phrase) => {
    await deleteBannedPhrase(phrase);
    toast.success("Banned Phrase Deleted!");
  };

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Banned Phrases</p>
      <div className={styles.input_phrase_box}>
        <input
          className={styles.input_banned_phrase}
          placeholder="Phrase to Ban"
          value={newPhrase}
          onChange={(e) => setNewPhrase(e.target.value)}
        />
        <div className={styles.add_button} onClick={handleAddPhrase}>
          Add Banned Phrase
        </div>
      </div>
      <div className={styles.edit_guidelinees_modal}>
        <div className={styles.divider}></div>
        <div className={styles.banned_phrases_layout}>
          {bannedPhrases?.map((item) => (
            <div className={styles.banned_phrase} key={item}>
              <p className={styles.banned_phrase_text}>{item}</p>
              <div
                className={styles.cancel_icon_bg}
                onClick={() => handleDeletePhrase(item)}
              >
                <CancelIcon />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.divider}></div>
        <div className={styles.guideline_layout}>
          <div className={styles.spacer}></div>
        </div>
      </div>
    </div>
  );
};

function CancelIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "block", margin: "auto" }}
    >
      <path
        d="M18 6L6 18M6 6l12 12"
        className={styles.cross_svg}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BannedPhrasesSettings;

// const banned_phrases = [
//   "artificial intelligence",
//   "ai",
//   "robotics",
//   "automation",
//   "chatbot",
//   "virtual assistant",
//   "data mining",
// ];

// const contextInjection = {
//   "(First name)": firstName,
// };
