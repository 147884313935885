import styles from "./landingnavbar.module.css";
import { useNavigate } from "react-router";

import { useMediaQuery } from "@material-ui/core";
import ChevronRightIcon from "../../assets/svg/RightArrow";

export default function NavbarMenu(props) {
  const navigate = useNavigate();

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Pricing", path: "/pricing" },
    { name: "FAQ", path: "/faq" },
    { name: "Resources", path: "/resources" },
    // { name: "Careers", path: "/careers" },
  ];

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className={styles.navbar_menu}>
      <div className={styles.navbar_menu_content}>
        {navItems.map((item) => (
          <p
            className={styles.menu_item_mobile}
            onClick={() => navigateTo(item.path)}
          >
            {item.name}
            <ChevronRightIcon />
          </p>
        ))}
      </div>
    </div>
  );
}
//navmenu-arrow.png
