import styles from "./settings_tabs.module.css";

const LineDivider = () => {
  return <div className={styles.lineDivider}></div>;
};

export default function NotificationSettings() {
  const notifications_menu = [
    {
      id: 1,
      title: "Email Notifications",
      subtitle:
        "Get emails to find out what’s going on when you’re online. You can turn these off.",
      icon: <div></div>,
      menuOptions: [
        {
          id: 11,
          title: "News and updates",
          subtitle: "News about product and feature updates.",
        },
        {
          id: 11,
          title: "Tips and tutorials",
          subtitle: "Tips on getting more out of United Market",
        },
        {
          id: 13,
          title: "Reminders",
          subtitle:
            "These are notifications to remind you of updates you might have missed.",
        },
        {
          id: 14,
          title: "Your Submission Updates",
          subtitle:
            "These are notifications about your current active submissions",
        },
        {
          id: 15,
          title: "New Opportunities",
          subtitle: "Updates about newly posted opportunities on United Market",
        },
      ],
    },
    {
      id: 2,
      title: "Mobile Notifications",
      subtitle:
        "Get emails to find out what’s going on when you’re online. You can turn these off.",
      icon: <div></div>,
      menuOptions: [
        {
          id: 21,
          title: "Notification one",
          subtitle: "Explanation for the first notification",
        },
        {
          id: 22,
          title: "Notification two",
          subtitle: "Explanation for the second notification",
        },
        {
          id: 23,
          title: "Notification three",
          subtitle: "Explanation for the third notification",
        },
      ],
    },
    {
      id: 3,
      title: "Push Notifications",
      subtitle:
        "Get emails to find out what’s going on when you’re online. You can turn these off.",
      icon: <div></div>,
      menuOptions: [
        {
          id: 31,
          title: "Notification one",
          subtitle: "Explanation for the first notification",
        },
        {
          id: 31,
          title: "Notification two",
          subtitle: "Explanation for the second notification",
        },
        {
          id: 33,
          title: "Notification three",
          subtitle: "Explanation for the third notification",
        },
      ],
    },
  ];

  const NotificationsMenu = () => {
    return (
      <div className={styles.notifications_menu}>
        {notifications_menu.map((notificationGroup, index) => (
          <div>
            <div className={styles.notifications_group}>
              <div className={styles.notifications_group_left}>
                <p className={styles.notification_title}>
                  {notificationGroup.title}
                </p>
                <p className={styles.notification_subtitle}>
                  {notificationGroup.subtitle}
                </p>
              </div>
              <div className={styles.notifications_group_right}>
                {notificationGroup.menuOptions.map((notifications, index) => (
                  <div className={styles.notification_item}>
                    <label className={styles.switchBox}>
                      <input
                        type="checkbox"
                        className={styles.checkbox}
                        checked
                      />
                      <div className={styles.slider}></div>
                    </label>
                    <div className={styles.notification_item_text}>
                      <p className={styles.notification_title}>
                        {notifications.title}
                      </p>
                      <p className={styles.notification_subtitle}>
                        {notifications.subtitle}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <LineDivider />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white">
      {/* <p>Notification Settings</p> */}
      <NotificationsMenu />
    </div>
  );
}
