import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import PricingComponent from "../../components/landing_components/PricingPage/PricingComponent";

const PricingPage = () => {
  return (
    <>
      <LandingNavbar
        childComponent={<PricingComponent />}
        activeText="Pricing"
      />
    </>
  );
};

export default PricingPage;
