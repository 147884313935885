// import "./chat.css";
import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./inbox.module.css";
import InboxSideProfile from "./inboxSideProfile";
import InboxMessageInput from "./inboxMessageInput";
import { AuthContext } from "../../../context/AuthContext";

// import "../Assets/selfProfilePic.png";

import artist_data from "../../../data/artist_data";

export default function InboxComponent() {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { chatbotData, messages, conversations } = useContext(AuthContext);

  console.log(chatbotData);
  console.log(messages);

  const textAreaRef = useRef(null);
  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  const selectChat = (chat) => {
    setSelectedChat(chat);
  };

  const filteredChatChannels = conversations.filter((chat) =>
    chat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const chatChannels = filteredChatChannels.map((chat) => (
    <React.Fragment key={chat.id}>
      <div className={styles.chat_card_box}>
        <div
          onClick={() => selectChat(chat)}
          className={`${styles.chat_card} ${
            selectedChat?.id === chat.id
              ? styles.selected_chat
              : styles.non_selected_chat
          }`}
        >
          <div className={`${styles.chat_info} `}>
            <div className={styles.chat_all_info}>
              <div className={styles.chat_user_info}>
                <div className={styles.chat_user_info_left}>
                  <div className={styles.chat_user_avatar}>
                    <img
                      src={chat.prospect_image}
                      className={styles.chat_user_avatar_image}
                      alt={"Profile"}
                    />
                  </div>
                  <div className={styles.user_info_box}>
                    <p className={styles.chat_user_name}>{chat.name}</p>
                    <p className={styles.chat_user_role}>{chat.position}</p>
                  </div>
                </div>
                <div className={styles.chat_time_stamp}>
                  <p className={styles.chat_time_stamp_text}>5:34pm</p>
                </div>
              </div>
              <div className={styles.chat_recent_message}>
                <p className={styles.chat_message}>
                  {chat.messages[chat.messages.length - 1].content}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));

  const MessageComponent = ({ selectedChat }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    useEffect(scrollToBottom, [selectedChat]);

    return (
      <div className={styles.message_wrapper}>
        {selectedChat.messages.map((message, index) => {
          const isSenderJustin = message.sender === "Justin Cose";

          return (
            <div
              key={index}
              className={`${styles.message_container} ${
                isSenderJustin ? styles.reversed : ""
              }`}
            >
              <div
                className={`${styles.message_avatar} ${
                  isSenderJustin
                    ? styles.message_avatar_self
                    : styles.message_avatar_other
                }`}
              >
                <img
                  className={styles.message_avatar_image}
                  src={
                    isSenderJustin ? "selfpfp.jpg" : selectedChat.prospect_image
                  }
                  alt={isSenderJustin ? "Self" : "Other"}
                />
              </div>
              <div
                className={`${styles.message_content} ${
                  isSenderJustin
                    ? styles.self_message_content
                    : styles.other_message_content
                }`}
              >
                <div
                  className={`${styles.message_info} ${
                    isSenderJustin
                      ? styles.self_message_info
                      : styles.other_message_info
                  }`}
                >
                  <p className={styles.message_sender}>{message.sender}</p>
                  <span className={styles.message_time}>
                    {message.timestamp}
                  </span>
                </div>

                <div
                  className={`${styles.message_text_content} ${
                    isSenderJustin
                      ? styles.self_message_text_content
                      : styles.other_message_text_content
                  }`}
                >
                  <p className={styles.message_text}>{message.content}</p>
                </div>
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>
    );
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.sidebar}>
        {/* SEARCH BUTTON */}
        <div className={styles.search_container}>
          <input
            className={styles.search_input}
            value={searchQuery}
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
          />
        </div>
        {/* <div className={styles.divider}></div> */}
        <div className={styles.chat_list}>{chatChannels}</div>
      </div>
      {selectedChat ? (
        <>
          <div className={styles.chat_container}>
            <div className={styles.chat_layout}>
              <div className={styles.chat_layout_content_info}>
                {/* <div className={styles.chat_header}>
                <div className={styles.chat_avatar}></div>
                <div className={styles.chat_name}>{selectedChat.name}</div>
              </div> */}
                <div className={styles.chat_messages}>
                  <MessageComponent selectedChat={selectedChat} />
                </div>
              </div>
              <div className={styles.chat_input}>
                <InboxMessageInput />
              </div>
            </div>
          </div>
          <InboxSideProfile selectedChat={selectedChat} />
        </>
      ) : (
        <div className={styles.no_chat_selected}>
          <div className={styles.select_chat_box}>
            <p className={styles.select_chat_text}>Select a Chat</p>
            <img
              className={styles.select_chat_image}
              src="/images/GeneralIcons/NoSelectedChatIcon.svg"
              alt="no chat"
            ></img>
          </div>
        </div>
      )}
      {/* {selectedChat ? (
        <InboxSideProfile selectedChat={selectedChat} />
      ) : (
        <div className={styles.no_chat_selected}>
          <div className={styles.select_chat_box}>
            <p className={styles.select_chat_text}>Select a Chat</p>
            <img className={styles.select_chat_image}></img>
          </div>
        </div>
      )} */}
    </div>
  );
}
