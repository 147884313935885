import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";

const SupportPage = () => {
  return (
    <>
      <LandingNavbar
        childComponent={<p>Support Page</p>}
        activeText="Support"
      />
    </>
  );
};

export default SupportPage;
