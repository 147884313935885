import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import FAQComponents from "../../components/landing_components/faq_page/faq_page";

const FAQPage = () => {
  return (
    <>
      <LandingNavbar childComponent={<FAQComponents />} activeText="FAQ" />
    </>
  );
};

export default FAQPage;
