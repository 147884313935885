import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";

const MissionPage = () => {
  return (
    <>
      <LandingNavbar
        childComponent={<p>Mission Page</p>}
        activeText="Mission"
      />
    </>
  );
};

export default MissionPage;
