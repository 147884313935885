import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./2fa.module.css";

const TWOFA = () => {
  return (
    <div className={styles.faq_components}>
      <div className={styles.faq_content}>
        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Two-Factor Authentication</p>
          </div>
        </div>
        <div className={styles.page_line_break}></div>
        <div
          style={{
            fontFamily: "Arial, sans-serif",
            lineHeight: "1.6",
            margin: "20px",
            textAlign: "start",
            width: "50%",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Two-Factor Authentication
          </div>

          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Overview
            </div>
            <div>
              Enable Two-Factor Authentication directly from ReplyAssist, to
              avoid account frequently getting disconnected.
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              How does it work?
            </div>
            <div>
              ReplyAssist will become an authentication app for your LinkedIn
              account. Whenever your account needs verification, ReplyAssist
              will generate security codes and send them to LinkedIn
              automatically to prevent account disconnection.
            </div>
            <div style={{ marginTop: "10px" }}>
              You can use the mobile authenticator app to sign in to LinkedIn
              using the QR code you will find in ReplyAssist. Your LinkedIn
              account will still be protected by 2 Factor Authenticator, so your
              account is completely safe.
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              How to enable 2FA?
            </div>
            <div style={{ fontWeight: "bold", color: "red" }}>
              NOTE: Before enabling 2FA in ReplyAssist, please make sure you
              have 2FA turned off in LinkedIn. In case you have it turned on,
              you won't be able to set 2FA with ReplyAssist.
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              LinkedIn setup
            </div>
            <div>
              To prepare for 2FA, first access your LinkedIn account and check
              if you have 2FA enabled or not.
            </div>
            <div style={{ marginTop: "10px" }}>
              If 2FA is enabled on LinkedIn, please:
            </div>
            <div style={{ marginTop: "10px", fontWeight: "bold" }}>
              Turn off 2FA
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              ReplyAssist setup
            </div>
            <div>Once is turned off, follow the steps below:</div>
            <div
              style={{ fontWeight: "bold", color: "red", marginTop: "10px" }}
            >
              NOTE: Please avoid toggling 2FA on and off multiple times in
              ReplyAssist.
            </div>
            <div style={{ marginTop: "10px" }}>
              Go to Settings &gt; LinkedIn Account Settings &gt; toggle "Enable
              Two-Step Authentication"
            </div>
            <div style={{ marginTop: "10px" }}>
              If you are asked for a verification code, please go to your
              LinkedIn email inbox, and enter the code in the field
            </div>
            <div style={{ marginTop: "10px" }}>Click APPLY</div>
            <div style={{ marginTop: "10px" }}>
              Once you click apply, click on Show QR code
            </div>
            <div style={{ marginTop: "10px" }}>
              Once the QR code is shown, scan it with your authenticator app.
            </div>
            <div style={{ marginTop: "10px" }}>
              Now, your account is still protected by 2FA but it will not have
              any disconnection issues. We strongly recommend to use this option
              to have your account always connected and protected as well.
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            We hope you found this article helpful! However, if you have any
            questions, please contact us via the support chat or at
            support@replyassist.com
          </div>
        </div>
      </div>
      <div className={styles.spacer}></div>
    </div>
  );
};

export default TWOFA;
