import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";

import styles from "./auth.module.css";

import GeneralSignUp from "./SignUpPages/GeneralSignUp";
import CompanyInfo from "./SignUpPages/CompanyInfo";
import CompanyBrands from "./SignUpPages/CompanyBrands";
import ChooseSubscription from "./SignUpPages/ChooseSubscription";
import { AuthContext } from "../../context/AuthContext";

const company_stages = [
  { component: GeneralSignUp, stage: 1 },
  { component: CompanyInfo, stage: 2 },
  { component: CompanyBrands, stage: 3 },
  { component: ChooseSubscription, stage: 4 },
];

const SignUpComponent = () => {
  const { profile } = useContext(AuthContext);
  const [userId, setUserId] = useState(null);
  const [currentStage, setCurrentStage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    // if (localStorage.getItem("USER_ID")) {
    //   setUserId(localStorage.getItem("USER_ID"));
    //   setCurrentStage(3);
    // }
    setCurrentStage(1);
  }, [localStorage.getItem("USER_ID")]);

  useEffect(() => {
    console.log(`Current stage: ${currentStage}`);
  }, [currentStage]);

  // Function to go to the next stage
  const goToNextStage = (newUserId) => {
    if (newUserId) {
      setUserId(newUserId);
    }
    if (currentStage < company_stages.length) {
      setCurrentStage(currentStage + 1);
    }
  };

  // Function to go back to the previous stage
  const goBackStage = () => {
    if (currentStage > 1) {
      setCurrentStage(currentStage - 1);
    }
  };

  // Find the current component based on the stage
  const CurrentComponent = company_stages.find(
    (stage) => stage.stage === currentStage
  )?.component;

  if (!CurrentComponent) {
    console.error(`No component found for stage: ${currentStage}`);
    return <div>Something went wrong, please try again later.</div>;
  }

  return (
    <div className={styles.home_components}>
      <CurrentComponent
        onNext={goToNextStage}
        onBack={goBackStage}
        userId={userId}
      />
    </div>
  );
};

export default SignUpComponent;
