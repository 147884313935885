import React, { createContext, useState, useEffect } from "react";

import { db } from "../services/firebase";
import { auth } from "../services/firebase";
import axios from "axios";
import { toast } from "react-toastify";

import {
  orderBy,
  query,
  collection,
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

export const ChatbotTuningContext = createContext();

//7oeUnHVAJfH0hbKti3dW

export const ChatbotTuningProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [fineTunedModels, setFineTunedModels] = useState([]);
  const [trainingData, setTrainingData] = useState([]);

  const DEVELOPMENT_API_KEY = "RA-q7L5m2P3nT8vY4dH0rJ6cX1zG9wA3fU";
  const fetchFineTunedModels = async () => {
    try {
      const response = await axios.get(
        "https://api.development.replyassist.com/fetch-fine-tuned-models",
        {
          headers: {
            Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
          },
        }
      );
      setFineTunedModels(response.data.data);
    } catch (error) {
      console.error("Error fetching fine-tuned models:", error);
    }
  };

  const createModel = async (modelData) => {
    try {
      const response = await axios.post(
        "https://api.development.replyassist.com/create-fine-tuned-model",
        modelData,
        {
          headers: {
            Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
          },
        }
      );

      const newModel = response.data;
      setFineTunedModels((prevModels) => [newModel, ...prevModels]);
    } catch (error) {
      console.error("Error sending data to the backend:", error);
    }
  };

  const fetchTrainingDataFile = async (fileId) => {
    try {
      const response = await axios.get(
        `https://api.development.replyassist.com/fetch-training-data-file?fileId=${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
          },
        }
      );

      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching training data file:", error);
      return null;
    }
  };

  const fetchTrainingData = async () => {
    try {
      const trainingDataRef = collection(db, "TrainingData");
      const querySnapshot = await getDocs(
        query(trainingDataRef, orderBy("last_updated", "desc"))
      );

      let trainingDataWithPoints = [];

      for (const doc of querySnapshot.docs) {
        const trainingDocData = { id: doc.id, ...doc.data(), dataPoints: [] };

        const dataPointsRef = collection(doc.ref, "DataPoints");
        const dataPointsQuery = query(
          dataPointsRef,
          orderBy("created_at", "desc")
        );
        const dataPointsSnapshot = await getDocs(dataPointsQuery);

        const dataPoints = dataPointsSnapshot.docs.map((dataPointDoc) => ({
          id: dataPointDoc.id,
          ...dataPointDoc.data(),
        }));

        trainingDataWithPoints.push({ ...trainingDocData, dataPoints });
      }

      console.log(
        "Fetched Training Data with Data Points:",
        trainingDataWithPoints
      );
      setTrainingData(trainingDataWithPoints);
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };

  const createNewTrainingData = async (
    newTrainingDataName,
    formattedDataPoints = []
  ) => {
    try {
      const newTrainingData = {
        name: newTrainingDataName,
        created_at: Date.now(),
        last_updated: Date.now(),
      };

      const trainingDataRef = collection(db, "TrainingData");
      const docRef = await addDoc(trainingDataRef, newTrainingData);

      console.log("New training data created successfully, ID:", docRef.id);

      await updateDoc(docRef, { id: docRef.id });

      // If formattedDataPoints is provided, add them to the DataPoints subcollection
      if (formattedDataPoints.length > 0) {
        const dataPointsRef = collection(
          db,
          "TrainingData",
          docRef.id,
          "DataPoints"
        );
        for (const dataPoint of formattedDataPoints) {
          await addDoc(dataPointsRef, dataPoint);
        }
      }

      const newTrainingDataWithID = {
        id: docRef.id,
        ...newTrainingData,
        dataPoints: formattedDataPoints,
      };

      setTrainingData((prevTrainingData) => [
        newTrainingDataWithID,
        ...prevTrainingData,
      ]);

      return docRef.id;
    } catch (error) {
      console.error("Error creating new training data:", error);
      throw error;
    }
  };

  const replaceTrainingData = async (trainingDataId, newDataPoints) => {
    try {
      const dataPointsRef = collection(
        db,
        "TrainingData",
        trainingDataId,
        "DataPoints"
      );

      const querySnapshot = await getDocs(query(dataPointsRef));
      const deletePromises = [];
      querySnapshot.forEach((doc) => {
        deletePromises.push(deleteDoc(doc.ref));
      });
      await Promise.all(deletePromises);

      const addPromises = newDataPoints.map((dataPoint) =>
        addDoc(dataPointsRef, dataPoint)
      );

      await Promise.all(addPromises);

      await updateTrainingDataLastUpdated(trainingDataId);

      setTrainingData((prevTrainingData) => {
        return prevTrainingData.map((trainingData) => {
          if (trainingData.id === trainingDataId) {
            return { ...trainingData, dataPoints: newDataPoints };
          }
          return trainingData;
        });
      });

      console.log("Training data replaced successfully");
    } catch (error) {
      console.error("Error replacing training data:", error);
      throw error;
    }
  };

  const updateTrainingDataLastUpdated = async (trainingDataId) => {
    try {
      const trainingDataDocRef = doc(db, "TrainingData", trainingDataId);

      await updateDoc(trainingDataDocRef, {
        last_updated: Date.now(),
      });

      console.log("Training data last updated timestamp successfully updated");

      setTrainingData((prevTrainingData) => {
        const updatedTrainingData = [...prevTrainingData];

        const index = updatedTrainingData.findIndex(
          (td) => td.id === trainingDataId
        );

        if (index !== -1) {
          updatedTrainingData[index] = {
            ...updatedTrainingData[index],
            last_updated: Date.now(),
          };

          updatedTrainingData.sort((a, b) => b.last_updated - a.last_updated);
        }

        return updatedTrainingData;
      });
    } catch (error) {
      console.error(
        "Error updating training data's last updated timestamp:",
        error
      );
      throw error;
    }
  };

  const updateDataFields = async (
    trainingDocId,
    dataPointDocId,
    newDataArray
  ) => {
    try {
      const dataPointsDocRef = doc(
        db,
        "TrainingData",
        trainingDocId,
        "DataPoints",
        dataPointDocId
      );

      await updateDoc(dataPointsDocRef, {
        data_array: newDataArray,
      });

      await updateTrainingDataLastUpdated(trainingDocId);

      console.log("Data array updated successfully");
    } catch (error) {
      console.error("Error updating data array:", error);
    }
  };

  const createNewEntryLine = async (trainingDocId, newDataArray) => {
    try {
      const dataPointsRef = collection(
        db,
        "TrainingData",
        trainingDocId,
        "DataPoints"
      );
      const newDocData = {
        data_array: newDataArray,
        created_at: Date.now(),
        id: null,
      };

      const docRef = await addDoc(dataPointsRef, newDocData);
      console.log("New entry line added successfully, ID:", docRef.id);

      await updateDoc(docRef, {
        id: docRef.id,
      });

      return docRef.id;
    } catch (error) {
      console.error("Error creating new entry line:", error);
      throw error;
    }
  };

  const deleteDataPoint = async (trainingDocId, dataPointDocId) => {
    try {
      const dataPointsDocRef = doc(
        db,
        "TrainingData",
        trainingDocId,
        "DataPoints",
        dataPointDocId
      );

      await deleteDoc(dataPointsDocRef);

      await updateTrainingDataLastUpdated(trainingDocId);

      console.log("Training Data Point deleted successfully:", dataPointDocId);
    } catch (error) {
      console.error("Error deleting Training Data Point:", error);
      throw error;
    }
  };

  const readUploadedTrainingDataFile = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();

        reader.onload = (event) => {
          const allLines = event.target.result.split(/\r\n|\n/);
          const validLines = allLines.filter(
            (line) =>
              line.startsWith("//Section") ||
              (!line.startsWith("/") && line.trim() !== "")
          );

          const validJsonlString = validLines.join("\n");

          resolve(validJsonlString);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsText(file);
      } catch (error) {
        toast.error("Error Creating Training Data");
        console.error(error);
        reject(error);
      }
    });
  };

  const updateDevelopmentModel = async (modelObject) => {
    try {
      const modelDocRef = doc(db, "FineTunedModels", "ChatbotModel");
      const updatedData = {
        current_development_model: modelObject,
      };
      await updateDoc(modelDocRef, updatedData);
      toast.success("Active Development Model Updated");
      console.log("Development model saved successfully");
    } catch (error) {
      console.error("Error saving development model:", error);
      throw error;
    }
  };

  const updateProductionModel = async (modelObject) => {
    try {
      const modelDocRef = doc(db, "FineTunedModels", "ChatbotModel");
      const updatedData = {
        current_production_model: modelObject,
      };
      await updateDoc(modelDocRef, updatedData);
      toast.success("Active Development Model Updated");
      console.log("Development model saved successfully");
    } catch (error) {
      console.error("Error saving development model:", error);
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchFineTunedModels();
        fetchTrainingData();
      } else {
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <ChatbotTuningContext.Provider
      value={{
        error,
        isLoading,
        fineTunedModels,
        createModel,
        trainingData,
        updateDataFields,
        createNewEntryLine,
        deleteDataPoint,
        createNewTrainingData,
        fetchTrainingDataFile,
        readUploadedTrainingDataFile,
        replaceTrainingData,
        updateDevelopmentModel,
      }}
    >
      {children}
    </ChatbotTuningContext.Provider>
  );
};
