import React, { useState } from "react";
import { useContext } from "react";
import styles from "./popups.module.css";
import { AuthContext } from "../../context/AuthContext";

const EditDealStage = ({ isOpen, onClose }) => {
  const { activeDealStages, inactiveDealStages } = useContext(AuthContext);

  // Define a new state variable to determine which stages to display
  const [displayedStages, setDisplayedStages] = useState(activeDealStages);

  if (!isOpen) return null;

  return (
    <div className={styles.fullscreen_modal}>
      <div className={styles.modal_body}>
        <div className={styles.modal_header}>
          <div className={styles.header_content}>
            <p className={styles.modal_title}>Edit Deal Stages</p>
            <button onClick={onClose}>Close</button>
          </div>
        </div>
        <div className={styles.modal_content}>
          <div className={styles.modal_title_section}>
            <p className={styles.modal_section_title}>Pipeline</p>
          </div>
          <div className={styles.deal_pipelines}>
            <div
              className={`${styles.deal_pipeline} ${
                displayedStages === activeDealStages
                  ? styles.deal_pipeline_active
                  : ""
              }`}
              onClick={() => setDisplayedStages(activeDealStages)}
            >
              <p className={styles.deal_pipeline_text}>Active Deal Stages</p>
            </div>
            <div
              className={`${styles.deal_pipeline} ${
                displayedStages === inactiveDealStages
                  ? styles.deal_pipeline_active
                  : ""
              }`}
              onClick={() => setDisplayedStages(inactiveDealStages)}
            >
              <p className={styles.deal_pipeline_text}>Inactive Deal Stages</p>
            </div>
          </div>
          <div className={styles.modal_title_section}>
            <p className={styles.modal_section_title}>Stages</p>
          </div>
          {displayedStages.map((stage, index) => (
            <div key={index} className={styles.deal_stage}>
              <p className={styles.deal_stage_text}>{stage}</p>
              <div className={styles.deal_stage_actions}></div>
            </div>
          ))}
        </div>
        <div className={styles.modal_footer}>
          <div className={styles.buttons}>
            <div className={styles.cancel_button} onClick={onClose}>
              Cancel
            </div>
            <div className={styles.save_button}>Save</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDealStage;
