// CookiePopup.js
import React from "react";
import { useState } from "react";
import styles from "./cookie.module.css";

const CookiePopup = ({}) => {
  const [isVisible, setIsVisible] = useState(
    localStorage.getItem("cookieConsent") !== "accepted"
  );

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
  };

  const handleDeny = () => {
    localStorage.setItem("cookieConsent", "denied");
    setIsVisible(false);
  };

  if (!isVisible) return null;
  return (
    <>
      <div className={styles.overlay}>
        <div className={styles.cookiePopup}>
          <p className={styles.cookie_title}>Your Cookie Preferences</p>
          <div className={styles.divider}></div>
          <p className={styles.cookie_info}>
            This website employs cookies to ensure that our users receive the
            optimal browsing experience. By using cookies, we can tailor content
            and ads to your preferences, provide social media features, and
            analyze our traffic.
          </p>
          <div className={styles.buttons}>
            <div onClick={handleDeny} className={styles.deny_button}>
              Use Necessary Only
            </div>
            <div onClick={handleAccept} className={styles.accept_button}>
              Allow All Cookies
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePopup;
