import React from "react";
import styles from "./followups.module.css";
import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import InterestTag from "../universal_components/interest_tag";

const useStyles = makeStyles({
  datePicker: {
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      padding: 0,
      height: "40px",
      paddingLeft: 14,
    },
  },
});

export default function FollowUpCard(props) {
  console.log(props);
  const followUp = props.followup;
  const prospect = props.followup.prospect;
  // const [selectedDate, handleDateChange] = React.useState(new Date());
  const [selectedDate, setSelectedDate] = useState(
    prospect.followup ? prospect.followup.toDate() : new Date()
  );

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    // updateProspectFollowup(props.prospect.id, newValue);
  };

  const toTitleCase = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const classes = useStyles();

  return (
    <div className={styles.follow_up_layout}>
      <div className={styles.item_layout_big}>
        <div className={styles.user_profile_layout}>
          <img
            src={prospect.profile_image}
            alt="profile"
            className={styles.user_profile_image}
          ></img>
          <div className={styles.user_profile_text}>
            <p className={styles.user_profile_name}>{prospect.name}</p>
          </div>
        </div>
      </div>
      <div className={styles.item_layout_small}>
        <div className={styles.follow_up_hover}>
          <p className={styles.follow_up_info_text}>
            {followUp.automated ? "Automated" : "Manual"}
          </p>
        </div>
      </div>
      {/* <div className={styles.item_layout_small}>
        <p className={styles.follow_up_info_text}>None</p>
      </div> */}
      <div className={styles.item_layout_small}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className={classes.datePicker}>
            <DateTimePicker
              value={selectedDate}
              onChange={(newValue) => handleDateChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    style: { padding: 0 },
                  }}
                />
              )}
            />
          </div>
        </LocalizationProvider>
      </div>

      <div className={styles.item_layout_small}>
        <p className={styles.follow_up_info_text}>
          Hey! Just following up here, hope all is well.
        </p>
      </div>
      <div className={styles.item_layout_small}>
        <p className={styles.follow_up_info_text}>
          {toTitleCase(prospect.deal_stage)}
        </p>
      </div>
      <div className={styles.item_layout_small}>
        <InterestTag level={prospect.interest_level.toLowerCase()} />
      </div>
      {/* <div>
        <div className={styles.edit_button}>
          <p className={styles.follow_up_info_text}>Edit</p>
        </div>
      </div> */}
      {/* <div className={styles.follow_up_layout}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            // label="Basic date time picker"
            classes={{ root: classes.root }}
            value={selectedDate}
            onChange={(newValue) => handleDateChange(newValue)}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{ textField: { size: "small" } }}
          />
        </LocalizationProvider>
      </div> */}
      {/* <div className={styles.deal_stage_layout}>
        <p className={styles.cell_text_content}>{props.prospect.deal_stage}</p>
      </div>
      <div className={styles.interest_level_layout}>
        <div className={styles.interest_level_tag}>High Interest</div>
      </div> */}
    </div>
  );
}
