import React, { useState, useContext } from "react";
import styles from "./follow_up_detection.module.css";
import { ChatbotTestingContext } from "../../../context/ChatbotTestingContext";

function getCurrentDateString() {
  const date = new Date();
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = days[date.getDay()];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `Today is ${dayOfWeek}, ${month} ${day}, ${year}. `;
}

function daysUntil(dateString) {
  const targetDate = new Date(dateString);

  const today = new Date();
  const todayAtMidnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const difference = targetDate - todayAtMidnight;

  const days = difference / (1000 * 60 * 60 * 24);

  return Math.round(days);
}

const detectTimestampPrompt =
  getCurrentDateString() +
  "You are an AI whos only job is to analyze a user message, and return with a timestamp estimating the amount of days the message is hinting at. Respond with only a string representing the day in ISO 8601 format.";

const FollowUpDetectionPage = () => {
  const { detectIntent, detectTimestamp } = useContext(ChatbotTestingContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState({});

  const handleIntentDetection = async () => {
    const text = searchTerm;
    setIsLoading(true);
    try {
      const intent = await detectIntent(text);
      //   console.log(intent);
      let detectedTime;

      const adjustFollowUp = intent.intentName === "Adjust Follow Up Intent";

      if (adjustFollowUp) {
        detectedTime = await detectTimestamp(detectTimestampPrompt, text);
      }

      setResult({
        intentTriggered: intent.intentName,
        // returnedResponse: detectedTime ? detectedTime : "N/A",
        followUpIn: daysUntil(detectedTime) + " Days",
        followUpOn: detectedTime,
      });
      //   console.log(result);
    } catch (error) {
      console.error("Error detecting intent:", error);
    } finally {
      setIsLoading(false);
    }
  };
  //setIntentResult(intent);
  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Follow Up Detection</p>
      <div className={styles.edit_guidelinees_modal}>
        <div className={styles.guideline_controls}>
          <input
            className={styles.search_bar}
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          ></input>
          <div className={styles.guideline_add} onClick={handleIntentDetection}>
            {isLoading ? "Loading..." : "Execute"}
          </div>
        </div>
        <div className={styles.divider}></div>
        {result && (
          <div className={styles.page_content}>
            <p>Intent Triggered: {result.intentTriggered}</p>
            {/* <p>Time Returned From Message: {result.returnedResponse}</p> */}
            <p>Will Follow Up In: {result.followUpIn}</p>
            <p>Will Follow Up On: {result.followUpOn}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FollowUpDetectionPage;
