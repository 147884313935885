import React, { useEffect } from "react";
import { useState } from "react";
import legal_agreements from "./legal_agreements";

import styles from "./agreements.module.css";

const LegalAgreementsPage = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.faq_components}>
      <div className={styles.faq_content}>
        {/* <p className={styles.title}>FAQ</p>
        <p className={styles.subtitle}>
          Everything you wanted to know about ReplyAssist! If you still have
          questions after reading the below FAQs, please reach out and let us
          know.
        </p> */}

        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Legal Agreements</p>
          </div>
        </div>
        <div className={styles.page_line_break}></div>

        <div className={styles.faq_list_items}>
          {legal_agreements.map((item, index) => (
            <div key={index}>
              <div
                className={styles.faq_tile}
                onClick={() =>
                  setExpandedIndex(index === expandedIndex ? null : index)
                }
              >
                <p className={styles.item_question}>{item.question}</p>
                <img
                  className={`${styles.down_arrow_image} ${
                    expandedIndex === index ? styles.flipped : ""
                  }`}
                  src="/images/down-arrow.png"
                  alt="expand"
                ></img>
              </div>
              {expandedIndex === index && (
                <div className={styles.faqanswer}>{item.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.spacer}></div>
    </div>
  );
};

export default LegalAgreementsPage;
