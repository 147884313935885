import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import styles from "./admin.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import AdminUsersPage from "../../components/admin_components/admin_users_page/adminUsersPage";
import EditChatbotPage from "../../components/admin_components/admin_edit_chatbot_page/EditChatbotPage";
import AdminChatbotTestingComponent from "../../components/admin_components/admin_inbox_page/AdminChatbotInbox";
import AdminApiTesting from "../../components/admin_components/admin_api_testing/adminAPITesting";
import AdminSettings from "../../components/admin_components/AdminSettings/AdminSettings";
import FineTuningPage from "../../components/admin_components/admin_fine_tuning/FineTuningPage";
import ContextInjectionPage from "../../components/admin_components/admin_context_injection/ContextInjectionPage";
import TrainingDataPage from "../../components/admin_components/admin_training_data/TrainingDataPage";
import FollowUpDetectionPage from "../../components/admin_components/follow_up_detection_page/FollowUpDetectionPage";

const AdminPage = () => {
  const menuItems = [
    {
      label: "Users",
      key: "users",
      component: AdminUsersPage,
    },
    {
      label: "Chatbot Testing",
      key: "chatbot-testing",
      component: AdminChatbotTestingComponent,
    },
    {
      label: "Fine Tuning",
      key: "fine-tuning",
      component: FineTuningPage,
    },
    {
      label: "Training Data",
      key: "training-data",
      component: TrainingDataPage,
    },
    {
      label: "Prompt Engineering",
      key: "prompt-engineering",
      component: EditChatbotPage,
    },
    // {
    //   label: "Context-Injection (RAG)",
    //   key: "context-injection",
    //   component: ContextInjectionPage,
    // },
    // {
    //   label: "Follow Up Detection",
    //   key: "follow-up-detection",
    //   component: FollowUpDetectionPage,
    // },
    {
      label: "API Testing",
      key: "api-testing",
      component: AdminApiTesting,
    },
    {
      label: "Admin Settings",
      key: "settings",
      component: AdminSettings,
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [activeComponentKey, setActiveComponentKey] = useState(
    menuItems[0].key
  );

  useEffect(() => {
    const activeItem = menuItems.find((item) =>
      location.pathname.includes(item.key)
    );
    if (activeItem) {
      setActiveComponentKey(activeItem.key);
    }
  }, [location]);

  const renderComponent = () => {
    const activeItem = menuItems.find(
      (item) => item.key === activeComponentKey
    );
    return activeItem ? <activeItem.component /> : null;
  };

  const handleMenuItemClick = (key) => {
    setActiveComponentKey(key);
    navigate(`/admin/${key}`);
  };

  return (
    <>
      <Sidebar
        admin={true}
        childComponent={
          <div className={styles.page_layout}>
            <div className={styles.sidebar}>
              {menuItems.map((item) => (
                <div
                  key={item.key}
                  onClick={() => handleMenuItemClick(item.key)}
                  className={
                    activeComponentKey === item.key
                      ? styles.menu_item_selected
                      : styles.menu_item
                  }
                >
                  {item.label}
                </div>
              ))}
            </div>
            <div style={{ flex: 1 }}>{renderComponent()}</div>
          </div>
        }
        activeText="Admin"
      />
    </>
  );
};

export default AdminPage;
