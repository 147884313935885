import React, { createContext, useState, useEffect } from "react";

import { db } from "../services/firebase";
import { auth } from "../services/firebase";
import axios from "axios";
import { toast } from "react-toastify";

import {
  orderBy,
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [customChatbots, setCustomChatbots] = useState(false);
  const [bannedPhrases, setBannedPhrases] = useState([]);

  const fetchCustomChatbots = async () => {
    try {
      const chatbotsRef = collection(db, "CustomChatbots");
      const querySnapshot = await getDocs(chatbotsRef);

      let chatbots = [];

      querySnapshot.forEach((doc) => {
        chatbots.push({ id: doc.id, ...doc.data() });
      });

      setCustomChatbots(chatbots);
    } catch (error) {
      console.error("Error fetching custom chatbots data:", error);
    }
  };

  const fetchBannedPhrases = async () => {
    try {
      const docRef = doc(db, "LiveChatbot", "banned_phrases");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const phrases = docSnap.data().phrases;
        console.log(phrases);
        setBannedPhrases(phrases);
      } else {
        console.log("No such document!");
        setBannedPhrases([]);
      }
    } catch (error) {
      console.error("Error fetching banned phrases:", error);
      setBannedPhrases([]);
    }
  };

  const addBannedPhrase = async (word) => {
    const lowercasedWord = word.toLowerCase();
    try {
      const docRef = doc(db, "LiveChatbot", "banned_phrases");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let phrases = docSnap.data().phrases;
        if (!phrases.includes(lowercasedWord)) {
          phrases = [...phrases, lowercasedWord];
          await updateDoc(docRef, { phrases });
          setBannedPhrases(phrases);
        }
      } else {
        console.log("Document does not exist, creating with new word.");
        const phrases = [lowercasedWord];
        setDoc(docRef, { phrases });
        setBannedPhrases(phrases);
      }
    } catch (error) {
      console.error("Error adding banned phrase:", error);
    }
  };

  const deleteBannedPhrase = async (word) => {
    const lowercasedWord = word.toLowerCase();
    try {
      const docRef = doc(db, "LiveChatbot", "banned_phrases");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let phrases = docSnap.data().phrases;
        phrases = phrases.filter((phrase) => phrase !== lowercasedWord);
        updateDoc(docRef, { phrases });
        setBannedPhrases(phrases);
      } else {
        console.log("No such document! Nothing to delete.");
      }
    } catch (error) {
      console.error("Error deleting banned phrase:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchCustomChatbots();
        fetchBannedPhrases();
      } else {
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AdminContext.Provider
      value={{
        customChatbots,
        bannedPhrases,
        addBannedPhrase,
        deleteBannedPhrase,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
