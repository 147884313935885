import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

const ChooseSubscription = ({ onNext, onBack, userId }) => {
  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content_right}>
        <div className={styles.sidebar_subscription}>
          <SubscriptionPlans userId={userId} />
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/images/LandingPage.png"
        alt="landing"
      ></img>
    </div>
  );
};

const SubscriptionPlans = (props) => {
  return (
    <div className={styles.subscription_plans}>
      {/* <stripe-pricing-table
        pricing-table-id="prctbl_1OKYB2ITemniZ4CSJUxi9QR2"
        publishable-key="pk_test_51OKXFWITemniZ4CSxEYQXF8W34MFKpPtbCtBb1fRYyXHBHxIHHN7q6WBxIIu81HlGRzDkBvAwIZJex5WvrwR1ZGp00StQdIcCo"
        client-reference-id={props.userId}
      ></stripe-pricing-table> */}
    </div>
  );
};

export default ChooseSubscription;
