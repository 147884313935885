import React, { useState } from "react";
import styles from "./universal.module.css";

function TabHighlight(props) {
  const tabs = props.tabs;
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const button = "Edit Deal Stages";

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    props.onTabChange(tab);
  };

  const handleEdit = () => {
    console.log(props.isEditing);
    props.setIsEditing(!props.isEditing);
  };

  return (
    <div className={styles.highlight_menu}>
      <div className={styles.tabs}>
        {tabs.map((tab, index) => (
          <p
            key={index}
            className={`${styles.highlight_box} ${
              tab === activeTab ? styles.active : ""
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </p>
        ))}
      </div>
      {props.buttons && (
        <div className={styles.buttons} onClick={handleEdit}>
          {props.isEditing ? (
            <>
              <div className={styles.button}>Add Deal Stage</div>
              <div className={styles.save_button}>Save</div>
            </>
          ) : (
            <div className={styles.button}>{button}</div>
          )}
        </div>
      )}
    </div>
  );
}

export default TabHighlight;
