import React from "react";

const ChevronRightIcon = ({
  width = 25,
  height = 25,
  color = "rgba(0, 0, 0, 0.8)",
  strokeWidth = 1,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill="none"
    stroke={color}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M9 18l6-6-6-6" stroke="currentColor" />
  </svg>
);

export default ChevronRightIcon;
