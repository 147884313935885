import React from "react";
import { useEffect } from "react";
import styles from "./editchatbot.module.css";
import { useState, useContext } from "react";
import { ChatbotGuidelinesContext } from "../../../context/ChatbotGuidelinesContext";
import SearchBarHeader from "../../portal_components/universal_components/search_bar_header";
import EditGuidelinePage from "./EditGuidelinesPage";

const EditChatbotPage = () => {
  const [isEditing, setIsEditing] = useState(false);

  const [pageTitle, setPageTitle] = useState(null);
  const [promptType, setPromptType] = useState(null);
  const [selectedGuideline, setSelectedGuideline] = useState(null);
  const [activePrompt, setActivePrompt] = useState(null);

  const { prompts, activePrompts } = useContext(ChatbotGuidelinesContext);

  function handleModalNav(selectedGuideline, currentActivePrompt, type) {
    console.log(currentActivePrompt);
    setSelectedGuideline(selectedGuideline);
    setActivePrompt(currentActivePrompt);
    setPromptType(type);
    setIsEditing(!isEditing);
    updatePageTitle(type);
  }

  function updatePageTitle(promptType) {
    let description = "";

    switch (promptType) {
      case "FollowUpPrompts":
        setPageTitle("Follow Up Prompts");
        break;
      case "SystemPrompts":
        setPageTitle("Main System Prompts");
        break;
      case "SystemPromptsFineTuning":
        setPageTitle("Fine Tuning System Prompts");
        break;
      default:
        description = "Unknown Prompt Type";
    }

    return description;
  }

  function back() {
    setIsEditing(!isEditing);
    setSelectedGuideline(null);
    setActivePrompt(null);
    setPromptType(null);
    setPageTitle(null);
  }

  return (
    <div className={styles.main_layout}>
      {pageTitle ? (
        <p className={styles.page_title}>{pageTitle}</p>
      ) : (
        <p className={styles.page_title}>Prompt Engineering</p>
      )}

      <div className={styles.edit_guidelinees_modal}>
        {!isEditing ? (
          <>
            <div className={styles.divider}></div>
            <div className={styles.global_guideline}>
              <p className={styles.global_title}>System Prompt</p>
              <div
                className={styles.global_edit_button}
                onClick={() =>
                  handleModalNav(
                    prompts.SystemPrompts,
                    activePrompts.SystemPrompts,
                    "SystemPrompts"
                  )
                }
              >
                Edit
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.global_guideline}>
              <p className={styles.global_title}>Fine Tuning System Prompt</p>
              <div
                className={styles.global_edit_button}
                onClick={() =>
                  handleModalNav(
                    prompts.SystemPromptsFineTuning,
                    activePrompts.SystemPromptsFineTuning,
                    "SystemPromptsFineTuning"
                  )
                }
              >
                Edit
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.global_guideline}>
              <p className={styles.global_title}>Follow Up Prompt</p>
              <div
                className={styles.global_edit_button}
                onClick={() =>
                  handleModalNav(
                    prompts.FollowUpPrompts,
                    activePrompts.FollowUpPrompts,
                    "FollowUpPrompts"
                  )
                }
              >
                Edit
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.guideline_layout}>
              <div className={styles.spacer}></div>
            </div>
          </>
        ) : (
          <EditGuidelinePage
            selectedGuideline={selectedGuideline}
            activePrompt={activePrompt}
            promptType={promptType}
            back={back}
          />
        )}
      </div>
    </div>
  );
};

export default EditChatbotPage;
