import React from "react";
import styles from "./followups.module.css";
import FilterMenu from "../universal_components/filter_menu";
import SearchBarHeader from "../universal_components/search_bar_header";
import dummy_prospect_data from "../../../data/prospect_data";
import FollowUpCard from "./followupcard";

import TabHighlight from "../universal_components/tab_highlight";

import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

function FollowUps() {

  const { followUps } = useContext(AuthContext);
  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Follow Ups</p>
      <SearchBarHeader />

      <div className={styles.follow_up_content}>
        <div className={styles.column_titles}>
          <p className={styles.column_title_big}>Prospect</p>
          <p className={styles.column_title_small}>Type</p>
          {/* <p className={styles.column_title_small}>Action Needed</p> */}
          <p className={styles.column_title_small}>Due Date</p>
          <p className={styles.column_title_small}>Message</p>
          <p className={styles.column_title_small}>Deal Stage</p>
          <p className={styles.column_title_small}>Interest Level</p>
        </div>
        {followUps.map((followup, index) => {
          return <FollowUpCard key={index} followup={followup} />;
        })}
      </div>
    </div>
  );
}

export default FollowUps;
