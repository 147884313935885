import React from "react";
import { useState } from "react";
import styles from "./testApi.module.css";
import {
  TEST_CREATE_PROSPECT_ENDPOINT,
  TEST_UPDATE_PROSPECT_ENDPOINT,
  TEST_CREATE_MULTIPLE_PROSPECTS_ENDPOINT,
  TEST_CHATBOT_ENDPOINT,
} from "./adminApiCalls";
import CircularLoader from "../../portal_components/universal_components/CircularLoader";

function AdminApiTesting() {
  const [response, setResponse] = useState("");
  const [loadingCreateProspect, setLoadingCreateProspect] = useState(false);
  const [loadingUpdateProspect, setLoadingUpdateProspect] = useState(false);
  const [loadingCreateMultipleProspects, setLoadingCreateMultipleProspects] =
    useState(false);
  const [loadingChatbot, setLoadingChatbot] = useState(false);

  async function handleTestProspectEndpoint() {
    setLoadingCreateProspect(true);
    const responseData = await TEST_CREATE_PROSPECT_ENDPOINT();
    setLoadingCreateProspect(false);
    console.log("Response:", responseData);
    setResponse(responseData);
  }

  async function handleTestUpdateProspectEndpoint() {
    setLoadingUpdateProspect(true);
    const responseData = await TEST_UPDATE_PROSPECT_ENDPOINT();
    setLoadingUpdateProspect(false);
    console.log("Response:", responseData);
    setResponse(responseData);
  }

  async function handleTestMultipleProspectsEndpoint() {
    setLoadingCreateMultipleProspects(true);
    const responseData = await TEST_CREATE_MULTIPLE_PROSPECTS_ENDPOINT();
    setLoadingCreateMultipleProspects(false);
    console.log("Response:", responseData);
    setResponse(responseData);
  }

  async function handleTestChatbotEndpoint() {
    setLoadingChatbot(true);
    const responseData = await TEST_CHATBOT_ENDPOINT();
    setLoadingChatbot(false);
    console.log("Response:", responseData);
    setResponse(responseData);
  }

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>API Testing</p>

      <div className={styles.buttons}>
        <div
          className={`${styles.send_button} ${
            loadingCreateProspect ? styles.loading : ""
          }`}
          onClick={handleTestProspectEndpoint}
        >
          {!loadingCreateProspect ? (
            <p>Create Prospect Endpoint</p>
          ) : (
            <div className={styles.loading_box}>
              <CircularLoader />
            </div>
          )}
        </div>
        <div
          className={`${styles.send_button} ${
            loadingUpdateProspect ? styles.loading : ""
          }`}
          onClick={handleTestUpdateProspectEndpoint}
        >
          {!loadingUpdateProspect ? (
            <p>Update Prospect Endpoint</p>
          ) : (
            <div className={styles.loading_box}>
              <CircularLoader />
            </div>
          )}
        </div>
        <div
          className={`${styles.send_button} ${
            loadingCreateMultipleProspects ? styles.loading : ""
          }`}
          onClick={handleTestMultipleProspectsEndpoint}
        >
          {!loadingCreateMultipleProspects ? (
            <p>Bulk Create Prospects Endpoint</p>
          ) : (
            <div className={styles.loading_box}>
              <CircularLoader />
            </div>
          )}
        </div>
        <div
          className={`${styles.send_button} ${
            loadingChatbot ? styles.loading : ""
          }`}
          onClick={handleTestChatbotEndpoint}
        >
          {!loadingChatbot ? (
            <p>Chatbot Endpoint</p>
          ) : (
            <div className={styles.loading_box}>
              <CircularLoader />
            </div>
          )}
        </div>
      </div>
      <div className={styles.feedbackBox}>
        <pre>
          <code>
            <p className={styles.response_text}> {response}</p>
          </code>
        </pre>
      </div>
    </div>
  );
}

export default AdminApiTesting;
