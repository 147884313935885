import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ProspectsComponent from "../../components/portal_components/prospects_page/prospectsComponent";

const ProspectsPage = () => {
  return (
    <>
      <Sidebar childComponent={<ProspectsComponent />} activeText="Prospects" />
    </>
  );
};

export default ProspectsPage;
