import React from "react";
import { useState, useContext } from "react";

import styles from "./waitlist.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
const JoinWaitlistPage = () => {
  const navigate = useNavigate();

  const { addWaitlistUser } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [company, setCompany] = useState("");
  const [linkedin, setLinkedin] = useState("");
  //   const [feedback, setFeedback] = useState("");

  function handleSubmit() {
    console.log("submit");
    toast.success(`Subscribed!`);
    navigate("/");
    addWaitlistUser(email, name, number, company);
  }

  const canContinue =
    name !== "" && email !== "" && number !== "" && company !== "";

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.sidebar}>
          {/* <div className={styles.back_button} onClick={() => navigate("/home")}>
            <img
              className={styles.back_icon}
              src="/images/GeneralIcons/GeneralBackButton.png"
              alt="back"
            ></img>
            <p className={styles.back_text}>Back to Main</p>
          </div> */}
          {/* <p className={styles.page_title}>ReplyAssist</p> */}
          <img
            src="/images/ReplyAssist-New-Logo-Transparent.png"
            alt="logo"
            className={styles.logo}
          ></img>
          <p className={styles.join_waitlist}>
            Join the free Advisor Insights Weekly Newsletter
          </p>
          <div className={styles.email_input}>
            <input
              onChange={(event) => setName(event.target.value)}
              placeholder="First and Last Name"
              className={styles.input}
            ></input>
          </div>
          <div className={styles.email_input}>
            <input
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email Address"
              className={styles.input}
            ></input>
          </div>
          <div className={styles.email_input}>
            <input
              onChange={(event) => setNumber(event.target.value)}
              placeholder="Phone Number"
              className={styles.input}
            ></input>
          </div>
          <div className={styles.email_input}>
            <input
              onChange={(event) => setCompany(event.target.value)}
              placeholder="Company Name"
              className={styles.input}
            ></input>
          </div>
          {/* <div className={styles.email_input}>
            <input
              onChange={(event) => setLinkedin(event.target.value)}
              placeholder="Linkedin URL"
              className={styles.input}
            ></input>
          </div> */}

          {canContinue ? (
            <div onClick={handleSubmit} className={styles.join_button}>
              <p className={styles.join_button_text}>Subscribe</p>
            </div>
          ) : (
            <div className={styles.join_button_inactive}>
              <p className={styles.join_button_text}>Subscribe</p>
            </div>
          )}
          {/* <p className={styles.note}>
            Please note: By submitting this form, we will be opting you into our
            free weekly email newsletter. If you ever get sick of us, you can
            opt-out at any time!
          </p> */}
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/images/LandingPage.png"
        alt="landing"
      ></img>
    </div>
  );
};

export default JoinWaitlistPage;
