import React from "react";
import styles from "./prospects.module.css";
import FilterMenu from "../universal_components/filter_menu";
import SearchBarHeader from "../universal_components/search_bar_header";
import dummy_prospect_data from "../../../data/prospect_data";
import Profile from "./profileCard";

import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

function ProspectsComponent() {
  const { prospects } = useContext(AuthContext);

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Prospects</p>
      <SearchBarHeader />

      <div className={styles.column_titles}>
        <p className={styles.column_title}>Basic Info</p>
        <p className={styles.column_title}>Next Follow Up</p>
        <p className={styles.column_title}>Deal Stage</p>
        <p className={styles.column_title_last}>Interest Level</p>
      </div>
      <div className={styles.prospect_scroll}>
        {prospects.map((prospect, index) => {
          return <Profile key={index} prospect={prospect} />;
        })}
        <div className={styles.buffer}></div>
      </div>
    </div>
  );
}

export default ProspectsComponent;
