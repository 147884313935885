import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div style={{ width: "100%", backgroundColor: "white", overflowY: "auto" }}>
      <div
        style={{
          width: "100%",
          height: "40vh",
          backgroundImage: "linear-gradient(to top right, #001f41, #003d82)",
          display: "flex", // Enables flexbox layout
          justifyContent: "center", // Centers content horizontally
          alignItems: "center", // Centers content vertically
        }}
      >
        <p
          style={{
            color: "white",
            width: "100%",
            textAlign: "center",
            margin: 0,
            fontSize: "50px",
          }}
        >
          ReplyAssist Privacy Policy
        </p>
      </div>
      <img
        style={{ width: "100%" }}
        src="/PrivacyPolicyImage.jpg"
        alt="Privacy Policy"
      ></img>
    </div>
  );
};

export default PrivacyPolicyPage;
