import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./chatbot_testing.module.css";
import InboxMessageInput from "./MessageInput";
import { AuthContext } from "../../../context/AuthContext";
import { ChatbotTestingContext } from "../../../context/ChatbotTestingContext";
import InfiniteScroll from "react-infinite-scroller";
import { ConfirmDeletePopup } from "../../screen_popups/DeletePopup/ConfirmDeletePopup";
import { toast } from "react-toastify";

import CreateTestConversation from "../../screen_popups/CreateTestConversation/CreateTestConversation";

export default function AdminChatbotInbox() {
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const {
    fetchChatbotTestingData,
    loadingConversations,
    testConversations,
    selectedChat,
    setSelectedChat,
    deleteMessage,
    toggleStarMessage,
  } = useContext(ChatbotTestingContext);

  const { currentUser } = useContext(AuthContext);

  function handleAddProspect() {
    setIsAdding(!isAdding);
  }

  const [deletePopup, setDeletePopup] = useState(false);

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  const selectChat = (chat) => {
    setSelectedChat(chat);
  };

  // const filteredChatChannels = testConversations.filter((chat) =>
  //   chat.model.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  // const sortedChatChannels = testConversations.sort((a, b) => {
  //   const timestampA = a.messages[a.messages.length - 1]?.time_stamp || 0;
  //   const timestampB = b.messages[b.messages.length - 1]?.time_stamp || 0;

  //   return timestampB - timestampA;
  // });
  const [isGlowing, setIsGlowing] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsGlowing(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isGlowing]); // Run this effect whenever isGlowing changes

  const chatChannels = testConversations.map((chat) => (
    <React.Fragment key={chat.id}>
      <div className={styles.chat_card_box}>
        <div
          onClick={() => selectChat(chat)}
          className={`${styles.chat_card} ${
            selectedChat?.id === chat.id
              ? styles.selected_chat
              : styles.non_selected_chat
          }`}
        >
          <div className={`${styles.chat_info} `}>
            <div className={styles.chat_all_info}>
              <div className={styles.chat_user_info}>
                <div className={styles.chat_user_info_left}>
                  <div className={styles.chat_user_avatar}>
                    <img
                      src="/accountIconStroke.png"
                      className={styles.chat_user_avatar_image}
                      alt={"Profile"}
                    />
                  </div>
                  <div className={styles.user_info_box}>
                    <p className={styles.chat_user_name}>
                      Conversation {chat.conversation_number}
                    </p>
                    <p className={styles.chat_user_name}>
                      Model: {getModelName(chat.model)}
                    </p>
                  </div>
                </div>
                <div className={styles.chat_time_stamp}>
                  {/* <p className={styles.chat_time_stamp_text}>
                    {chat.messages.length > 0
                      ? convertTimestampToTime(
                          chat.messages[chat.messages.length - 1].time_stamp
                        )
                      : ""}
                  </p> */}
                </div>
              </div>
              <div className={styles.chat_recent_message}>
                <p className={styles.chat_message}>
                  {chat.messages[chat.messages.length - 1].content}
                </p>
                <div className={styles.message_spacer}></div>
                <p className={styles.chat_conversatsion_details}>
                  {chat.promptName
                    ? `Prompt: ${chat.promptName}`
                    : "No Prompt Selected"}
                </p>
                <p
                  className={`${styles.chat_conversatsion_details} ${
                    chat.follow_up_date && isGlowing ? styles.text_glow : ""
                  }`}
                >
                  {chat.follow_up_date
                    ? `Scheduled Follow Up: ${formatDateDay(
                        chat.follow_up_date
                      )}`
                    : "No Follow Up Scheduled"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));

  const MessageComponent = ({ selectedChat, onDeleteClick }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "auto" });
      }
    };
    

    useEffect(scrollToBottom, [selectedChat]);
    console.log(selectedChat);

    const [clickedMessageId, setClickedMessageId] = useState(null);

    const handleStarClick = (messageId, isStarred = false) => {
      toggleStarMessage(selectedChat.id, messageId, isStarred);
      if (!isStarred) {
        toast.success("Message Starred");
      } else {
        toast.success("Message Unstarred");
      }
    };

    return (
      <div className={styles.message_wrapper}>
        {selectedChat.messages.map((message, index) => {
          return (
            <Message
              message={message}
              handleStarClick={handleStarClick}
              onDeleteClick={onDeleteClick}
              clickedMessageId={clickedMessageId}
              index={index}
            />
          );
        })}

        {loadingMessage && <LoadingMessage />}
        <div ref={messagesEndRef} />
      </div>
    );
  };

  const [deleteFunction, setDeleteFunction] = useState(() => () => {});

  const deleteMessageFunction = async (chatId, messageId) => {
    try {
      await deleteMessage(chatId, messageId);
      toast.success("Message Deleted");
    } catch (error) {
      toast.error("Error Deleting Message");
    }
  };

  const handleDeleteClick = (messageId) => {
    setDeleteFunction(
      () => () => deleteMessageFunction(selectedChat.id, messageId)
    );
    setDeletePopup(true);
  };

  return (
    <div className={styles.main_layout}>
      <CreateTestConversation
        isOpen={isAdding}
        onClose={() => setIsAdding(false)}
        setSelectedChat={setSelectedChat}
      />
      <div className={styles.sidebar}>
        <div className={styles.search_container}>
          <input
            className={styles.search_input}
            value={searchQuery}
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
          />
          <div className={styles.add_test_prospect} onClick={handleAddProspect}>
            <img src="/add.png" className={styles.add_image} alt="add"></img>
          </div>
        </div>
        <div className={styles.pagination_controls}>
          <div
            className={styles.pagination_button}
            onClick={() => fetchChatbotTestingData("prev")}
          >
            Next Page
          </div>
          <div className={styles.line}></div>
          <div
            className={styles.pagination_button}
            onClick={() => fetchChatbotTestingData("next")}
          >
            Prev Page
          </div>
        </div>
        <div className={styles.loading_line_background}>
          {loadingConversations && <div className={styles.loading_line}></div>}
        </div>
        <div className={styles.chat_channel_box}>{chatChannels}</div>
      </div>
      {selectedChat ? (
        <>
          <div className={styles.chat_container}>
            <div className={styles.chat_layout}>
              <div className={styles.chat_layout_content_info}>
                <div className={styles.chat_messages}>
                  <MessageComponent
                    selectedChat={selectedChat}
                    onDeleteClick={handleDeleteClick}
                  />
                </div>
              </div>
              <div className={styles.chat_input}>
                <InboxMessageInput
                  selectedChat={selectedChat}
                  currentUser={currentUser}
                  loadingMessage={loadingMessage}
                  setLoadingMessage={setLoadingMessage}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.no_chat_selected}>
          <div className={styles.select_chat_box}>
            <p className={styles.select_chat_text}>Select a Chat</p>
            <img
              className={styles.select_chat_image}
              src="/images/GeneralIcons/NoSelectedChatIcon.svg"
              alt="select chat"
            ></img>
          </div>
        </div>
      )}
      <ConfirmDeletePopup
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        actionFunction={deleteFunction}
        desc={"Are you sure you want to delete?"}
      />
    </div>
  );
}

function Message(props) {
  const { showChatInfo, selectedChat } = useContext(ChatbotTestingContext);

  const message = props.message;

  const clickedMessageId = props.clickedMessageId;
  const index = props.index;

  const isSenderSelf = message.sender !== "ReplyAssist";
  const isLastMessage = index === selectedChat.messages.length - 1;

  const messageLayout = isSenderSelf
    ? styles.message_layout_content_reversed
    : styles.message_layout_content;

  return (
    <div
      key={index}
      className={`${styles.message_container} ${
        isSenderSelf ? styles.reversed : ""
      }`}
    >
      <div
        className={`${styles.message_avatar} ${
          isSenderSelf
            ? styles.message_avatar_self
            : styles.message_avatar_other
        }`}
      >
        {isSenderSelf ? (
          <img
            className={styles.message_avatar_image_icon}
            src="/accountIconStroke.png"
            alt="Self"
          />
        ) : (
          <img
            className={styles.message_avatar_image}
            src="/ReplyAssistLogoBug.png"
            alt="Other"
          />
        )}
      </div>

      <div
        className={`${styles.message_content} ${
          isSenderSelf
            ? styles.self_message_content
            : styles.other_message_content
        }`}
      >
        <div
          className={`${styles.message_info} ${
            isSenderSelf ? styles.self_message_info : styles.other_message_info
          }`}
        >
          <p className={styles.message_sender}>
            {isSenderSelf ? selectedChat.prospectInfo.name : "ReplyAssist"}
          </p>
        </div>
        <div className={messageLayout}>
          {message.content === "Human Input Required" ? (
            <div className={styles.input_required}>{message.content}</div>
          ) : message.content === "Conversation Ended" ? (
            <div className={styles.end_conversation}>{message.content}</div>
          ) : (
            <div
              className={`${styles.message_text_content} ${
                isSenderSelf
                  ? styles.self_message_text_content
                  : styles.other_message_text_content
              }`}
            >
              <p className={styles.message_text}>
                {renderTextWithLineBreaks(message.content)}
              </p>
            </div>
          )}
          {!isSenderSelf && (
            <>
              {message.starred ? (
                <div
                  className={styles.star_icon_filled}
                  onClick={() =>
                    props.handleStarClick(message.id, message.starred)
                  }
                >
                  <RateIconFilled />
                </div>
              ) : (
                <div
                  className={styles.star_icon}
                  onClick={() =>
                    props.handleStarClick(message.id, message.starred)
                  }
                >
                  <RateIcon />
                </div>
              )}
            </>
          )}
          <div
            className={styles.trash_icon}
            onClick={() => props.onDeleteClick(message.id)}
          >
            <TrashIcon />
          </div>
        </div>
        {!showChatInfo &&
          !message.first_message &&
          (isSenderSelf ? (
            <></>
          ) : (
            <>
              {message.matched_intent ? (
                <p className={styles.chat_conversatsion_details}>
                  <strong>Intent:</strong> {message.matched_intent}
                </p>
              ) : (
                <p className={styles.chat_conversatsion_details}>
                  <strong>Intent:</strong> No Intent Was Triggered
                </p>
              )}
              {isLastMessage && (
                <>
                  <p className={styles.chat_conversatsion_details}>
                    <strong>Scheduled Follow Up:</strong>{" "}
                    {formatDateDay(selectedChat.follow_up_date)} (
                    {daysUntil(selectedChat.follow_up_date)} Days)
                  </p>
                </>
              )}
              {/* <p className={styles.chat_follow_up_update}>
                <strong>Follow Up Updated Sucessfully!</strong>
              </p> */}
              {/* {message.responseMetadata.sentiment_score !== null &&
                message.responseMetadata.sentiment_score !== undefined && (
                  <p className={styles.chat_conversatsion_details}>
                    <strong>
                      Sentiment Score:{" "}
                      {Number(message.responseMetadata.sentiment_score).toFixed(
                        5
                      )}
                    </strong>
                  </p>
                )} */}
            </>
          ))}
      </div>
    </div>
  );
}

function LoadingMessage() {
  const isSenderSelf = false;
  return (
    <div
      className={`${styles.message_container} ${
        isSenderSelf ? styles.reversed : ""
      }`}
    >
      <div
        className={`${styles.message_avatar} ${
          isSenderSelf
            ? styles.message_avatar_self
            : styles.message_avatar_other
        }`}
      >
        {isSenderSelf ? (
          <img
            className={styles.message_avatar_image_icon}
            src="/accountIconStroke.png"
            alt="Self"
          />
        ) : (
          <img
            className={styles.message_avatar_image}
            src="/ReplyAssistLogoBug.png"
            alt="Other"
          />
        )}
      </div>

      <div
        className={`${styles.message_content} ${
          isSenderSelf
            ? styles.self_message_content
            : styles.other_message_content
        }`}
      >
        <div
          className={`${styles.message_info} ${
            isSenderSelf ? styles.self_message_info : styles.other_message_info
          }`}
        >
          <p className={styles.message_sender}>ReplyAssist</p>
        </div>
        <div className={styles.message_layout_content}>
          <div className={styles.loading_message}>
            <div className={styles.loader}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RateIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2L14.6 8.89L22 9.24L16.5 13.97L18.1 21L12 17.27L5.9 21L7.5 13.97L2 9.24L9.4 8.89L12 2Z"
        className={styles.star_svg}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function RateIconFilled() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2L14.6 8.89L22 9.24L16.5 13.97L18.1 21L12 17.27L5.9 21L7.5 13.97L2 9.24L9.4 8.89L12 2Z"
        className={styles.star_svg_filled}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function TrashIcon() {
  return (
    <svg
      enable-background="new 0 0 512 512"
      height="14px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      width="14px"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g fill="rgba(255, 0, 0, 0.75)"> */}
      <g fill="rgba(0, 0, 0, 0.5)" className={styles.trash_icon_fill}>
        <path d="M444.852,66.908h-99.339V47.04c0-21.943-17.792-39.736-39.736-39.736h-99.339 c-21.944,0-39.736,17.793-39.736,39.736v19.868H67.363v19.868h20.47l19.887,377.489c0,21.944,17.792,39.736,39.736,39.736h218.546 c21.944,0,39.736-17.792,39.736-39.736l19.538-377.489h19.577V66.908z M186.57,47.04c0-10.962,8.926-19.868,19.868-19.868h99.339 c10.962,0,19.868,8.906,19.868,19.868v19.868H186.57V47.04z M385.908,463.236l-0.039,0.505v0.524 c0,10.943-8.906,19.868-19.868,19.868H147.455c-10.942,0-19.868-8.925-19.868-19.868v-0.524l-0.019-0.523L107.72,86.776h297.669 L385.908,463.236z" />
        <rect height="317.885" width="19.868" x="246.173" y="126.511" />
        <polygon points="206.884,443.757 186.551,126.493 166.722,127.753 187.056,445.017" />
        <polygon points="345.649,127.132 325.82,125.891 305.777,443.776 325.606,445.017" />
      </g>
    </svg>
  );
}

function renderTextWithLineBreaks(text) {
  return text.split("<br>").map((line, index, array) => (
    <React.Fragment key={index}>
      {line}
      {/* Add two <br> elements after each line except the last one */}
      {index < array.length - 1 && (
        <>
          <br />
          <br />
        </>
      )}
    </React.Fragment>
  ));
}

function getModelName(fineTunedModelName) {
  const parts = fineTunedModelName.split(":");
  return parts.length > 3 ? parts[3] : null;
}

function formatNextFollowUp(followUps) {
  if (followUps.length === 0) {
    return "N/A";
  }
  const followUp = followUps[0];

  let milliseconds;

  // Check if the timestamp is a Unix timestamp (number)
  if (typeof followUp.timestamp === "number") {
    milliseconds = followUp.timestamp * 1000; // Convert to milliseconds
  } else {
    // Original format with seconds and nanoseconds
    milliseconds =
      followUp.timestamp.seconds * 1000 +
      followUp.timestamp.nanoseconds / 1000000;
  }

  const date = new Date(milliseconds);

  const formattedDate = date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return formattedDate;
}

function formatNextFollowUpMessage(followUps) {
  if (followUps.length === 0) {
    return "N/A";
  }
  const followUpMessage = followUps[0].message;

  return followUpMessage;
}

function formatDateDay(timestamp) {
  const date = new Date(timestamp);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = monthNames[monthIndex];

  let suffix = "th";
  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return `${month} ${day}${suffix}`;
}

function daysUntil(timestamp) {
  const targetDate = new Date(timestamp);

  const today = new Date();
  const todayAtMidnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const difference = targetDate - todayAtMidnight;

  const days = difference / (1000 * 60 * 60 * 24);

  return Math.round(days);
}
