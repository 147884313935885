import React, { useState, useRef, useEffect } from "react";
import styles from "./dynamicfield.module.css";
import { Tooltip } from "@mui/material";

export const DynamicFormField = ({
  title,
  type = "text",
  error = "",
  name,
  info,
  content,
  dropdown = false,
  customDropdown = false,
  trainingData = false,
  dropDownData,
  onValueChange,
  numberOnly = false,
  receiveObject,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible]);

  const handleOptionClick = (option, event) => {
    event.stopPropagation(); // Add this line
    onValueChange(option);
    console.log("Handle Option Click");
    toggleDropdown();
  };

  const handleInputChange = (event) => {
    if (numberOnly) {
      const value = event.target.value;
      if (value === "" || /^\d+$/.test(value)) {
        onValueChange(value);
      }
    } else {
      onValueChange(event.target.value);
    }
  };

  const inputBoxStyle = dropdown ? styles.input_box_dropdown : styles.input_box;

  return (
    <div className={styles.user_info_layout}>
      <div className={styles.field_info}>
        <p className={styles.user_info_title}>{title}</p>
        {info && (
          <Tooltip title={info} arrow>
            <img
              src="/moreInfoIcon.png"
              alt="info"
              className={styles.infoImage}
            />
          </Tooltip>
        )}
      </div>
      <div className={inputBoxStyle}>
        <input
          name={name}
          className={styles.input_box_text}
          value={content}
          onClick={() => {
            dropdown && toggleDropdown();
          }}
          onChange={(e) => !dropdown && handleInputChange(e)}
          type={numberOnly ? "number" : type}
        />
        {dropdown && (
          <div
            className={styles.input_box_dropdown_button}
            onClick={toggleDropdown}
          >
            <div className={styles.dropdown_green_bg} ref={dropdownRef}>
              <img
                src="/DropDownArrow.png"
                alt="options"
                // className={styles.drop_down_image}
                className={`${styles.drop_down_image} ${
                  isDropdownVisible ? styles.rotate_180 : ""
                }`}
              />
              {isDropdownVisible && (
                <>
                  {customDropdown ? (
                    <div className={styles.dropdown_menu}>
                      {trainingData ? (
                        <>
                          {dropDownData?.map((data, i) => (
                            <div
                              key={i}
                              className={styles.dropdown_menu_option_box}
                              onClick={(e) => handleOptionClick(data.name, e)}
                            >
                              <p className={styles.dropdown_menu_option}>
                                {data.name}
                              </p>
                              <p
                                className={styles.dropdown_menu_option_updated}
                              >
                                Updated {formatTimestamp(data.last_updated)}
                              </p>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {dropDownData?.map((option, i) => (
                            <div
                              key={i}
                              className={styles.dropdown_menu_option_box}
                              onClick={(e) =>
                                handleOptionClick(
                                  receiveObject ? option : option.version_title,
                                  e
                                )
                              }
                            >
                              <p className={styles.dropdown_menu_option}>
                                {option.version_title}
                              </p>
                              <p
                                className={styles.dropdown_menu_option_updated}
                              >
                                Updated {formatTimestamp(option.lastUpdated)}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className={styles.dropdown_menu}>
                      {dropDownData?.map((option, i) => (
                        <div
                          key={i}
                          className={styles.dropdown_menu_option_box}
                          onClick={(e) => handleOptionClick(option, e)}
                        >
                          <p className={styles.dropdown_menu_option}>
                            {option}
                          </p>
                          {option === "gpt-3.5-turbo-1106" && (
                            <p
                              className={
                                styles.dropdown_menu_option_recommended
                              }
                            >
                              Recommended
                            </p>
                          )}
                          {option === "gpt-4-0613" && (
                            <p
                              className={
                                styles.dropdown_menu_option_experimental
                              }
                            >
                              Experimental
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {error && <p className={styles.error_text}>{error}</p>}
    </div>
  );
};

function formatTimestamp(timestamp) {
  try {
    const date = new Date(parseInt(timestamp, 10));

    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString(undefined, options);
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}
