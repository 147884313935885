import React from "react";
import styles from "./popup.module.css";
import { useState, useContext } from "react";
import { ChatbotGuidelinesContext } from "../../../context/ChatbotGuidelinesContext";
import { ChatbotTestingContext } from "../../../context/ChatbotTestingContext";
import { ChatbotTuningContext } from "../../../context/ChatbotTuningContext";
import { DynamicFormField } from "../../portal_components/universal_components/DynamicFormField/DynamicFormField";
import { toast } from "react-toastify";
import CircularLoader from "../../portal_components/universal_components/CircularLoader/CircularLoader";

const CreateTestConversation = ({ isOpen, onClose }) => {
  const { createNewTestConversation, testConversations } = useContext(
    ChatbotTestingContext
  );

  const getNextTestingNumber = () => {
    const highestNumber = testConversations[0].conversation_number;
    return highestNumber + 1;
  };

  const { fineTunedModels } = useContext(ChatbotTuningContext);
  const { prompts } = useContext(ChatbotGuidelinesContext);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modelVersion, setModelVersion] = useState("");
  const [promptVersion, setPromptVersion] = useState("");
  const [promptID, setPromptID] = useState("");

  const canCreateProspect = message && modelVersion && promptVersion;

  async function createTestProspect() {
    setLoading(true);
    const testNumber = getNextTestingNumber();
    await createNewTestConversation(
      modelVersion,
      promptID,
      promptVersion,
      message,
      testNumber
    );
    setLoading(false);
    toast.success(`Conversation ${testNumber} Created`);
    closeFunction();
  }

  function closeFunction() {
    onClose();
    setMessage(null);
    setModelVersion(null);
  }

  const modifiedFineTunedModels = fineTunedModels
    .filter((model) => model.fine_tuned_model)
    .map((model) => ({
      ...model,
      name: model.fine_tuned_model,
      last_updated: model.created_at,
    }));

  const test_conversation_data = [
    {
      label: "Intro Message",
      value: message,
      setter: setMessage,
      dropdown: false,
      receiveObject: false,
    },
    {
      label: "Model Version",
      value: modelVersion,
      setter: setModelVersion,
      dropdown: true,
      dropdownData: modifiedFineTunedModels,
      dataType: true,
      receiveObject: false,
    },
    {
      label: "Prompt Version",
      value: promptVersion,
      setter: setPromptVersion,
      dropdown: true,
      dropdownData: prompts?.SystemPrompts.versions,
      dataType: false,
      receiveObject: true,
    },
  ];

  const handleInputChange = (setter) => (value) => (object) => {
    if (object) {
      setter(value.version_title);
      setPromptID(value.documentId);
    } else {
      setter(value);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.fullscreen_modal}>
      <div className={styles.add_prospect_modal_body}>
        <>
          <div className={styles.modal_header}>
            <div className={styles.header_content}>
              <p className={styles.add_modal_title}>
                Create Test Conversation {getNextTestingNumber()}
              </p>
            </div>
          </div>

          <div className={styles.modal_content}>
            <div className={styles.prospect_info_fields}>
              {test_conversation_data.map((stageData, index) => {
                return (
                  <DynamicFormField
                    key={index}
                    title={stageData.label}
                    dropdown={stageData.dropdown}
                    dropDownData={stageData.dropdownData}
                    customDropdown={true}
                    trainingData={stageData.dataType}
                    content={stageData.value}
                    receiveObject={stageData.receiveObject}
                    onValueChange={(value) =>
                      handleInputChange(stageData.setter)(value)(
                        stageData.receiveObject
                      )
                    }
                    placeholder={stageData.label}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.modal_footer}>
            <div className={styles.buttons}>
              <div className={styles.cancel_button} onClick={closeFunction}>
                Cancel
              </div>

              {canCreateProspect ? (
                <div
                  className={styles.save_button}
                  onClick={createTestProspect}
                >
                  {loading ? (
                    <CircularLoader
                      size="24px"
                      color="rgba(255, 255, 255, 1)"
                    />
                  ) : (
                    "Create Conversation"
                  )}
                </div>
              ) : (
                <div className={styles.save_button_mute}>
                  Create Conversation
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default CreateTestConversation;

// function setMaleProfilePicture() {
//   const randomNumber = Math.floor(Math.random() * 78) + 1;
//   const newAvatarUrl = `https://xsgames.co/randomusers/assets/avatars/male/${randomNumber}.jpg`;
//   setPfp(newAvatarUrl);
// }

// function setFemaleProfilePicture() {
//   const randomNumber = Math.floor(Math.random() * 78) + 1;
//   const newAvatarUrl = `https://xsgames.co/randomusers/assets/avatars/female/${randomNumber}.jpg`;
//   setPfp(newAvatarUrl);
// }

// function setRandomProfilePicture() {
//   const isMale = Math.random() < 0.5;

//   if (isMale) {
//     setMaleProfilePicture();
//   } else {
//     setFemaleProfilePicture();
//   }
// }
