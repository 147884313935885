import React from "react";
import styles from "./trainingData.module.css";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { ChatbotTuningContext } from "../../../context/ChatbotTuningContext";
import { ConfirmDeletePopup } from "../../screen_popups/DeletePopup/ConfirmDeletePopup";
import convertDataToJSONLFormat from "../../../utils/convertToJSONL";
import { ReplaceTrainingDataPopup } from "../../screen_popups/ReplaceTrainingDataPopup/CreateTrainingDataPopup/ReplaceTrainingDataPopup";
export default function EditTrainingDataPage(props) {
  const selectedTrainingData = props.selectedTrainingData;
  const viewOnly = selectedTrainingData.viewOnly;

  const { updateDataFields, createNewEntryLine, deleteDataPoint } =
    useContext(ChatbotTuningContext);

  const identityOptions = ["user", "assistant"];

  const [trainingDataPoints, setTrainingDataPoints] = useState(
    selectedTrainingData.dataPoints
  );

  const [openDataSet, setOpenDataSet] = useState(null);
  const [editingEntryId, setEditingEntryId] = useState(null);
  const [editingEntryError, setEditingEntryError] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [replacePopup, setReplacePopup] = useState(false);

  const [loadingPopup, setLoadingPopup] = useState(false);

  useEffect(() => {
    const removePendingUpdates = () => {
      const updatedArray = trainingDataPoints.filter((data) => {
        return data.id === openDataSet || !data.pendingUpdate;
      });
      setTrainingDataPoints(updatedArray);
    };

    if (openDataSet !== null) {
      removePendingUpdates();
    }
  }, [openDataSet]);

  useEffect(() => {
    setEditingEntryError(false);
  }, [editingEntryId]);

  const toggleItem = (itemId) => {
    const dataPoint = trainingDataPoints.find((dp) => dp.id === itemId);

    const isPendingUpdate = dataPoint && dataPoint.pendingUpdate;

    if (!isPendingUpdate) {
      cancelEditEntry();
      setEditingEntryId(null);
    }

    if (openDataSet === itemId && !isPendingUpdate) {
      setOpenDataSet(null);
    } else {
      setOpenDataSet(itemId);
    }
  };

  const toggleIdentityDropdown = (event) => {
    event.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  };

  function downloadTrainingDataAsJSONL() {
    const filename = `${selectedTrainingData.name.replace(/ /g, "-")}.jsonl`;

    const jsonlData = convertDataToJSONLFormat(selectedTrainingData);

    console.log(jsonlData);

    const blob = new Blob([jsonlData], { type: "application/json" });

    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = filename;

    a.style.display = "none";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  }

  const addDataPoint = (event) => {
    event.stopPropagation();

    if (trainingDataPoints.length > 0) {
      const lastDataPoint = trainingDataPoints[trainingDataPoints.length - 1];
      const lastEntry =
        lastDataPoint.data_array[lastDataPoint.data_array.length - 1];

      if (lastEntry.identity !== null && lastEntry.content !== null) {
        const newArray = [...trainingDataPoints];
        const newId = Date.now();
        const newDataPoint = {
          id: newId,
          pendingUpdate: true,
          data_array: [{ identity: null, content: null }],
        };

        newArray.unshift(newDataPoint);
        setTrainingDataPoints(newArray);
        setOpenDataSet(newId);
        setEditingEntryId(0);
      } else {
        setEditingEntryError(true);
        toast.warning("Complete New Data Point");
      }
    } else {
      const newArray = [...trainingDataPoints];
      const newId = Date.now();
      const newDataPoint = {
        id: newId,
        pendingUpdate: true,
        data_array: [{ identity: null, content: null }],
      };

      newArray.unshift(newDataPoint);
      setTrainingDataPoints(newArray);
      setOpenDataSet(newId);
      setEditingEntryId(0);
    }
  };

  const addNewEntry = (event, index) => {
    event.stopPropagation();

    if (index >= 0 && index < trainingDataPoints.length) {
      const lastEntry =
        trainingDataPoints[index].data_array[
          trainingDataPoints[index].data_array.length - 1
        ];

      if (
        lastEntry &&
        lastEntry.identity !== null &&
        lastEntry.content !== null
      ) {
        const newArray = [...trainingDataPoints];
        const newData = { identity: null, content: null };

        newArray[index].data_array.push(newData);
        setTrainingDataPoints(newArray);
        setEditingEntryId(newArray[index].data_array.length - 1);
      } else {
        setEditingEntryError(true);
        toast.warning("Complete Current Entry");
      }
    }
  };

  const [originalData, setOriginalData] = useState(null);

  const editEntry = (event, index, dataPointId) => {
    setOpenDataSet(dataPointId);
    event.stopPropagation();
    setEditingEntryId(index);
    const openItem = trainingDataPoints.find((data) => data.id === dataPointId);
    setOriginalData(openItem.data_array.map((entry) => ({ ...entry })));
  };

  const cancelEditEntry = (event) => {
    event && event.stopPropagation();
    setEditingEntryId(null);

    setTrainingDataPoints((prevData) => {
      return prevData.reduce((acc, data) => {
        if (data.id === openDataSet) {
          let updatedDataArray;

          if (originalData) {
            updatedDataArray = originalData.filter(
              (entry) => entry.identity !== null && entry.content !== null
            );
          } else {
            updatedDataArray = data.data_array.filter(
              (entry) => entry.identity !== null && entry.content !== null
            );
          }

          if (updatedDataArray.length > 0) {
            acc.push({ ...data, data_array: updatedDataArray });
          }
        } else {
          acc.push(data);
        }
        return acc;
      }, []);
    });

    setOriginalData(null);
  };

  const saveEntry = async (event) => {
    event.stopPropagation();

    if (!openDataSet) {
      return;
    }

    const openItem = trainingDataPoints.find((data) => data.id === openDataSet);

    if (!openItem) {
      return;
    }

    const isValid = openItem.data_array.every(
      (entry) => entry.identity && entry.content
    );

    if (!isValid) {
      toast.error("Add Required Fields");
      setEditingEntryError(true);
      return;
    }

    try {
      if (openItem.pendingUpdate) {
        const newRowData = [{ ...openItem.data_array[0] }];
        const newId = await createNewEntryLine(
          selectedTrainingData.id,
          newRowData
        );

        openItem.id = newId;
        openItem.pendingUpdate = false;
        setOpenDataSet(newId);
      } else {
        await updateDataFields(
          selectedTrainingData.id,
          openItem.id,
          openItem.data_array
        );
      }

      toast.success("Data Entry Saved");
      setEditingEntryId(null);
    } catch (error) {
      toast.error("Error Saving Data Entry");
      console.error(error);
    }
  };

  const deleteEntry = async (event) => {
    event.stopPropagation();

    if (editingEntryId === null) {
      return;
    }

    try {
      setTrainingDataPoints((prevData) => {
        return prevData.map((data) => {
          if (data.id === openDataSet) {
            const updatedDataArray = [...data.data_array];
            if (updatedDataArray.length === 1) {
              toast.warning("Cannot Delete Only Entry");
              return data;
            } else if (data.data_array[editingEntryId]) {
              updatedDataArray.splice(editingEntryId, 1);

              updateDataFields(
                selectedTrainingData.id,
                data.id,
                updatedDataArray
              );

              toast.success("Data Entry Deleted");
              return { ...data, data_array: updatedDataArray };
            }
          }
          return data;
        });
      });

      setEditingEntryId(null);
    } catch (error) {
      toast.error("Error Deleting Data Entry");
      console.error(error);
    }
  };

  const deleteTrainingDataPoint = async (event) => {
    event.stopPropagation();

    if (openDataSet === null) {
      toast.error("No Data Set Found");
      return;
    }

    try {
      setTrainingDataPoints((prevData) => {
        return prevData.filter((data) => {
          if (data.id === openDataSet) {
            deleteDataPoint(selectedTrainingData.id, data.id);
            toast.success("Data Set Deleted");
            return false;
          }
          return true;
        });
      });

      setEditingEntryId(null);
    } catch (error) {
      toast.error("Error Deleting Set");
      console.error(error);
    }
  };

  const handleIdentityChange = (newIdentity, optionIndex) => {
    setTrainingDataPoints((prevData) => {
      const newArray = prevData.map((data) => {
        if (data.id === openDataSet) {
          const newDataArray = data.data_array.map((entry, index) => {
            if (index === editingEntryId) {
              return { ...entry, identity: newIdentity };
            }
            return entry;
          });
          return { ...data, data_array: newDataArray };
        }
        return data;
      });

      return newArray;
    });

    setDropdownVisible(!dropdownVisible);
  };

  const handleContentChange = (newContent) => {
    setTrainingDataPoints((prevData) => {
      const newArray = prevData.map((data) => {
        if (data.id === openDataSet) {
          const newDataArray = data.data_array.map((entry, index) => {
            if (index === editingEntryId) {
              return { ...entry, content: newContent };
            }
            return entry;
          });
          return { ...data, data_array: newDataArray };
        }
        return data;
      });

      return newArray;
    });
  };

  async function replaceTrainingData(file) {
    try {
      setLoadingPopup(true);
      const newDataPoints = await props.jsonReplaceTrainingData(
        selectedTrainingData.id,
        file
      );
      if (newDataPoints) {
        setTrainingDataPoints(newDataPoints);
      } else {
        throw new Error("No data points returned");
      }
    } catch (error) {
      toast.error("Error Overwriting Data");
      console.error("Error replacing training data:", error);
    } finally {
      setReplacePopup(false);
      setLoadingPopup(false);
      toast.success("Data Overwritten");
    }
  }

  function replaceTrainingDataJson() {
    setReplacePopup(true);
  }

  return (
    <div className={styles.edit_guideline_modal}>
      <div className={styles.header_section}>
        <div className={styles.title_area_create}>
          <div className={styles.back_button_small} onClick={props.back}>
            <img
              className={styles.back_icon_opacity}
              src={"/images/BackIcon.png"}
              alt="back"
            ></img>
          </div>
          {viewOnly ? (
            <p className={styles.page_title}>
              <span className={styles.page_title_blue}>
                {selectedTrainingData.name}
              </span>
            </p>
          ) : (
            <p className={styles.page_title}>
              Edit{" "}
              <span className={styles.page_title_blue}>
                {selectedTrainingData.name}
              </span>
            </p>
          )}
        </div>
        <div className={styles.page_controls_top}>
          {!viewOnly && (
            <div
              className={styles.download_json_button}
              onClick={replaceTrainingDataJson}
            >
              Overwrite With Json File
            </div>
          )}

          <div
            className={styles.download_json_button}
            onClick={downloadTrainingDataAsJSONL}
          >
            Download Json
          </div>
          {!viewOnly && (
            <div className={styles.new_line_button} onClick={addDataPoint}>
              Create Line
            </div>
          )}
        </div>
      </div>
      <div className={styles.section_divider_no_margin}></div>

      <div className={styles.guideline_layout}>
        {trainingDataPoints.map((data, index) => {
          const reversedIndex = trainingDataPoints.length - 1 - index;
          const entries = data.data_array.length;
          // const isOpen = openDataSet === data.id;
          const isOpen = true;
          const card_style = isOpen
            ? styles.data_set_item_hover
            : styles.data_set_item;

          const dataPointId = data.id;
          const showOpenEntry = openDataSet === dataPointId;

          return (
            <div
              className={card_style}
              onClick={() => toggleItem(data.id)}
              key={data.id}
            >
              <div className={styles.data_set_tile_info}>
                <div className={styles.data_set_tile_info_text}>
                  <p className={styles.line_row_title}>Line {reversedIndex}</p>
                  <p className={styles.dot}>•</p>
                  <p className={styles.line_row_entry}>{entries} entries</p>
                  {data.section && (
                    <>
                      <p className={styles.dot}>•</p>
                      <p className={styles.line_row_entry}>{data.section}</p>
                    </>
                  )}
                </div>
                <img
                  src="/DropDownIconBlack.png"
                  alt="dropdown"
                  className={`${styles.dropdown_icon} ${
                    isOpen ? styles.rotate_180 : ""
                  }`}
                ></img>
              </div>

              {isOpen && (
                <div className={styles.data_set_item_data_layout}>
                  <div className={styles.data_set_item_data}>
                    {data.data_array.map((data, index) => (
                      <div key={index} className={styles.data_input_options}>
                        {editingEntryId === index && showOpenEntry ? (
                          <EditableDataEntry
                            identity={data.identity}
                            content={data.content}
                            dataPointId={dataPointId}
                            index={index}
                          />
                        ) : (
                          <div className={styles.data_set_item_text_box}>
                            {viewOnly ? (
                              <ViewOnlyDataEntry
                                identity={data.identity}
                                content={data.content}
                                index={index}
                              />
                            ) : (
                              <DataEntry
                                identity={data.identity}
                                content={data.content}
                                dataPointId={dataPointId}
                                index={index}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {!viewOnly && <DataSetControls index={index} />}
                </div>
              )}
            </div>
          );
        })}
        <div className={styles.bottom_section_divider}></div>
      </div>
      <ConfirmDeletePopup
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        deleteFtn={(event) => {
          deleteTrainingDataPoint(event);
        }}
        desc={"Are you sure you want to delete?"}
      />
      <ReplaceTrainingDataPopup
        openModal={replacePopup}
        loading={loadingPopup}
        closeModal={() => setReplacePopup(false)}
        actionFunction={replaceTrainingData}
        desc={"Overwrite Training Data?"}
      />
    </div>
  );

  function DataEntry(props) {
    return (
      <div className={styles.entry_container}>
        <p
          className={styles.data_set_item_text_index}
          onClick={(event) => editEntry(event, props.index, props.dataPointId)}
        >
          {props.index}
        </p>
        <div className={styles.vertical_divider}> </div>

        {props.identity === "system" ? (
          <p
            className={styles.data_set_item_text}
            onClick={(event) =>
              editEntry(event, props.index, props.dataPointId)
            }
          >
            {props.identity}: System Prompt
          </p>
        ) : (
          <p
            className={styles.data_set_item_text_alligned}
            onClick={(event) =>
              editEntry(event, props.index, props.dataPointId)
            }
          >
            {props.identity}: {props.content}
          </p>
        )}
      </div>
    );
  }

  function EditableDataEntry(props) {
    return (
      <div className={styles.data_input_options}>
        <p
          className={styles.data_set_item_text_index}
          onClick={(event) => editEntry(event, props.index, props.dataPointId)}
        >
          {props.index}
        </p>
        <div className={styles.vertical_divider}> </div>

        <div className={styles.data_input_positioning}>
          <div
            className={`${styles.data_input_identity} ${
              editingEntryError &&
              editingEntryId === props.index &&
              !props.identity
                ? styles.entry_error
                : ""
            }`}
            onClick={(event) => toggleIdentityDropdown(event)}
          >
            <input
              type="text"
              value={props.identity}
              className={styles.data_input_identity_input}
              placeholder="-"
              onClick={(event) => toggleIdentityDropdown(event)}
              readOnly
            />
            <img
              src="/BlueDownArrow.png"
              alt="dropdown"
              className={`${styles.dropdown_icon_blue} ${
                dropdownVisible ? styles.rotate_180 : ""
              }`}
            ></img>
          </div>
          {dropdownVisible && (
            <div className={styles.dropdown_content}>
              {identityOptions.map((option, optionIndex) => (
                <div
                  key={optionIndex}
                  className={styles.dropdown_option}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleIdentityChange(option, optionIndex);
                  }}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
        <input
          type="text"
          value={props.content}
          placeholder="Content"
          className={`${styles.data_input_content} ${
            editingEntryError &&
            editingEntryId === props.index &&
            !props.identity
              ? styles.entry_error
              : ""
          }`}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => handleContentChange(e.target.value)}
        />

        <div
          className={styles.trash_control_button}
          onClick={(event) => deleteEntry(event)}
        >
          <TrashIcon />
        </div>

        <div
          className={styles.cancel_control_button}
          onClick={(event) => cancelEditEntry(event)}
        >
          <CancelIcon />
        </div>
        <div
          className={styles.check_control_button}
          onClick={(event) => saveEntry(event)}
        >
          <CheckMarkIcon />
        </div>
      </div>
    );
  }

  function ViewOnlyDataEntry(props) {
    return (
      <>
        <p className={styles.data_set_item_text_index}>{props.index}</p>
        <div className={styles.vertical_divider}> </div>

        {props.identity === "system" ? (
          <p className={styles.data_set_item_text_read_only}>
            {props.identity}: System Prompt
          </p>
        ) : (
          <p className={styles.data_set_item_text_read_only}>
            {props.identity}: {props.content}
          </p>
        )}
      </>
    );
  }

  function DataSetControls(props) {
    return (
      <>
        <p
          className={styles.data_set_delete_button}
          onClick={(e) => {
            e.stopPropagation();
            setDeletePopup(true);
          }}
        >
          Delete Data Set
        </p>
        <p
          className={styles.data_set_add_button}
          onClick={(event) => addNewEntry(event, props.index)}
        >
          Add New Entry
        </p>
      </>
    );
  }

  function CheckMarkIcon() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 6L9 17L4 12"
          // stroke="currentColor"
          className={styles.check_svg}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function CancelIcon() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18M6 6l12 12"
          className={styles.cross_svg}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function TrashIcon() {
    return (
      <svg
        enable-background="new 0 0 512 512"
        height="18px"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 512 512"
        width="18px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="rgba(255, 0, 0, 0.75)">
          {/* Updated fill color */}
          <path d="M444.852,66.908h-99.339V47.04c0-21.943-17.792-39.736-39.736-39.736h-99.339 c-21.944,0-39.736,17.793-39.736,39.736v19.868H67.363v19.868h20.47l19.887,377.489c0,21.944,17.792,39.736,39.736,39.736h218.546 c21.944,0,39.736-17.792,39.736-39.736l19.538-377.489h19.577V66.908z M186.57,47.04c0-10.962,8.926-19.868,19.868-19.868h99.339 c10.962,0,19.868,8.906,19.868,19.868v19.868H186.57V47.04z M385.908,463.236l-0.039,0.505v0.524 c0,10.943-8.906,19.868-19.868,19.868H147.455c-10.942,0-19.868-8.925-19.868-19.868v-0.524l-0.019-0.523L107.72,86.776h297.669 L385.908,463.236z" />
          <rect height="317.885" width="19.868" x="246.173" y="126.511" />
          <polygon points="206.884,443.757 186.551,126.493 166.722,127.753 187.056,445.017" />
          <polygon points="345.649,127.132 325.82,125.891 305.777,443.776 325.606,445.017" />
        </g>
      </svg>
    );
  }
}
