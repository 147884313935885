import React from "react";
import { useEffect } from "react";
import styles from "./settings_tabs.module.css";
import { useState, useContext } from "react";
import { ChatbotGuidelinesContext } from "../../../../context/ChatbotGuidelinesContext";
import SearchBarHeader from "../../../portal_components/universal_components/search_bar_header";

const LiveChatbotSettings = () => {
  const [isEditing, setIsEditing] = useState(false);

  const [pageTitle, setPageTitle] = useState(null);

  const { prompts, activePrompts } = useContext(ChatbotGuidelinesContext);

  //   function handleModalNav(selectedGuideline, currentActivePrompt, type) {
  //     console.log(currentActivePrompt);
  //     setSelectedGuideline(selectedGuideline);
  //     setActivePrompt(currentActivePrompt);
  //     setPromptType(type);
  //     setIsEditing(!isEditing);
  //     updatePageTitle(type);
  //   }

  //   function updatePageTitle(promptType) {
  //     let description = "";

  //     switch (promptType) {
  //       case "FollowUpPrompts":
  //         setPageTitle("Follow Up Prompts");
  //         break;
  //       case "SystemPrompts":
  //         setPageTitle("Main System Prompts");
  //         break;
  //       case "SystemPromptsFineTuning":
  //         setPageTitle("Fine Tuning System Prompts");
  //         break;
  //       default:
  //         description = "Unknown Prompt Type";
  //     }

  //     return description;
  //   }

  //   function back() {
  //     setIsEditing(!isEditing);
  //     setSelectedGuideline(null);
  //     setActivePrompt(null);
  //     setPromptType(null);
  //     setPageTitle(null);
  //   }

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Live Chatbot</p>
      <div className={styles.edit_guidelinees_modal}>
        <div className={styles.divider}></div>
        <div className={styles.global_guideline}>
          <p className={styles.global_title}>ReplyAssist AI</p>
          {/* <div className={styles.global_edit_button}>Edit</div> */}
        </div>
        <div className={styles.divider}></div>
        <div className={styles.guideline_layout}>
          <div className={styles.spacer}></div>
        </div>
      </div>
    </div>
  );
};

export default LiveChatbotSettings;
