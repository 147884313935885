import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./deal_stages.module.css";
import SearchBarHeader from "../universal_components/search_bar_header";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dummy_prospect_data from "../../../data/prospect_data";
import InterestTag from "../universal_components/interest_tag";
import { useNavigate } from "react-router";
import TabHighlight from "../universal_components/tab_highlight";

import EditDealStage from "../../screen_popups/editDealStage";

import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

export default function DealStages() {
  const {
    prospects,
    setProspectDealStage,
    activeDealStages,
    inactiveDealStages,
  } = useContext(AuthContext);

  // const active_deal_stages = [
  //   "Introduction Call Scheduled",
  //   "Qualified Lead",
  //   "Closing Call Scheduled",
  //   "Waiting on Decision",
  //   "Closed Client",
  // ];
  // const inactive_deal_stages = ["Future Sale", "Currently Unfit"];

  const [activeStages, setActiveStages] = useState(activeDealStages);

  const [isEditing, setIsEditing] = useState(false);

  const tabs = ["Active", "Inactive"];

  const handleTabChange = (selectedTab) => {
    console.log("Selected Tab:", selectedTab);
    if (selectedTab === "Active") {
      setActiveStages(activeDealStages);
    } else {
      setActiveStages(inactiveDealStages);
    }
  };

  function handleOnDragEnd(result) {
    const { source, destination } = result;

    if (!destination) return;

    // Ensure it was dropped in a different column
    if (source.droppableId !== destination.droppableId) {
      const draggedProspect = prospects.find(
        (prospect) => prospect.id.toString() === result.draggableId
      );

      if (draggedProspect) {
        setProspectDealStage(draggedProspect.id, destination.droppableId);
      }
    }
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className={styles.main_layout}>
        <p className={styles.page_title}>Deal Stages</p>
        <SearchBarHeader />
        {/* <div className={styles.header_divider}></div> */}
        <TabHighlight
          tabs={tabs}
          buttons={true}
          onTabChange={handleTabChange}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
        />

        <EditDealStage isOpen={isEditing} onClose={() => setIsEditing(false)} />
        <div className={styles.deal_stages}>
          {activeStages.map((stage, index) => (
            <Droppable droppableId={stage}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.deal_stage_tile}
                >
                  <div className={styles.deal_stage_tile_header}>
                    <div className={styles.deal_stage_tile_header_strip}></div>
                    <p className={styles.deal_stage_title}>{stage}</p>
                    <div className={styles.deal_stage_number}>
                      <p>
                        {
                          prospects.filter(
                            (prospect) =>
                              prospect.deal_stage.toUpperCase() ===
                              stage.toUpperCase()
                          ).length
                        }
                      </p>
                    </div>
                  </div>
                  <div className={styles.draggable_area}>
                    {prospects
                      .filter(
                        (prospect) =>
                          prospect.deal_stage.toUpperCase() ===
                          stage.toUpperCase()
                      )
                      .map((prospect, prospectIndex) => (
                        <Draggable
                          key={prospect.id}
                          draggableId={prospect.id.toString()}
                          index={prospectIndex}
                        >
                          {(provided) => (
                            <ProspectTile
                              ref={provided.innerRef}
                              provided={provided}
                              data={prospect}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            />
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                  <div className={styles.buffer}></div>
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </div>
    </DragDropContext>
  );
}

const ProspectTile = React.forwardRef(({ data, ...props }, ref) => {
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function goToInbox() {
    navigate("/inbox");
  }

  return (
    <div ref={ref} className={styles.prospect_tile} {...props}>
      {/* Render the prospect data here */}
      <div className={styles.prospect_tile_top}>
        <div className={styles.prospect_info}>
          <img
            className={styles.prospect_image}
            src={data.profile_image}
            alt="profile"
          ></img>
          <p className={styles.prospect_name}>{data.name}</p>
        </div>
        {/* <div className={styles.prospect_interest}></div> */}

        <div className={styles.more_button} onClick={toggleMenu}>
          <div className={styles.more_dot}> </div>
          <div className={styles.more_dot}> </div>
          <div className={styles.more_dot}> </div>
        </div>
        {showMenu && (
          <div className={styles.menu}>
            <div className={styles.menu_item}>
              <p className={styles.menu_item_text}>Move Deal</p>
            </div>
            <div className={styles.menu_item}>
              <p className={styles.menu_item_text}>Archive</p>
            </div>
            <div className={styles.menu_item}>
              <p className={styles.menu_item_text}>Edit</p>
            </div>
          </div>
        )}
      </div>
      <div className={styles.middle_option}></div>
      <div className={styles.prospect_tile_bottom}>
        <p className={styles.go_to_inbox} onClick={goToInbox}>
          Message
        </p>
        <InterestTag
          level={data.interest_level.toLowerCase()}
          // width={100}
          // height={50}
        />
      </div>
    </div>
  );
});
