import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";

const TestimonialsPage = () => {
  return (
    <>
      <LandingNavbar
        childComponent={<p>Testimonials Page</p>}
        activeText="Testimonials"
      />
    </>
  );
};

export default TestimonialsPage;
