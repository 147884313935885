import styles from "./settings_tabs.module.css";
import cards_on_file from "../../../../data/credit_card_data";
import { useMemo, useState } from "react";
import { useTable } from "react-table";

//#001B8E
const CreditCard = ({ card }) => {
  return (
    <div className={styles.creditCardContainer}>
      <div className={styles.overlayShape}></div>
      <div className={styles.overlayShapeBefore} />
      <div className={styles.cardDetailsLeft}>
        <div className={styles.image_box}>
          <img
            className={styles.cardLogo}
            alt="card type"
            src="/v2Assets/credit_card_logos/visa_logo.png"
          />
        </div>
        <p className={styles.cardNumber}>•••• •••• •••• {card.last_four}</p>
        <div className={styles.cardInfo}>
          <p className={styles.infoTitle}>Name</p>
          <p className={styles.infoValue}>{card.name}</p>
        </div>
      </div>
      <div className={styles.cardDetailsRight}>
        <div className={styles.image_box}>
          <div className={styles.moreButton}></div>
        </div>
        <div className={styles.cardInfoRight}>
          <p className={styles.infoTitle}>Expry Date</p>
          <p className={styles.infoValue}>{card.exprydate}</p>
        </div>
      </div>
    </div>
  );
};

const AddPayment = () => {
  return (
    <div className={styles.addPayment}>
      <div className={styles.addPaymentIcon}>
        {/* <PlusIcon className={styles.icon} /> */}
      </div>
      <p className={styles.addPaymentText}>Add payment method</p>
    </div>
  );
};

const Table = () => {
  const data = useMemo(
    () => [
      {
        id: 1,
        transactionId: "123",
        date: "2023-06-11",
        status: "Success",
        amount: "120",
      },
      {
        id: 2,
        transactionId: "124",
        date: "2023-06-10",
        status: "Pending",
        amount: "300",
      },
      {
        id: 3,
        transactionId: "125",
        date: "2023-06-09",
        status: "Failed",
        amount: "200",
      },
      {
        id: 4,
        transactionId: "126",
        date: "2023-06-08",
        status: "Success",
        amount: "500",
      },
      {
        id: 5,
        transactionId: "127",
        date: "2023-06-07",
        status: "Pending",
        amount: "700",
      },
      {
        id: 6,
        transactionId: "128",
        date: "2023-06-06",
        status: "Failed",
        amount: "600",
      },
      {
        id: 7,
        transactionId: "129",
        date: "2023-06-05",
        status: "Success",
        amount: "800",
      },
      {
        id: 8,
        transactionId: "130",
        date: "2023-06-04",
        status: "Pending",
        amount: "900",
      },
      {
        id: 9,
        transactionId: "131",
        date: "2023-06-03",
        status: "Failed",
        amount: "150",
      },
      {
        id: 10,
        transactionId: "132",
        date: "2023-06-02",
        status: "Success",
        amount: "350",
      },
      {
        id: 11,
        transactionId: "133",
        date: "2023-06-01",
        status: "Pending",
        amount: "450",
      },
    ],
    []
  );

  const [pageIndex, setPageIndex] = useState(0);

  const itemsPerPage = 5;
  const startIndex = pageIndex * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < pageCount - 1;

  const nextPage = () => {
    if (canNextPage) setPageIndex((old) => old + 1);
  };

  const previousPage = () => {
    if (canPreviousPage) setPageIndex((old) => old - 1);
  };

  const pagedData = data.slice(startIndex, endIndex);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "Transaction ID",
        accessor: "transactionId",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data: pagedData,
    });

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Billing History</h1>
      <div className={styles.tableContainer}>
        <table {...getTableProps()} className={styles.table}>
          <thead className={styles.tableHeader}>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, colIndex) => (
                  <th
                    {...column.getHeaderProps()}
                    className={styles.tableHeading}
                    key={colIndex}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className={styles.tableBody}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      {...cell.getCellProps()}
                      className={styles.tableData}
                      key={cellIndex}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.pagination}>
        <button
          onClick={previousPage}
          disabled={!canPreviousPage}
          className={`${styles.btn} ${styles.prevBtn}`}
        >
          Prev
        </button>
        <button
          className={`${styles.btn} ${styles.nextBtn}`}
          onClick={nextPage}
          disabled={!canNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default function PaymentSettings() {
  return (
    <>
      <div className={styles.credit_cards_container}>
        {cards_on_file.map((card, index) => (
          <CreditCard key={index} card={card} />
        ))}
        {/* <AddPayment /> */}
      </div>
      <Table />
    </>
  );
}
