import React from "react";
import { useContext, useState } from "react";

import styles from "./landing_page.module.css";
import Footer from "../footer/footer";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../context/AuthContext";
import CookiePopup from "../../screen_popups/CookiePopup/cookiePopup";

import { useMediaQuery } from "@material-ui/core";
import DemoVideo from "./demo_video";

//ReplyAssist will
//-- do you lead generation
//-- respond to your linkedin direct messages
//-- complete your follow-up tasks
//-- schedule meetings for you
//-- monitor dealstage management

const value_props = [
  {
    image: "/images/ValuePropIcons/ValuePropOne.svg",
    title: "Simple & Convenient",
    subtitle:
      "Manage your entire sales process, start to finish, in ReplyAssist. It was created to be easy to use and keep your prospecting work within one tool, so you no longer need to jump around between 4-5 applications when messaging prospects.",
  },
  {
    image: "/images/ValuePropIcons/ValuePropTwo.svg",
    title: "Compliance-Minded",
    subtitle:
      "Since ReplyAssist was created by a former Investment Adviser Representative, we understand what you can & can’t say when messaging prospects on LinkedIn, and we've accounted for this when creating the tool.",
  },
  {
    image: "/images/ValuePropIcons/ValuePropThree.svg",
    title: "Personalized for Quality",
    subtitle:
      "During Account Setup, we gather information about your objectives for using ReplyAssist so the software can generate personalized responses to prospects for you. This human touch element of ReplyAssist ensures quality conversations & optimal rapport building.",
  },
  {
    image: "/images/ValuePropIcons/ValuePropFour.svg",
    title: "Saves You Time",
    subtitle:
      "Eliminate 67% of your daily prospecting work by using ReplyAssist to respond to your LinkedIn Direct Messages, complete your CRM follow up tasks, and schedule meetings for you. Imagine what it’s like to free up 3+ hours/day of prospecting work.",
  },
  {
    image: "/images/ValuePropIcons/ValuePropFive.svg",
    title: "Custom Availability",
    subtitle:
      "You are able to set your working hours so that ReplyAssist is able to work in the background for you on autopilot. This frees up your time to focus on other, more important tasks, such as working with clients, or even time away from work, such as spending time with family & friends.",
  },
  {
    image: "/images/ValuePropIcons/ValuePropSix.svg",
    title: "Affordable Value",
    subtitle:
      "You could pay an assistant to prospect for you and get average results. And you could spend your valuable time on hours of prospecting each day. Or instead, you can pay an affordable & fixed monthly price to use this all-in-one tool.",
  },
];

const steps = [
  {
    image: "/step1.jpg",
    step: "1",
    title: "Sign Up for an Account",
    subtitle:
      "We can talk all day about the benefits of ReplyAssist, but this is the best way for you to see and get a feel for how powerful ReplyAssist is, and how it’ll work for you!",
  },
  {
    image: "/step2.jpg",
    step: "2",
    title: "Complete Account Setup",
    subtitle:
      "Don’t worry, we’ve made this an easy step-by-step process for you to follow. Although this process only takes about 45 minutes, please don’t rush it. The more specific you are during this process, the more personalized ReplyAssist will be for you!",
  },
  {
    image: "/images/LandingPage/aiImageGraphic.jpg",
    step: "3",
    title: "Activate ReplyAssist",
    subtitle:
      "Once your Account Setup is finished, just click the “Activate ReplyAssist” button, and ReplyAssist will start helping you with your prospecting work!",
  },
];

const LandingPage = () => {
  const [footerNewsletterEmail, setfooterNewsletterEmail] = useState("");
  const { addNewslistUser } = useContext(AuthContext);
  const navigate = useNavigate();

  function handleJoinWaitlist() {
    navigate("/join-newsletter");
  }

  function handleScheduleDemo() {
    window.open("https://calendly.com/austin-replyassist/demo-call", "_blank");
}

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const homeLayout = isDesktop
    ? styles.landing_header
    : styles.landing_header_mobile;

  return (
    <div className={styles.home_components}>
      <CookiePopup />
      <div className={styles.home_header_content}>
        <div className={styles.navbar_box}></div>
        <div className={homeLayout}>
          <div className={styles.landing_header_left}>
            <div className={styles.loader}>
              <p className={styles.replyassist_will}>ReplyAssist</p>
              <div className={styles.words}>
                <span className={`${styles.word} ${styles.word_one}`}>
                  is compliance-aware
                </span>
                <span className={`${styles.word} ${styles.word_two}`}>
                  automates your LinkedIn outreach
                </span>
                <span className={`${styles.word} ${styles.word_three}`}>
                  crafts personalized replies for you
                </span>
                <span className={`${styles.word} ${styles.word_four}`}>
                  schedules & automates your follow ups
                </span>
                <span className={`${styles.word} ${styles.word_five}`}>
                  helps you get meetings scheduled
                </span>
                {/* <span className={`${styles.word} ${styles.word_one}`}>
                do your lead generation
                does all of your follow ups 
              </span> */}
              </div>
            </div>
            <div className={styles.main_copywriting}>
              <p className={styles.title_copywriting}>
                The First Ever, All-In-One LinkedIn Lead Generation + CRM Tool
                for Financial Advisors
              </p>
              <p className={styles.subtitle_copywriting}>
                Grow your client base by efficiently scheduling meetings on
                LinkedIn with your target audience, AND reduce your daily
                prospecting work by 67%
              </p>
            </div>

            <div className={styles.buttons}>
              <div className={styles.trial_button} onClick={handleScheduleDemo}>
                <p className={styles.trial_button_text}>
                  Schedule a Demo Call
                </p>
              </div>
            </div>
          </div>
            {/* <iframe
              src="https://demo.arcade.software/x9CfhJ7SHfwEC5k7VeX0?embed&show_copy_link=true"
              title="ReplyAssist 6-Minute Demo"
              frameborder="0"
              loading="lazy"
              webkitAllowFullScreen
              mozAllowFullScreen
              allowFullScreen
              allow="clipboard-write"
              className={styles.iframe_demo}
            ></iframe> */}
            <DemoVideo/>
        </div>
      </div>

      <div className={styles.banner}>
        <div className={styles.banner_content}>
          <p className={styles.banner_title}>
            Growing your client base is tough.
          </p>
          <p className={styles.banner_content_text}>
            How much is <strong>ineffective prospecting</strong> costing you?
            How many <strong>potential clients</strong> don’t even know you
            exist? How many of your days are <strong>half-wasted</strong>{" "}
            because you don’t have enough prospects to meet with? How many
            prospects reject you when you message them? Are you tired of paying
            marketing companies <strong>without getting any</strong> results?
            How often do you feel like you’re struggling? Can you confidently
            say you don’t need some help? A lack of an{" "}
            <strong>all-in-one-tool</strong> may already be{" "}
            <strong>costing you</strong> a great deal.
          </p>
          {/* <div className={styles.banner_button}>
            <p>How it works</p>
          </div> */}
        </div>
      </div>
      <div className={styles.section}>
        <p className={styles.expierence_title}>
          <strong>ReplyAssist makes prospecting easier.</strong>
        </p>
        <div className={styles.easier_cards}>
          {value_props.map((item) => (
            <div className={styles.easy_card}>
              <div className={styles.value_prop_circle}>
                <img
                  className={styles.value_prop_image}
                  src={item.image}
                  alt="icon"
                ></img>
              </div>
              <div className={styles.value_prop_text}>
                <p className={styles.value_prop_title}>{item.title}</p>
                <p className={styles.value_prop_subtitle}>{item.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.banner_two}>
        <div className={styles.banner_content}>
          <p className={styles.banner_title_medium}>We understand you.</p>
          <p className={styles.banner_paragraph_top}>
            We know how it feels to not know where your next client is coming
            from and how draining it is to waste hours every single day with
            ineffective prospecting methods. All of that time, energy, & hope is
            wasted with no results to show for it. We know what it feels like to
            want to help everyday Americans with their finances, and yet they
            won’t allow you to help them because they won’t give you the time of
            day.
          </p>
          <p className={styles.banner_title_small}>You’re in good hands.</p>
          <p className={styles.banner_paragraph}>
            Below is some proof to show you that we not only care about helping
            you, but we are the correct company to do so.
            <br /> <br />
            We have already helped thousands of financial representatives with
            their marketing through webinars, have had hundreds of financial
            representative clients, and we’ve done work in the financial
            services industry for 7+ years.
            <br /> <br />
            Additionally,{" "}
            <a
              href="https://www.linkedin.com/in/austin-mcculloh/"
              className={styles.linkedInLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              our founder
            </a>{" "}
            is a former Investment Adviser Representative who has personally
            sent 35,000+ Invites on LinkedIn and manually responded to 12,000
            one-on-one Direct Message conversations. This has resulted in a
            little over 650 meetings set from LinkedIn with an 80.62% meeting
            show-up rate. And, the last business he sold generated 52,106 leads
            on LinkedIn for its financial representative clients.
            <br /> <br />
            So, we knew how to build a tool to get you quality conversations &
            meetings from LinkedIn because we’ve already done it for years.
          </p>
        </div>
      </div>
      {/* <div className={styles.section}>
        <div className={styles.easier_cards}>
          {steps.map((item) => (
            <div className={styles.step_card}>
              <img
                className={styles.step_image}
                src={item.image}
                alt="step"
              ></img>
              <div className={styles.step_text}>
                <p className={styles.step}>Step: {item.step}</p>
                <p className={styles.step_tile}>{item.title}</p>
                <p className={styles.step_subtile}>{item.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className={styles.expierence_section}>
        <p className={styles.expierence_title}>
          <strong>We’ve worked with Financial Advisors from…</strong>
        </p>
        <div className={styles.logos}>
          {/* <img
            className={styles.expierence_logo}
            src="/images/FinancialLogos/nm.png"
            alt="landing"
          ></img> */}
          <img
            className={styles.expierence_logo}
            src="/images/FinancialLogos/ej.png"
            alt="landing"
          ></img>
          <img
            className={styles.expierence_logo}
            src="/images/FinancialLogos/massmutual.png"
            alt="landing"
          ></img>
          <img
            className={styles.expierence_logo}
            src="/images/FinancialLogos/primerica.png"
            alt="landing"
          ></img>
          {/* <img
            className={styles.expierence_logo_sm}
            src="/images/FinancialLogos/wfg.png"
            alt="landing"
          ></img> */}
          <img
            className={styles.expierence_logo_sm}
            src="/images/FinancialLogos/buereau.png"
            alt="landing"
          ></img>
        </div>
      </div>
      <div className={styles.letter_section}>
        <div className={styles.letter_content}>
          <p className={styles.letter_text}>
            <strong>At ReplyAssist…</strong> <br /> <br />
            We know you are the kind of person who wants to be financially
            independent, successful, wealthy, and live life on YOUR terms. In
            order to be that way, you need more clients, such as investment
            clients & life insurance clients. The problem is, you are wasting
            too much time with ineffective prospecting while also not getting
            enough meetings scheduled with ideal prospects. And this makes you
            feel frustrated, hopeless, and worn out. We believe it’s unfortunate
            that so much of your role as a Financial Advisor comes down to being
            good at prospecting - it shouldn’t be that way. We understand that
            you’d prefer to be meeting with clients rather than meeting with
            closed-off prospects who aren’t even that excited to meet with you.
            And we know this because we’ve been in your shoes before. That’s why
            we created an all-in-one tool to do your prospecting work for{" "}
            <strong>you</strong>.
            <br />
            <br />
            Here’s how it works: 1. Sign up for an account, 2. Complete your
            Account Setup, and 3. Activate ReplyAssist to let our tool start
            doing your prospecting work for you.{" "}
            <span className={styles.hyperlink} onClick={handleJoinWaitlist}>
              Join our free weeky newsletter
            </span>
            , so you can stop worrying about how you’re going to find your next
            client. Instead, you’ll be focused on growing your business & moving
            towards the financial freedom you’ve always wanted. <br />
            <br />
            We believe <strong>anyone</strong> can be wealthy. Especially you,
            that’s why you are reading this. Sometimes all it takes is{" "}
            <strong>one</strong> domino... <strong>one</strong> tool... to
            change your life forever.
            <br /> <br />
            To winning <strong>together</strong>, <br />
            The ReplyAssist Team
          </p>
        </div>
      </div>
      <div className={styles.skeptical_section}>
        <p className={styles.expierence_title}>
          <strong>Still a bit skeptical? That’s okay.</strong>
        </p>
        <p className={styles.skeptical_text}>
          We know you have some questions for us, so let us address the main
          ones here. <br />
          <br />
          <strong>1.</strong> We know you’ve been burned by other marketing
          companies before. Paying good money for little to no results. We
          tested ReplyAssist many times before launching it to make sure that it
          worked the way it was intended to - to reduce your time spent
          prospecting while also getting you meetings scheduled. <br />
          <strong>2.</strong> We understand that it is important for you to be
          compliant, so we created ReplyAssist with this in mind. The last thing
          we want is to get you in trouble, and that would also reflect poorly
          on us as a business. So we have guidelines in place to make sure
          ReplyAssist is being cautious for you. For example, we know that you
          can’t go promote any particular stocks when messaging on LinkedIn, so
          we have this accounted for within ReplyAssist. <br />
          <strong>3.</strong> ReplyAssist isn’t some “general” prospecting tool.
          It is personalized to prospect <strong>for you specifically</strong>{" "}
          based on how you complete your Account Setup, and it customizes its
          response for EACH of your LinkedIn conversations because no two
          prospects of yours are the exact same. It is as personalized as you
          would be, if not even more personalized. If you want to better
          understand how ReplyAssist is even more personalized, ask us how.{" "}
          <br />
          <strong>4.</strong> What if you don’t like using ReplyAssist? Well, we
          doubt that’ll happen. But IF you want to stop using it, you can cancel
          your subscription anytime with a 30-day notice.
        </p>
      </div>
      <div className={styles.letter_section}>
        <div className={styles.letter_content2}>
          <p className={styles.letter_text}>
            <img
              className={styles.expierence_logo2}
              src="/images/AdvisorCheckLogoEdited.png"
              alt="landing"
            ></img>
            <br></br>
            Take 1 minute to claim your free{" "}
            <a
              href="https://advisorcheck.com?utm_source=replyassist&utm_medium=website&utm_campaign=homepage"
              className={styles.linkedInLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              AdvisorCheck
            </a>{" "}
            profile.
            <br />
            <br />
            We are committed to being a holisitc resource to the entire
            financial services industry, and that's why we've decided to partner
            with companies with a <br></br>similar vision to ours, like
            AdvisorCheck.
            <br></br>
            <br></br>Advisors, we encourage you to check out AdvisorCheck to
            help build deeper trust & rapport with prospective clients you
            <br></br>are talking to. Visit their site to learn more!
            <br />
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;