import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAVGYUkIHbYUob5sj1EzKvsEvEZJPUHtmI",
  authDomain: "reply-assist.firebaseapp.com",
  projectId: "reply-assist",
  storageBucket: "reply-assist.appspot.com",
  messagingSenderId: "103009107310",
  appId: "1:103009107310:web:2bc5b31d6c343b0cea957f",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
