import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import ResourcesComponents from "../../components/landing_components/resourcesPage/ResourcesComponents";
import LegalAgreementsPage from "../../components/landing_components/LegalAgreementsPage/LegalAgreementsPage";

const LegalAgreements = () => {
  return (
    <div>
      <LandingNavbar childComponent={<LegalAgreementsPage />} activeText="Legal Agreements" />
    </div>
  );
};

export default LegalAgreements;
