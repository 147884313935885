import React from "react";
import { useState, useContext, useRef } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";

// import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

const CompanyInfo = ({ onNext, onBack, userId }) => {
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const fileInputRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(CompanyIndustries);

  const [editProfile, setEditProfile] = useState({
    companyName: "",
    companyWebsite: "",
    companyIndustry: "",
    introduction: "",
  });

  const { updateAccountProfilePicture, updateUserProfile } =
    useContext(AuthContext);

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedProfilePicture(URL.createObjectURL(file));
      updateAccountProfilePicture(userId, file);
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const canContinue = selectedProfilePicture !== null;

  const filterOptions = (query) => {
    const filtered = CompanyIndustries.filter((option) =>
      option.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterOptions(query);
  };

  const handleOptionSelect = (option) => {
    console.log("Option:", option);
    setSearchQuery(option);
    setEditProfile((prevState) => ({
      ...prevState,
      companyIndustry: option, // Update the companyIndustry in editProfile
    }));
    setDropdownOpen(false);
  };

  const handleSave = async () => {
    try {
      await updateUserProfile(userId, editProfile);
    } catch (error) {
      console.error("Error saving company info:", error);
    }
  };

  function handleContinue() {
    handleSave();
    onNext();
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content_right}>
        <div className={styles.sidebar_right}>
          <p className={styles.page_title}>Account Information</p>
          <div className={styles.proflie_picture_section}>
            <div className={styles.profile_picture_bg}>
              <img
                alt="logo"
                src={selectedProfilePicture || "/DefaultImage.png"}
                className={styles.profile_picture}
              ></img>
            </div>
            <div className={styles.update_profile_picture}>
              <p onClick={handleUploadButtonClick}>Upload Profile Picture</p>
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleProfilePictureChange}
              />
            </div>
          </div>
          <p className={styles.login_input_titles}>Industry</p>
          <div className={styles.dropdown_input_option}>
            <div className={styles.dropdown_input}>
              <input
                className={styles.dropdown_input_element}
                name="industry"
                value={searchQuery}
                placeholder="Select Industry"
                onChange={handleInputChange}
                onFocus={() => setDropdownOpen(true)}
                // onBlur={() => setDropdownOpen(false)}
              />
            </div>
            {isDropdownOpen && (
              <div className={styles.dropdown_options}>
                {filteredOptions.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => handleOptionSelect(option)}
                    className={styles.dropdown_item}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
          <p className={styles.login_input_titles}>
            Account / Company Bio (Optional)
          </p>
          <div className={styles.paragraph_input}>
            <textarea
              name="introduction"
              value={editProfile.introduction}
              onChange={handleEditChange}
              className={styles.textarea_input}
            ></textarea>
          </div>
          <p className={styles.login_input_titles}>Company Name (Optional)</p>
          <div className={styles.email_input}>
            <input
              name="companyName"
              value={editProfile.companyName}
              onChange={handleEditChange}
              className={styles.input}
            ></input>
          </div>
          <p className={styles.login_input_titles}>
            Company Website (Optional)
          </p>
          <div className={styles.email_input}>
            <input
              name="companyWebsite"
              value={editProfile.companyWebsite}
              onChange={handleEditChange}
              className={styles.input}
            ></input>
          </div>

          {canContinue ? (
            <div onClick={handleContinue} className={styles.page_button}>
              <p className={styles.join_button_text}>Continue</p>
            </div>
          ) : (
            <div className={styles.page_button_inactive}>
              <p className={styles.join_button_text}>Continue</p>
            </div>
          )}
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/images/LandingPage.png"
        alt="landing"
      ></img>
    </div>
  );
};

export default CompanyInfo;

const CompanyIndustries = [
  "Accounting",
  "Administration & Office Support",
  "Advertising, Arts & Media",
  "Banking & Financial Services",
  "Call Centre & Customer Service",
  "Community Services & Development",
  "Construction",
  "Consulting & Strategy",
  "Design & Architecture",
  "Education & Training",
  "Engineering",
  "Farming, Animals & Conservation",
  "Government & Defence",
  "Healthcare & Medical",
  "Hospitality & Tourism",
  "Human Resources & Recruitment",
  "Information & Communication Technology",
  "Insurance & Superannuation",
  "Legal",
  "Manufacturing, Transport & Logistics",
  "Marketing & Communications",
  "Mining, Resources & Energy",
  "Music",
  "Real Estate & Property",
  "Retail & Consumer Products",
  "Sales",
  "Science & Technology",
  "Social Media",
  "Sport & Recreation",
  "Trades & Services",
];
