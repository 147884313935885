import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import SignInComponent from "../../components/auth_components/signin_page/signin_component";
import SignIn from "../../components/auth_components/signin_page/SignIn";

const SignInPage = () => {
  return (
    <>
      <LandingNavbar childComponent={<SignInComponent />} activeText="SignIn" />
    </>
  );
};

export default SignIn;
