import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import InboxComponent from "../../components/portal_components/inbox_page/inboxComponent";

const CampaignsPage = () => {
  return (
    <>
      <Sidebar childComponent={<InboxComponent />} activeText="Campaigns" />
    </>
  );
};

export default CampaignsPage;
