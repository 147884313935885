import React from "react";
import { useState, useContext } from "react";

import styles from "./signup.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { signIn, createAccount, error } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    signIn(username, password)
      .then((userCredential) => {
        if (userCredential) {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
        // Optionally, show the user an error message here
      });
  };

  const canContinue = username !== "" && password;

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.sidebar}>
          {/* <div className={styles.back_button} onClick={() => navigate("/home")}>
            <img
              className={styles.back_icon}
              src="/images/GeneralIcons/GeneralBackButton.png"
              alt="back"
            ></img>
            <p className={styles.back_text}>Back to Main</p>
          </div> */}
          {/* <p className={styles.page_title}>ReplyAssist</p> */}
          <img
            src="/images/ReplyAssist-New-Logo-Transparent.png"
            alt="logo"
            className={styles.logo}
          ></img>
          <p className={styles.join_waitlist}>Sign In</p>

          <div className={styles.email_input}>
            <input
              onChange={(event) => setUsername(event.target.value)}
              placeholder="Email"
              className={styles.input}
            ></input>
          </div>
          <div className={styles.email_input}>
            <input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Password"
              className={styles.input}
            ></input>
          </div>

          {canContinue ? (
            <div onClick={handleSubmit} className={styles.join_button}>
              <p className={styles.join_button_text}>Sign In</p>
            </div>
          ) : (
            <div className={styles.join_button_inactive}>
              <p className={styles.join_button_text}>Sign In</p>
            </div>
          )}
          {/* <p className={styles.note}>
            Please note: By submitting this form, we will be opting you into our
            free weekly email newsletter. If you ever get sick of us, you can
            opt-out at any time!
          </p> */}
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/images/LandingPage.png"
        alt="landing"
      ></img>
    </div>
  );
};

export default SignIn;


