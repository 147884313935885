import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

// import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

const CompanyBrands = () => {
  return <div className={styles.home_components}></div>;
};

export default CompanyBrands;
