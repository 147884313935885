import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./faq.module.css";

const faq_data = [
  {
    question: "Where is ReplyAssist based out of as a company?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: Chicago, Illinois.</strong>
        <br />
        <br />
        We know that a lot of technology companies are based out of other
        countries, and that would be a major concern to you as a US-based
        advisor.
        <br />
        <br />
        Our founder, Austin McCulloh, is from Iowa, so we completely understand how important it is for you to
        work with a US-based company (since United States Financial Advisors
        only work with United States residents, in general).
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "Is ReplyAssist trying to replace me as a Financial Advisor? ",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: No.</strong>
        <br />
        <br />
        We believe that most Financial Advisors constantly have to juggle two
        roles, which isn’t optimal. These two roles are:
        <br />
        <br />
        1. Being a Financial Advisor.
        <br />
        2. Being a Salesperson.
        <br />
        <br />
        So, the aim of ReplyAssist is to reduce your work as a Salesperson so
        you can put more time into being the best Financial Advisor possible for
        your clients. We aren’t trying to replace you at all - ReplyAssist is
        VERY different from a robo advisor. In fact, our intention is to free up
        your time to make you an even better Financial Advisor!
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "How will ReplyAssist improve my life?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: Many ways.</strong>
        <br />
        <br />
        Time saved. Better Results. Better Life.
        <br />
        <br />
        More meetings set with prospects so you can close more clients. More
        down time to spend with clients, family, friends, or on hobbies. Less
        stress from dealing with difficult prospects & rejection. Less time
        spent hunched over your computer/phone typing out long, detailed
        messages.
        <br />
        <br />
        The list goes on. ReplyAssist’s mission is for you to{" "}
        <strong>Live Different</strong>, and we truly mean it.
      </p>
    ),
  },
  {
    question:
      "Why not just save the money & do all of this prospecting work on my own?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: Well, do you value your time or your money more? You can always
          make more money, but you can’t get your time back.
        </strong>
        <br />
        <br />
        Sure, you can save some money each month by not using ReplyAssist, but
        we don’t believe it’s worth your time or effort to do all of your
        prospecting work on your own. Responding to hundreds of LinkedIn Direct
        Messages and doing hundreds of follow up tasks in your CRM each month is
        time consuming, and ReplyAssist will save you roughly 40 hours per month
        by reducing these tasks for you (most of your total prospecting work).
        For example, if you make $60/hour, that is $2,400 saved each month in
        terms of your time. Imagine what else you could do with 40 extra hours
        each month…
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "How do I know LinkedIn prospecting even works?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: ANY prospecting method can work IF it is done the proper way.{" "}
        </strong>
        <br />
        <br />
        For example, our Founder, Austin McCulloh, hates cold calling and
        knocking door-to-door to prospect, BUT he understands that these methods
        also work <strong>IF</strong> done the correct way.
        <br />
        <br />
        With LinkedIn, you can’t be quite as “direct” in your prospecting
        approach because you need to build rapport first. But you can build a
        relationship on any social media platform - you don’t need to meet in
        person to build a quality relationship. The way to build a relationship
        online is different from building a relationship in person, and we kept
        this in mind while creating ReplyAssist - hence why we are such big
        advocates of the scheduled Follow Ups portion of the software.
        <br />
        <br />
        To circle back to cold calling & door knocking, we do believe LinkedIn
        is MORE effective than those. As the years move on, fewer & fewer people
        are willing to answer calls from strangers (or phone calls in general).
        And the same concept applies to door-knocking. Plus, both cold calling
        and door-knocking, in general, tend to be more time-consuming than
        messaging on social media, and they require more effort.
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "Who can I target with my ReplyAssist Campaigns? As in, what type of prospects?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: Almost anyone on LinkedIn.{" "}
        </strong>
        <br />
        <br />
        If you can find them on LinkedIn, there's a way for us to help you contact them.
        <br />
        <br />
        And keep in mind that we can help you exclude certain people, as well. For example, if you don't want your Campaign to be targeting other Financial Advisors, there are multiple ways we can help you avoid them.
        <br />
        <br />
      </p>
    ),
  },
  {
    question:
      "How do I know ReplyAssist will work for me as a Financial Advisor?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: Multiple reasons.</strong>
        <br />
        <br />
        1. Our executive team has worked with financial advisors, just like you,
        for over 7 years, which includes running a digital marketing firm from
        2020 - 2023. During those years, we generated 54,000+ leads (lead = a
        response from an ideal prospect) for financial advisor clients. This has
        resulted in thousands of meetings set for those clients.
        <br />
        <br />
        2. Our Founder, Austin McCulloh, was a licensed Financial Advisor
        for multiple years, so he understands the most common objections &
        questions a financial representative (you) will hear from a prospect,
        and how to answer them in a compliant way.
        <br />
        <br />
        3. Austin has sent 35,000+ Invites on LinkedIn, as well as he has
        responded to over 12,000 Direct Message conversations with prospects on
        LinkedIn personally. This has resulted in over 650 meetings set from
        LinkedIn with an 80.62% meeting show-up rate.
        <br />
        <br />
        <strong>To summarize</strong> - We understand the problems you face when
        prospecting, as well as how to overcome them based on our real-world
        experience, so we have accounted for that while building ReplyAssist.
        <br />
        <br />
      </p>
    ),
  },
  {
    question:
      "I’m a Financial Advisor, which isn't the same job as a Life Insurance Agent. Will ReplyAssist know the difference?",
    answer: (
      <p className={styles.answer_text}>
        <strong> A: Yes.</strong>
        <br />
        <br />
        Part of the Onboarding process for each new customer is to ask for your
        input on factors such as your main objective of using ReplyAssist & what
        products/services you offer to your clients. Which means, your
        ReplyAssist account is tailored specifically for you, your job title,
        and your goals.
        <br />
        <br />
        Based on our years of experience of working with thousands of different
        financial services professionals, (Financial Advisors, Life Insurance
        Agents, etc), we understand that the way a Financial Advisor sells to a
        prospect is not the same way a Life Insurance Agent sells to a prospect,
        so we built ReplyAssist with this in mind. In addition, we know that
        Financial Advisors have far more compliance rules to follow due to your
        securities license(s), certification(s), etc., so we have factored these
        guidelines into our software.
        <br />
        <br />
      </p>
    ),
  },
  {
    question:
      "Since ReplyAssist has AI-powered features, do I need to use them?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: No. It is fully within your control to turn them off or on.
        </strong>
        <br />
        <br />
        Although we want to help you as much as possible, and AI is a powerful
        tool to assist you, we understand how important it is for you to be
        compliant as a financial advisor, so you are <strong>not</strong>{" "}
        required to use <strong>any</strong> AI while using ReplyAssist.
        <br />
        <br />
        So, you have <strong>complete control</strong> over any of the
        AI-powered features.
        <br />
        <br />
        To give you peace of mind, below are some ReplyAssist features, as well
        as examples of how AI is <strong>not</strong> required to use them:
        <br />
        • Automated Responder - this allows the AI to reply on your behalf, but
        it is set to "off" as the default option when you create your account.
        <br />
        • Generate AI Reply button - this allows you to use AI to create
        personalized & relevant messages to send to prospects. But you do not
        need to use this button, and even if you do, you still need to manually
        press "send" to deliver the message. Also, you're able to edit any AI
        Generated Reply before sending it, too.
        <br />
        • Scheduled Follow Ups - these do not use AI. And, you are able to fully
        customize the future follow up messages that send, as well as their due
        dates.
        <br />
        <br />
        To summarize how we at ReplyAssist view the use of AI, here’s a quote
        from Hubspot’s CMO, Kipp Bodnar, within Hubspot's “The State of AI in
        Marketing in 2023” report:
        <br />
        <br />
        <span className={styles.hubspot_quote_text}>
          “AI will never replace human creativity and connection. Lived
          experiences and opinions will never be able to be generated by a
          machine, and an employee can’t be mentored and managed by AI. AI is a
          great tool for marketers to use, but would be useless without human
          knowledge, strategy, and implementation.{" "}
        </span>
        <strong>
          But when it comes to menial tasks that take up way too many of our
          hours — let’s leave those to the robots.“
        </strong>
        <br />
        <br />
      </p>
    ),
  },
  {
    question:
      "Am I required to use the Automated Responder to reply to conversations with prospects?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: No. </strong>
        <br />
        <br />
        We actually recommend that you do <strong>not</strong> use the Automated
        Responder yet since it is in early development, and the Automated
        Responder is turned <strong>off</strong> as the default option for each
        new user.
        <br />
        <br />
        Below are the two ways you can reply to ongoing conversations in your
        Inbox.
        <br />
        <br />
        <strong>
          Option 1 (recommended): Manual Replying + Generate AI Reply button
        </strong>
        <br></br>
        If you still want control over the messages that you are sending to
        prospects, we recommend using this option. With this approach, you can
        manually type out your reply to each prospect and/or you can click the
        "Generate AI Reply" button at the bottom of your Inbox, which will
        generate a personalized & relevant message to send to your prospect.
        With this option, you are the one pressing "send" to deliver the
        message, and also keep in mind that you are able to edit any
        AI-generated reply before sending it, as well. If you dislike any
        AI-generated reply, you are able to click the "Generate AI Reply" button
        again to generate a new message.
        <br />
        <br />
        <strong>Option 2: Automated Responder</strong>
        <br></br>
        If you are want to put almost all of your prospecting on autopilot, this
        is the right choice for you. With this option, the ReplyAssist AI
        Chatbot will detect when to reply on your behalf to prospects, and
        engage with them to build rapport & politely encourage them to schedule
        an introduction/discovery meeting with you if they indicate some level
        of interest in meeting. Compliance was top-of-mind when creating this
        feature, so we have directed it to be compliant by not providing advice
        or making recommendations when messaging on your behalf.
        <br />
        <br />
      </p>
    ),
  },
  {
    question:
      "What if one of my prospects asks if they’re talking to a robot and/or chatbot while the Automated Responder is turned on?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: If this does happen (which we expect is rare), we have designed
          ReplyAssist to notify you over email.{" "}
        </strong>
        <br />
        <br />
        Giving your prospects peace of mind is important, so we suggest that you
        personally respond back to that specific prospect to assure them that,
        at the end of the day, they would be working with a human (you), not a
        robot. And don't worry, our notifications system will make it clear
        which conversations require your manual input vs. those that don't
        require your input.
      </p>
    ),
  },
  {
    question:
      "How do I know ReplyAssist won’t get me in trouble with compliance?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: Compliance was kept top-of-mind the entire time we created the
          software.{" "}
        </strong>
        <br />
        <br />
        The last thing we want is for you to get in trouble with your leadership
        team and/or company, so we have been extremely diligent with putting
        safeguards, rules, & parameters in place to make sure ReplyAssist is as
        compliant as possible.
        <br />
        <br />
        Additionally, we go by this rule of thumb / philosophy of thinking:
        <br />
        <br />
        <strong>
          “If it’s going to get you in trouble with compliance, it’s not
          effective to use when prospecting.”{" "}
        </strong>
        <br />
        <br />
        Meaning, anything that will get you in trouble with compliance is too
        “salesy” in the first place. For example, if you’re trying to talk about
        stock market returns with prospects while messaging on LinkedIn, you are
        getting too ahead of yourself. The purpose of prospecting on LinkedIn is
        to get your prospects <strong>curious</strong> and{" "}
        <strong>interested</strong> to learn more. So, once a prospect begins to
        show interest in learning more, the best next steps are to schedule a
        video/phone call together. Providing in-depth answers and/or selling are
        meant to be done on the meeting with a prospect, NOT over LinkedIn
        Direct Messages (DMs).
        <br />
        <br />
        <br />
        Important Note when using our Automated Responder (which you are{" "}
        <strong>not</strong> required to use):
        <br />
        <br />
        Given the nature of Artificial Intelligence, we are not able to
        guarantee with 100% certainty the effectiveness of our Automated
        Responder. Which means, we cannot guarantee that there is no risk
        associated with using the Automated Responder in regard to following
        compliance guidelines. Although we used our experience of being licensed
        in the financial services industry in the past to keep compliance
        guidelines in mind, Artificial Intelligence is not perfect, so there is
        a chance for miswording & errors. If you are excited about the benefits
        of ReplyAssist and still want to use the software, but are cautious
        about compliance, we recommend you leave the Automated Responder turned
        “off”, and use the "Generate AI Reply" button to manually reply to
        messages. That way, you still get the benefits of highly personalized &
        relevant messages to send to prospects, but you also get to review the
        AI-generated reply & edit it, before pressing send.
        <br />
      </p>
    ),
  },
  {
    question: "How can I get the full benefit out of using ReplyAssist?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: 1. Complete your Onboarding right after making your payment. 2.
          Don't expect immediate results.{" "}
        </strong>
        <br />
        <br />
        Some customers will start having great LinkedIn conversations on the
        first day of using ReplyAssist. But, sometimes that's just beginners
        luck. Since your prospects are human, (as in, they have emotions), some
        might not respond all too well to you contacting them on LinkedIn. Even
        the best salespeople in the world are told “No”, so please do understand
        that just because you'll get responses like "No", "No, thank you", and
        "I'm not interested" does not mean that ReplyAssist is ineffective.{" "}
        <br />
        <br />
        Sales is about rejection & having a long-term mindset, so we know that
        you won’t be able to convert <strong>most</strong> of your prospects no
        matter how great you and/or your sales tools (like ReplyAssist) are -
        that's the nature of working in sales.
        <br />
        <br />
        From the book, The 1-Page Marketing Plan by Allan Dib, roughly 30% of
        your prospects are not interested in what you have to offer at all, and
        another 30% would not want what you have to offer even if it was free.
        That is <strong>60% (or more)</strong> of your prospects who will never
        work with you, regardless of how skilled you are or how great the
        software is that you're using.
        <br />
        <br />
        So, given how results aren't immediate with ReplyAssist, we recommend
        you try ReplyAssist for at least 90 days (3 months) and reassess from
        there. We believe you’ll want to continue using the tool for years going
        forward, but you have to start & be committed with it for at least a few
        months to start seeing the <strong>full benefits.</strong> In fact, due
        to features, like our scheduled Follow Ups functionality, we believe
        ReplyAssist will become even more effective for you the longer you use
        the software because you'll start to create stronger, deeper rapport
        with more prospects by consistently staying top-of-mind.
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "Are there any contracts with the ReplyAssist subscription?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: No.</strong>
        <br />
        <br />
        We do recommend that you use the software for at least 90 days before
        making a decision to discontinue its use (if you decide that is what's
        best for you).
        <br />
        <br />
        Sales is never a walk in the park (it's never easy), so it’ll take some
        time to see the full benefits of using the ReplyAssist software.
      </p>
    ),
  },
  {
    question: "Does ReplyAssist have a referral/affiliate program?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: Yes, we have a Referral Program!</strong>
        <br />
        <br />
        We currently have no cap on our Referral Program, either, so refer as
        many people as you'd like (even if that’s 25+ people)!
        <br />
        <br />
        Visit our <a href="/pricing" className={styles.link}><strong>Pricing page</strong></a> for more info.
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "What if I don’t like using ReplyAssist and want to stop/cancel?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: We’d love to keep you around as a customer, but we understand that
          ReplyAssist isn’t for everyone.
        </strong>
        <br />
        <br />
        Just reach out to us at support@replyassist.com to cancel your subscription, and let us know at least 30 days
        before your next payment is due. Then, we’ll take care of stopping your
        subscription on our end. For example, if your payment is due on July
        15th, notify us no later than June 15th.
        <br />
        <br />
        <strong>
          In addition, we hate when companies aren’t transparent, so here’s why
          we have a 30-day cancellation policy:
        </strong>
        <br />
        <br />
        In order for ReplyAssist to be the best company & product possible for
        you, we make estimations on future earnings to reinvest part of our
        profit to improve our technology, systems, grow our team, and constantly
        enhance our customer experience. So, if we have customers randomly
        leaving without prior notice, it's tough to be on the "offensive" and
        properly grow our business to best serve customers. Instead, we'd be in
        "defensive mode", and the byproduct of operating in "defensive mode" in
        business would lead to lower ReplyAssist product quality, plus lower
        overall company & team morale. That isn't good for you or for us.
        <br />
        <br />
        <strong>PS - </strong> Even if you do stop your payments, always keep in
        mind that we’d love to get you using ReplyAssist again in the (near)
        future! And, if at any point you feel we could improve something about
        our software, customer experience, or anything else within the business,
        please do <strong>not</strong> hesitate to let us know!
        <br />
        <br />
      </p>
    ),
  },
];

const FAQComponents = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.faq_components}>
      <div className={styles.faq_content}>
        {/* <p className={styles.title}>FAQ</p>
        <p className={styles.subtitle}>
          Everything you wanted to know about ReplyAssist! If you still have
          questions after reading the below FAQs, please reach out and let us
          know.
        </p> */}

        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Frequently Asked Questions</p>
            <p className={styles.page_subtitle}>
              Everything you wanted to know about ReplyAssist! If you still have
              questions after reading the below FAQs, please reach out and let
              us know.
            </p>
          </div>
        </div>
        <div className={styles.page_line_break}></div>

        <div className={styles.faq_list_items}>
          {faq_data.map((item, index) => (
            <div key={index}>
              <div
                className={styles.faq_tile}
                onClick={() =>
                  setExpandedIndex(index === expandedIndex ? null : index)
                }
              >
                <p className={styles.item_question}>{item.question}</p>
                <img
                  className={`${styles.down_arrow_image} ${
                    expandedIndex === index ? styles.flipped : ""
                  }`}
                  src="/images/down-arrow.png"
                  alt="expand"
                ></img>
              </div>
              {expandedIndex === index && (
                <div className={styles.faqanswer}>{item.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.spacer}></div>
    </div>
  );
};

export default FAQComponents;
