const cards_on_file = [
  {
    id: "1",
    name: "John Smith",
    type: "Visa",
    last_four: "9362",
    exprydate: "07/27",
  },
  {
    id: "1",
    name: "John K Smith",
    type: "Visa",
    last_four: "1425",
    exprydate: "04/24",
  },
];

export default cards_on_file;
