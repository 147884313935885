import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import InboxComponent from "../../components/portal_components/inbox_page/inboxComponent";

const InboxPage = () => {
  return (
    <>
      <Sidebar childComponent={<InboxComponent />} activeText="Inbox" />
    </>
  );
};

export default InboxPage;
