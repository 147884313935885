import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import LandingPage from "../../components/landing_components/landing_page/landing_page";

const HomePage = () => {
  return (
    <div>
      <LandingNavbar childComponent={<LandingPage />} activeText="Home" />
    </div>
  );
};

export default HomePage;
