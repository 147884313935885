import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./settings.module.css";
import LiveChatbotSettings from "./settings_tabs/LiveChatbotSettings";
import { AuthContext } from "../../../context/AuthContext";
import CustomChatbotSettings from "./settings_tabs/CustomChatbotSettings";
import BannedPhrasesSettings from "./settings_tabs/BannedPhrasesSettings";
import SearchReplaceSettings from "./settings_tabs/SearchReplaceSettings";

export default function AdminSettings() {
  const [activeTab, setActiveTab] = useState("Live Chatbot");
  const tabs = [
    "Live Chatbot",
    "Custom Chatbots",
    "Banned Phrases",
    "Search & Replace",
    // "Follow Up Detection",
  ];
  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);

  return (
    <div className={styles.accountContainer}>
      <p className={styles.settingsTitle}>Admin Settings</p>
      <div className={styles.tabs} ref={tabsRef}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>
      <div className={styles.tab_layout}>
        {activeTab === "Live Chatbot" && <LiveChatbotSettings />}
        {activeTab === "Custom Chatbots" && <CustomChatbotSettings />}
        {activeTab === "Banned Phrases" && <BannedPhrasesSettings />}
        {/* {activeTab === "Search & Replace" && <SearchReplaceSettings />} */}
      </div>
    </div>
  );
}
