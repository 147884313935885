import React from "react";
import styles from "./universal.module.css";
import { useState, useRef, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
// import FilterMenu from "../universal_components/filter_menu";

function InterestTag(props) {
  const height = props.height;
  const width = props.width;
  const { updateProspectInterestLevel } = useContext(AuthContext);

  //

  let tag_color;

  switch (props.level) {
    case "high interest":
      tag_color = "rgba(0, 61, 130, 1)";
      break;
    case "medium interest":
      tag_color = "rgba(0, 61, 130, 0.75)";
      break;
    case "low interest":
      tag_color = "rgba(0, 61, 130, 0.45)";
      break;
    case "neutral":
      tag_color = "rgba(0, 0, 0, 0.4)";
      break;
    case "not interested":
      tag_color = "rgba(0, 0, 0, 0.75)";
      break;
    default:
      tag_color = "defaultColor";
      break;
  }

  const toTitleCase = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const handleInterestLevelChange = (newValue) => {
    props.setLevel(newValue);
    updateProspectInterestLevel(props.prospectId, newValue);
  };

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={styles.interest_tag}
      onClick={toggleMenu}
      style={{
        backgroundColor: tag_color,
        height: height,
        width: width,
      }}
    >
      <p className={styles.interest_tag_text}>{toTitleCase(props.level)}</p>
      {showMenu && (
        <div className={styles.menu}>
          <div
            className={styles.menu_item}
            onClick={() => handleInterestLevelChange("HIGH INTEREST")}
          >
            <p className={styles.menu_item_text}>High Interest</p>
            <div
              className={styles.interest_dot}
              style={{
                backgroundColor: "rgba(0, 61, 130, 1)",
              }}
            ></div>
          </div>
          <div
            className={styles.menu_item}
            onClick={() => handleInterestLevelChange("MEDIUM INTEREST")}
          >
            <p className={styles.menu_item_text}>Medium Interest</p>
            <div
              className={styles.interest_dot}
              style={{
                backgroundColor: "rgba(0, 61, 130, 0.75)",
              }}
            ></div>
          </div>
          <div
            className={styles.menu_item}
            onClick={() => handleInterestLevelChange("LOW INTEREST")}
          >
            <p className={styles.menu_item_text}>Low Interest</p>
            <div
              className={styles.interest_dot}
              style={{
                backgroundColor: "rgba(0, 61, 130, 0.45)",
              }}
            ></div>
          </div>

          <div
            className={styles.menu_item}
            onClick={() => handleInterestLevelChange("NEUTRAL")}
          >
            <p className={styles.menu_item_text}>Neutral</p>
            <div
              className={styles.interest_dot}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              }}
            ></div>
          </div>
          <div
            className={styles.menu_item}
            onClick={() => handleInterestLevelChange("NOT INTERESTED")}
          >
            <p className={styles.menu_item_text}>Not Interested</p>
            <div
              className={styles.interest_dot}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.75)",
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InterestTag;
