import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import styles from "./contactsales.module.css";
import { AuthContext } from "../../../context/AuthContext";
import CheckmarkIconWhite from "../../../assets/svg/CheckIconWhite";

import { useMediaQuery } from "@material-ui/core";

const ContactSalesComponents = () => {
  const navigate = useNavigate();
  const { addBrevoMainContact } = useContext(AuthContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [company, setCompany] = useState("");
  const [linkedin, setLinkedin] = useState("");

  function handleSubmit() {
    console.log("submit");
    const [firstName, lastName] = name.split(" ");
    addBrevoMainContact(email, firstName, lastName, company, number, 13);
    toast.success("Sent to Sales!");
    navigate("/");
  }

  const canContinue =
    name !== "" && email !== "" && number !== "" && company !== "";

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <div className={styles.main_layout}>
      <div className={styles.spacer}></div>
      <div className={styles.blue_background_banner}>
        {isDesktop && (
          <div className={styles.page_content_banner}>
            <p className={styles.feature_text_title}>
              Chat with our sales team
            </p>
            <div className={styles.feature}>
              <CheckmarkIconWhite />
              <div className={styles.feature_text}>
                Fully Automate Your Prospecting
              </div>
            </div>
            <div className={styles.feature}>
              <CheckmarkIconWhite />
              <div className={styles.feature_text}>
                Learn How To Increase Team Productivity
              </div>
            </div>
            <div className={styles.feature}>
              <CheckmarkIconWhite />
              <div className={styles.feature_text}>Get Pricing Information</div>
            </div>
            <div className={styles.feature}>
              <CheckmarkIconWhite />
              <div className={styles.feature_text}>
                Explore Personalized Use Cases
              </div>
            </div>
          </div>
        )}
      </div>
      {isDesktop && <div className={styles.page_content}></div>}
      <div className={styles.form_content}>
        <p className={styles.form_content_title}>Contact Sales</p>
        <div className={styles.form_content_fields}>
          <div className={styles.content_field}>
            <p className={styles.field_title}>Full Name</p>
            <input
              className={styles.input_field}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={styles.content_field}>
            <p className={styles.field_title}>Your Business Email</p>
            <input
              className={styles.input_field}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.content_field}>
            <p className={styles.field_title}>Your Company Name</p>
            <input
              className={styles.input_field}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div className={styles.content_field}>
            <p className={styles.field_title}>Your LinkedIn URL (Optional but preferred)</p>
            <input
              className={styles.input_field}
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
            />
          </div>
          <div className={styles.content_field}>
            <p className={styles.field_title}>Phone Number</p>
            <input
              className={styles.input_field}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.bottom_section}>
          <div
            className={styles.submit_button}
            onClick={canContinue ? handleSubmit : null}
            style={{ opacity: canContinue ? 1 : 0.5 }}
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSalesComponents;
