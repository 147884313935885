import React from "react";
import styles from "./loader.module.css";

function CircularLoader({ size = "3.25em" }) {
  return (
    <svg
      viewBox="25 25 50 50"
      className={styles.circular_loader}
      style={{ width: size }}
    >
      <circle
        r="20"
        cy="50"
        cx="50"
        className={styles.circular_loader_circle}
      ></circle>
    </svg>
  );
}

export default CircularLoader;
