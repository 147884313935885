import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import styles from "./trainingData.module.css";
import { useState, useContext } from "react";
import { ChatbotGuidelinesContext } from "../../../context/ChatbotGuidelinesContext";
import SearchBarHeader from "../../portal_components/universal_components/search_bar_header";
import EditTrainingDataPage from "./EditTrainingDataPage";
import { ChatbotTuningContext } from "../../../context/ChatbotTuningContext";
import { toast } from "react-toastify";
import { CreateTrainingDataPopup } from "../../screen_popups/CreateTrainingDataPopup/CreateTrainingDataPopup";
import destructureJsonData from "../../../utils/destructureJsonData";
import CircularLoader from "../../portal_components/universal_components/CircularLoader/CircularLoader";

import { useNavigate } from "react-router";

const TrainingDataPage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedTrainingData, setSelectedTrainingData] = useState(null);
  const { guidelines } = useContext(ChatbotGuidelinesContext);
  const [createPopup, setCreatePopup] = useState(false);

  const [loadingTrainingDataFile, setLoadingTrainingDataFile] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const {
    trainingData,
    createNewTrainingData,
    fetchTrainingDataFile,
    readUploadedTrainingDataFile,
    replaceTrainingData,
  } = useContext(ChatbotTuningContext);

  useEffect(() => {
    if (id) {
      setLoadingTrainingDataFile(true);
      fetchTrainingDataFile(id)
        .then((data) => {
          const trainingDataObject = {
            id: id,
            name: id,
            dataPoints: destructureJsonData(data).dataPoints || [],
            viewOnly: true,
          };
          setSelectedTrainingData(trainingDataObject);
          setLoadingTrainingDataFile(false);
        })
        .catch((error) => {
          console.error("Error fetching training data:", error);
          toast.error("Error fetching training data");
          setLoadingTrainingDataFile(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (selectedTrainingData) {
      console.log(selectedTrainingData);
      setIsEditing(true);
    }
  }, [selectedTrainingData]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredGuidelines, setFilteredGuidelines] = useState(guidelines);

  useEffect(() => {
    const filtered = guidelines.filter((guideline) =>
      guideline.intent.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredGuidelines(filtered);
  }, [searchTerm, guidelines]);

  function handleModalNav(selectedTrainingData) {
    setSelectedTrainingData(selectedTrainingData);
    console.log(selectedTrainingData);
    setIsEditing(!isEditing);
  }

  function back() {
    setIsEditing(!isEditing);
    setSelectedTrainingData(null);
    navigate(`/admin/training-data`);
  }

  const handleCreateNew = async (event) => {
    event.stopPropagation();
    setCreatePopup(true);
  };

  const createTrainingData = async (name, file) => {
    try {
      setLoadingPopup(true);
      if (file) {
        const trainingDataFile = await readUploadedTrainingDataFile(file);
        const formattedDataPoints =
          destructureJsonData(trainingDataFile).dataPoints;
        const newTrainingDataId = await createNewTrainingData(
          name,
          formattedDataPoints
        );

        setCreatePopup(false);

        const newTrainingData = trainingData.find(
          (td) => td.id === newTrainingDataId
        );

        if (newTrainingData) {
          setSelectedTrainingData(newTrainingData);
          setIsEditing(true);
        }
      } else {
        const newTrainingDataId = await createNewTrainingData(name);
        setCreatePopup(false);

        const newTrainingData = trainingData.find(
          (td) => td.id === newTrainingDataId
        );

        if (newTrainingData) {
          setSelectedTrainingData(newTrainingData);
          setIsEditing(true);
        }
      }
      setLoadingPopup(false);
    } catch (error) {
      toast.error("Error Creating Training Data");
      console.error(error);
    }
  };

  const jsonReplaceTrainingData = async (id, file) => {
    try {
      const trainingDataFile = await readUploadedTrainingDataFile(file);
      const destructuredData = destructureJsonData(trainingDataFile);

      if (!destructuredData || !destructuredData.dataPoints) {
        throw new Error("Failed to process training data file");
      }

      const formattedDataPoints = destructuredData.dataPoints;
      await replaceTrainingData(id, formattedDataPoints);

      return formattedDataPoints;
    } catch (error) {
      toast.error("Error Creating Training Data");
      console.error(error);
    }
  };

  //
  return (
    <div className={styles.main_layout}>
      {loadingTrainingDataFile ? (
        <div className={styles.loading_page}>
          <CircularLoader size="50px" color="rgba(0, 61, 130, 1)" />
        </div>
      ) : (
        <>
          {!isEditing ? (
            <p className={styles.page_title_margin}>Training Data</p>
          ) : (
            <div className={styles.header_section}></div>
          )}
          <div className={styles.edit_guidelinees_modal}>
            {!isEditing ? (
              <>
                <div className={styles.guideline_controls}>
                  <SearchBarHeader
                    type="EditChatbot"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                  <div
                    className={styles.guideline_add}
                    onClick={handleCreateNew}
                  >
                    <img
                      src="/add.png"
                      className={styles.add_guideline}
                      alt="add"
                    ></img>
                  </div>
                </div>
                {/* <div className={styles.section_divider}></div> */}

                <div className={styles.training_data_container}>
                  {trainingData.map((data, index) => {
                    const readyToTrain = data.dataPoints.length >= 10;
                    return (
                      <div className={styles.data_set} key={index}>
                        <div className={styles.data_set_top}>
                          <div className={styles.training_data_text}>
                            <p className={styles.training_data_title}>
                              {data.name}
                            </p>
                            <p className={styles.training_data_subtitle}>
                              {data.dataPoints.length} Data Points
                            </p>
                          </div>
                          {readyToTrain ? (
                            <div className={styles.top_right}>
                              <p className={styles.ready_text}>
                                Ready to Train
                              </p>
                              <CheckMarkIcon />
                            </div>
                          ) : (
                            <div className={styles.top_right}>
                              <p className={styles.not_ready_text}>
                                Validation Failed
                              </p>
                              <CrossIcon />
                            </div>
                          )}
                        </div>
                        <div className={styles.data_set_bottom}>
                          <p className={styles.training_data_last_updated}>
                            Last Updated At:{" "}
                            {formatTimestamp(data.last_updated)}
                          </p>
                          <div
                            className={styles.open_data_button}
                            onClick={() => handleModalNav(data)}
                          >
                            Open Data
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className={styles.spacer}></div>
                </div>
              </>
            ) : (
              <EditTrainingDataPage
                selectedTrainingData={selectedTrainingData}
                jsonReplaceTrainingData={jsonReplaceTrainingData}
                isCreating={isCreating}
                back={back}
              />
            )}
          </div>
          <CreateTrainingDataPopup
            openModal={createPopup}
            closeModal={() => setCreatePopup(false)}
            loading={loadingPopup}
            actionFunction={createTrainingData}
            desc={"Create New Training Data"}
          />
        </>
      )}
    </div>
  );
};

function formatTimestamp(timestamp) {
  try {
    const date = new Date(parseInt(timestamp, 10));

    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString(undefined, options);
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}

function CheckMarkIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 6L9 17L4 12"
        // stroke="currentColor"
        className={styles.check_svg}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CrossIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18M6 6l12 12"
        className={styles.red_cross_svg}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TrainingDataPage;
