import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Integrations from "../../components/portal_components/integrations_page/integrations";

const IntegrationsPage = () => {
  return (
    <>
      <Sidebar childComponent={<Integrations />} activeText="Integrations" />
    </>
  );
};

export default IntegrationsPage;
