import { useState, useContext, useRef, useEffect } from "react";
import React from "react";
import styles from "./chatbot_testing.module.css";
import { app } from "../../../services/firebase";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { ChatbotTestingContext } from "../../../context/ChatbotTestingContext";
import EmojiPicker from "emoji-picker-react";

export default function InboxMessageInput(props) {
  const { sendChatbotMessage, selectedChat, setTestConversations } = useContext(
    ChatbotTestingContext
  );

  const [userInput, setUserInput] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const canSendMessage = userInput !== "";

  const handleEmojiClick = (emojiData, event) => {
    setUserInput((prevInput) => prevInput + emojiData.emoji);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  const handleSendMessage = async () => {
    const trimmedInput = userInput.trim();

    if (!trimmedInput) {
      return;
    }

    setUserInput("");
    props.setLoadingMessage(true);

    try {
      const userMessageId = await sendChatbotMessage(
        selectedChat.id,
        trimmedInput,
        props.currentUser.uid
      );
      if (userMessageId) {
        // updateUserMessageId(userMessageId);
      } else {
        console.log("Error Saving User Message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      props.setLoadingMessage(false);
    }
  };

  // function updateUserMessageId(newUserMessageId) {
  //   setTestConversations((prevConversations) => {
  //     return prevConversations.map((conversation) => {
  //       if (conversation.id === selectedChat.id) {
  //         const updatedMessages = conversation.messages.map((msg) => {
  //           if (msg.id === "TEMPORARY_ID") {
  //             return { ...msg, id: newUserMessageId };
  //           }
  //           return msg;
  //         });
  //         return { ...conversation, messages: updatedMessages };
  //       }
  //       return conversation;
  //     });
  //   });
  // }

  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      await handleSendMessage();
    }
  };

  return (
    <div className={styles.message_input_container}>
      <textarea
        className={styles.message_input}
        type="text"
        placeholder="Type message..."
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        onKeyDown={handleKeyPress} // <-- Add this line
      />
      <div className={styles.message_input_controls}>
        <div className={styles.message_input_controls_left}>
          <div className={styles.emoji_icon_container}>
            <img
              className={styles.emoji_icon}
              src="/EmojiIcon.png"
              alt="emoji"
              onClick={toggleEmojiPicker}
            />
            {showEmojiPicker && (
              <div className={styles.emoji_picker_container}>
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.message_input_controls_right}>
          {/* <div className={styles.add_to_data_button}>
            Add to Training Data Set
          </div> */}
          {canSendMessage ? (
            <div
              className={styles.send_button_active}
              onClick={handleSendMessage}
            >
              Send
            </div>
          ) : (
            <div className={styles.send_button_inactive}>Send</div>
          )}
        </div>
      </div>
    </div>
  );
}
