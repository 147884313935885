export default function convertDataToJSONLFormat(data) {
  if (!data || typeof data !== "object") {
    return "";
  }

  const jsonDataArray = [];
  let currentSection = "";
  let sectionData = []; // Temporary storage for data within a section

  if (data.dataPoints && Array.isArray(data.dataPoints)) {
    data.dataPoints.forEach((dataPoint) => {
      if (dataPoint.section && dataPoint.section !== currentSection) {
        if (sectionData.length > 0) {
          // Add all data of the previous section as a single string
          jsonDataArray.push(sectionData.join("\n"));
          sectionData = []; // Reset for the new section
          jsonDataArray.push(""); // Add a newline between sections
        }

        jsonDataArray.push(`//Section: ${dataPoint.section}`);
        currentSection = dataPoint.section;
      }

      if (dataPoint.data_array && Array.isArray(dataPoint.data_array)) {
        const messages = dataPoint.data_array.map((message) => ({
          role: message.identity,
          content: message.content,
        }));
        // Append the JSON string to the current section data
        sectionData.push(JSON.stringify({ messages }));
      }
    });

    // Add the last section's data, if any
    if (sectionData.length > 0) {
      jsonDataArray.push(sectionData.join("\n"));
    }
  }

  if (jsonDataArray.length === 0) {
    return "";
  }

  // Join with two newlines to separate sections
  const jsonlData = jsonDataArray.join("\n");

  return jsonlData.trim(); // Trim to remove the last newline
}
