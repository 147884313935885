import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./pricing.module.css";

import Footer from "../footer/footer";
import CheckmarkIcon from "../../../assets/svg/CheckIcon";
import { pricing_options } from "../../../data/pricing_options";

import { useNavigate } from "react-router";

const PricingComponent = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

  return (
    <>
      <div className={styles.landing_page}>
        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Pricing</p>
            <p className={styles.page_subtitle}>
             <br></br> In the United States, there are <strong>220,000,000</strong> LinkedIn users,
            </p>
            <p className={styles.page_second_subtitle}>
              so finding $100,000+ AUM clients shouldn’t be difficult
            </p>
          </div>
        </div>
        {/* <PricingTiles pricingOptions={pricing_options} /> */}
        <div className={styles.calendly_form}>
          <div
            class="calendly-inline-widget"
            data-url="https://calendly.com/austin-replyassist/demo-call?hide_gdpr_banner=1&primary_color=003d82"
            style={{ minWidth: "320px", height: "700px" }}
          ></div>
        </div>
        <div className={styles.page_titles}>
            <br></br>
            <p className={styles.page_linkedinstat}>
              LinkedIn users have <strong>twice the buying power</strong> of the average online audience (<a href="https://blog.hootsuite.com/linkedin-demographics-for-business/#LinkedIn_income_demographicsm" target="_blank" rel="noopener noreferrer">Hootsuite</a>), and 32% of U.S. adults would make a purchase from LinkedIn (MarketingCharts)
              </p>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div className={styles.skeptical_section}>
        <p className={styles.page_title_sm}>
          <strong>Referral Program</strong>
        </p>
        <p className={styles.skeptical_text}>
          Earn <strong>$250</strong> for <strong>each</strong> referral you send us who signs up for ReplyAssist
          <br /> <br />
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </p>{" "}
      </div>
        <br></br>
        <br></br>
        <div className={styles.pricing_table}>
          <div
            className="elfsight-app-48d4917b-dc8c-48da-ba1a-a2c57ca28dad"
            data-elfsight-app-lazy
          ></div>
        </div>
      </div>
      <div className={styles.faq_list_items}>
        {faq_data.map((item, index) => (
          <div key={index}>
            <div
              className={styles.faq_tile}
              onClick={() =>
                setExpandedIndex(index === expandedIndex ? null : index)
              }
            >
              <p className={styles.item_question}>{item.question}</p>
              <img
                className={`${styles.down_arrow_image} ${
                  expandedIndex === index ? styles.flipped : ""
                }`}
                src="/images/down-arrow.png"
                alt="expand"
              ></img>
            </div>
            {expandedIndex === index && (
              <div className={styles.faqanswer}>{item.answer}</div>
            )}
          </div>
        ))}
      </div>
      <div className={styles.skeptical_section}>
        <br /> <br /> <br />
        <p className={styles.page_title_sm}>
          <strong>Five reasons why it’s costing you to not use ReplyAssist</strong>
        </p>
        <p className={styles.skeptical_text}>
          <br />
          <strong>1.</strong> You make money closing new clients. Not by how
          many new prospects you contact every week.
          <br /> <br />
          <strong>2.</strong> You take the time & spend the money to hire &
          train (upfront & ongoing costs) a human assistant to do your
          prospecting for you. But are they ever as good as you? We think you
          know the answer… <br /> <br />
          <strong>3.</strong> You aren’t meant to physically send thousands of
          messages on a phone/computer every month. Doing this takes a physical
          toll on your body long-term, and it’s also extremely boring work, too.{" "}
          <br /> <br />
          <strong>4.</strong> You’re a Financial Advisor, and yet you spend 50%
          of your time being a salesperson. Imagine how many more clients you
          could serve if you didn’t need to dedicate so much time to sales &
          marketing. <br /> <br />
          <strong>5.</strong> We only get one life, and it goes by fast. So
          would you rather spend hours prospecting each month or free up that
          amount of time to spend with family, friends, and enjoying hobbies?
          It’s all about opportunity cost.
        </p>{" "}
        <br /> <br /> <br /> <br /> <br />
      </div>
      <Footer />
    </>
  );
};

export const PricingTiles = ({ pricingOptions }) => {
  function handleButtonClick(option) {
    if (option.sign_up_redirect) {
      window.location.href = "https://auth.replyassist.com";
    } else {
      navigate("/contact-sales");
    }
  }

  function redirectToSignUp() {}

  //sign_up_redirect

  const navigate = useNavigate();

  return (
    <div className={styles.career_options}>
      {pricingOptions.map((option, index) => (
        <div
          key={index}
          className={
            option.emphasize
              ? styles.pricing_tile_emphasis
              : styles.pricing_tile
          }
        >
          <div className={styles.pricing_tile_top}>
            <p
              className={styles.career_title}
              style={option.emphasize ? { color: "#003d82" } : {}}
            >
              {option.title}
            </p>
            <p className={styles.monthly_price}>
              <span className={styles.money_sign}>$</span>
              {option.monthly_price}/mo
            </p>
          </div>
          <div className={styles.detail_item_box}>
            {option.details.map((item, index) => (
              <div className={styles.detail_item} key={index}>
                <p className={styles.detail_text}>{item}</p>
              </div>
            ))}

            {option.additional_details && (
              <>
                <p>+</p>
                {option.additional_details.map((item, index) => (
                  <div className={styles.detail_item} key={index}>
                    <p className={styles.detail_text}>{item}</p>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={styles.pricing_tile_bottom_layout}>
            <div
              className={styles.cta_button}
              onClick={() => handleButtonClick(option)}
            >
              Choose Plan
            </div>
            <p className={styles.referral}>
              Billed upfront. Cancel anytime.
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingComponent;

const faq_data = [
  {
    question:
      "Why not just save the money & do all of this prospecting work on my own?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: Well, do you value your time or your money more? You can always
          make more money, but you can’t get time back.
        </strong>
        <br />
        <br />
        Sure, you can save some money each month by not using ReplyAssist, but
        we don’t believe it’s worth your time or effort to do all of your
        prospecting work on your own. Responding to hundreds of LinkedIn Direct
        Messages and doing hundreds of follow up tasks in your CRM each month is
        time consuming, and ReplyAssist will save you roughly 40 hours per month
        by reducing these tasks for you (67% of your total prospecting work).
        For example, if you make $60/hour, that is $2,400 saved each month in
        terms of your time. Imagine what else you could do with 40 extra hours
        each month…
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "Are there any contracts with the ReplyAssist subscription?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: Nope.</strong>
        <br />
        <br />
        We do recommend that you use the tool for at least 90 days before
        quitting its use, though. Sales is never a walk in the park (it's never
        easy), so it’ll take some time to see the full benefits of using the
        ReplyAssist software.
      </p>
    ),
  },

  {
    question: "Can I cancel my plan?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: We’d love to keep you around as a customer, but we understand that
          ReplyAssist isn’t for everyone.
        </strong>
        <br />
        <br />
        <br />
        You can cancel your plan anytime with a 30-day notice of the plan
        renewal date.
        <br />
        <br />
      </p>
    ),
  },
];
