/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */

import React from "react";
import styles from "./popup.module.css";
export function ConfirmDeletePopup(props) {
  const { openModal, closeModal, actionFunction, desc } = props;

  if (!openModal) {
    return null;
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const modalStyle = getModalStyle();

  return (
    <div open={openModal} onClose={closeModal} className={styles.overlay}>
      <div style={modalStyle} className={styles.modalPaper}>
        <div className={styles.modal_header}></div>
        <div className={styles.modal_body}>
          <div className={styles.modal_icon}>
            <svg
              className={styles.cross_svg}
              enable-background="new 0 0 512 512"
              height="25px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 512 512"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="rgba(255, 0, 0, 0.75)">
                {/* Updated fill color */}
                <path d="M444.852,66.908h-99.339V47.04c0-21.943-17.792-39.736-39.736-39.736h-99.339 c-21.944,0-39.736,17.793-39.736,39.736v19.868H67.363v19.868h20.47l19.887,377.489c0,21.944,17.792,39.736,39.736,39.736h218.546 c21.944,0,39.736-17.792,39.736-39.736l19.538-377.489h19.577V66.908z M186.57,47.04c0-10.962,8.926-19.868,19.868-19.868h99.339 c10.962,0,19.868,8.906,19.868,19.868v19.868H186.57V47.04z M385.908,463.236l-0.039,0.505v0.524 c0,10.943-8.906,19.868-19.868,19.868H147.455c-10.942,0-19.868-8.925-19.868-19.868v-0.524l-0.019-0.523L107.72,86.776h297.669 L385.908,463.236z" />
                <rect height="317.885" width="19.868" x="246.173" y="126.511" />
                <polygon points="206.884,443.757 186.551,126.493 166.722,127.753 187.056,445.017" />
                <polygon points="345.649,127.132 325.82,125.891 305.777,443.776 325.606,445.017" />
              </g>
            </svg>
          </div>
          <p className={styles.popup_text}>{desc}</p>

          <div className={styles.popup_buttons}>
            <div
              className={styles.cancel_button}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </div>
            <div
              className={styles.delete_button}
              onClick={(event) => {
                actionFunction(event);
                closeModal();
              }}
            >
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
