import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import FollowUps from "../../components/portal_components/follow_ups_page/followups";

const FollowUpsPage = () => {
  return (
    <>
      <Sidebar childComponent={<FollowUps />} activeText="Follow Ups" />
    </>
  );
};

export default FollowUpsPage;
