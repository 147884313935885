import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./inbox.module.css";
import EmojiPicker from "emoji-picker-react";

// Assuming you have initialized your firebaseApp somewhere

// import twilio from "twilio";

export default function InboxMessageInput() {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [userInput, setUserInput] = useState("");
  const handleEmojiClick = (emojiData, event) => {
    setUserInput((prevInput) => prevInput + emojiData.emoji);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };
  return (
    <div className={styles.message_input_container}>
      <input
        className={styles.message_input}
        type="text"
        placeholder="Type message..."
      />
      <div className={styles.message_input_controls}>
        <div className={styles.message_input_controls_left}>
          <div className={styles.emoji_icon_container}>
            <img
              className={styles.emoji_icon}
              src="/EmojiIcon.png"
              alt="emoji"
              onClick={toggleEmojiPicker}
            />
            {showEmojiPicker && (
              <div className={styles.emoji_picker_container}>
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </div>
            )}
          </div>
        </div>
        <button className={styles.send_button_active}>Send</button>
      </div>
    </div>
  );
}
