import React from "react";
import { useContext, useState } from "react";
import styles from "./universal.module.css";
// import FilterMenu from "../universal_components/filter_menu";
import { AuthContext } from "../../../context/AuthContext";

function SearchBarHeader(props) {
  const { prospects, conversations, activeDealStages, inactiveDealStages } =
    useContext(AuthContext);
  const filterType = props.type;
  // State to determine whether the menu is open or not
  const [menuOpen, setMenuOpen] = useState(false);
  const dealStages = activeDealStages + inactiveDealStages;
  function handleFilterClick() {
    // Toggle the menu state when filter button is clicked
    setMenuOpen((prevState) => !prevState);
    console.log(conversations);
  }
  return (
    <div className={styles.search_layout}>
      <div className={styles.search_bar}>
        <img
          className={styles.search_icon}
          src="/images/GeneralIcons/SearchIcon.png"
          alt="search"
        ></img>
        <div className={styles.search_divider}></div>
        <input
          placeholder="Search"
          className={styles.search_input}
          value={props.searchTerm}
          onChange={(e) => props.setSearchTerm(e.target.value)}
        ></input>
      </div>
      {filterType !== "EditChatbot" && (
        <div className={styles.search_filter} onClick={handleFilterClick}>
          <img
            className={styles.search_filter_icon}
            src="/images/GeneralIcons/FilterIcon.png"
            alt="filter"
          ></img>
        </div>
      )}
      {/* Conditionally render the filter menu */}
      {menuOpen && filterType === "Prospects" && (
        <div className={styles.filter_menu}>
          {/* Your filter menu content here */}
          {/* Example: */}
          <div className={styles.filter_section}>
            {/* <p className={styles.filter_section_title}>Sort By</p>
            <div className={styles.sort_divider}></div> */}
            <div className={styles.sort_option}>
              <p>Sort A-Z</p>
              <div> </div>
            </div>
            <div className={styles.sort_divider}></div>
            <div className={styles.sort_option}>
              <p>Sort Follow Up's</p>
              <div> </div>
            </div>
            <div className={styles.sort_divider}></div>
            <div></div>
          </div>
          <div className={styles.filter_menu_content}>
            <div className={styles.filter_section}>
              <p className={styles.filter_section_title}>Deal Stage</p>
              <div className={styles.deal_stages_container}>
                {activeDealStages.map((deal, index) => (
                  <div key={index} className={styles.deal_stages_item}>
                    <p className={styles.deal_item_text}>{deal}</p>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <p className={styles.filter_section_title}>Interest Level</p>
              <div>
                <div className={styles.filter_interest_item}>
                  <div className={styles.filter_interest_checkbox}></div>
                  <p className={styles.filter_interest_text}>High Interest</p>
                </div>
                <div className={styles.filter_interest_item}>
                  <div className={styles.filter_interest_checkbox}></div>
                  <p className={styles.filter_interest_text}>Medium Interest</p>
                </div>
                <div className={styles.filter_interest_item}>
                  <div className={styles.filter_interest_checkbox}></div>
                  <p className={styles.filter_interest_text}>Low Interest</p>
                </div>
                <div className={styles.filter_interest_item}>
                  <div className={styles.filter_interest_checkbox}></div>
                  <p className={styles.filter_interest_text}>Neutral</p>
                </div>
                <div className={styles.filter_interest_item}>
                  <div className={styles.filter_interest_checkbox}></div>
                  <p className={styles.filter_interest_text}>Not Interested</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchBarHeader;
