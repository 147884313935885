export default function destructureJsonData(jsonlData) {
  if (!jsonlData || typeof jsonlData !== "string") {
    return {};
  }

  const currentTime = Date.now();

  let currentSection = "Unknown Section";
  const jsonDataArray = jsonlData
    .split("\n")
    .map((line) => {
      if (line.startsWith("//Section:")) {
        currentSection = line.split("//Section:")[1].trim();
        return null;
      }

      try {
        return { section: currentSection, json: JSON.parse(line) };
      } catch (error) {
        console.error("Error parsing JSON string:", error);
        return null;
      }
    })
    .filter((item) => item !== null);

  const dataPoints = jsonDataArray.map((item, index) => ({
    id: index,
    section: item.section,
    created_at: new Date(currentTime - index).toISOString(),
    data_array: item.json.messages.map((message) => ({
      identity: message.role,
      content: message.content,
    })),
  }));

  return {
    dataPoints: dataPoints,
  };
}
