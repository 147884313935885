import { useMediaQuery } from "@material-ui/core";
export default function DemoVideo() {
    const isDesktop = useMediaQuery("(min-width: 1024px)");
    const videoWidth = isDesktop ? "48%" : "90%"
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(47.013888888888886% + 41px)",
        height: 0,
        width: videoWidth,
      }}
    >
      <iframe
        src="https://demo.arcade.software/x9CfhJ7SHfwEC5k7VeX0?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
        title="ReplyAssist 8-Minute Demo"
        frameBorder="0"
        loading="lazy"
        allowFullScreen
        allow="clipboard-write"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          colorScheme: "light",
        }}
      />
    </div>
  );
}
