import React from "react";
import styles from "./inbox.module.css";

const LineDivider = () => {
  return <div className={styles.lineDivider}></div>;
};

const InboxSideProfile = (props) => {
  return (
    <div className={styles.side_profile_component}>
      <img
        className={styles.side_profile_image}
        src={props.selectedChat.prospect_image}
        alt="profile"
      ></img>
      <div className={styles.side_profile_top_info}>
        <div className={styles.side_profile_top_left_info}>
          <p className={styles.side_profile_name}>John Smith</p>

          <p className={styles.side_profile_email}>
            Email: johnsmith@gmail.com
          </p>
          <p className={styles.side_profile_phone}>Phone: (773)-272-6162</p>
        </div>
        <div className={styles.side_profile_top_right_info}>
          <div className={styles.side_profile_tag_box}>
            <p className={styles.side_profile_tag}>High Interest</p>
          </div>
        </div>
      </div>
      <div className={styles.linkedin_profile_button}>
        <p className={styles.linkedin_profile_text}>Linkedin Profile</p>
      </div>
      <LineDivider />
      <div className={styles.side_profile_section}>
        <div className={styles.side_profile_section_header}>
          <p className={styles.side_profile_section_title}>Next Follow Up</p>
          <p className={styles.side_profile_section_edit}>Edit</p>
        </div>
        <div className={styles.side_profile_section_content}>
          <p className={styles.side_profile_section_text}>
            July 22nd, 3:00 PM CT
          </p>
        </div>
      </div>
      <LineDivider />
      <div className={styles.side_profile_section}>
        <div className={styles.side_profile_section_header}>
          <p className={styles.side_profile_section_title}>Meeting History</p>
          <p className={styles.side_profile_section_edit}>Edit</p>
        </div>
        <div className={styles.side_profile_section_content}>
          <p className={styles.side_profile_section_text}>
            July 22nd, 3:00 PM CT
          </p>
        </div>
      </div>
      <LineDivider />
      <div className={styles.side_profile_section}>
        <div className={styles.side_profile_section_header}>
          <p className={styles.side_profile_section_title}>Deal Stage</p>
          <p className={styles.side_profile_section_edit}>Edit</p>
        </div>
        <div className={styles.side_profile_section_content}>
          <p className={styles.side_profile_section_text}>
            Closing Call Scheduled
          </p>
        </div>
      </div>
      <LineDivider />
      <div className={styles.side_profile_notes_section}>
        <div className={styles.side_profile_section_header}>
          <p className={styles.side_profile_section_title}>Notes</p>
          <p className={styles.side_profile_section_edit}></p>
        </div>
        <div className={styles.side_profile_notes_section_content}>
          <p className={styles.side_profile_section_notes}>Notes</p>
        </div>
      </div>
      <div className={styles.bottomlineDivider}></div>
    </div>
  );
};

export default InboxSideProfile;
