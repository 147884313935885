import styles from "./landingnavbar.module.css";
import { useNavigate, useLocation } from "react-router";

import { useState } from "react";

import NavbarMenu from "./NavbarMenu";

import { useMediaQuery } from "@material-ui/core";

import HamburgerIcon from "../../assets/svg/HamburgerIcon";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Pricing", path: "/pricing" },
    { name: "FAQ", path: "/faq" },
    { name: "Resources", path: "/resources" },
    // { name: "Careers", path: "/careers" },
  ];

  const navigateTo = (path) => {
    navigate(path);
  };

  function handleLogin() {
    navigate("/partner-login");
  }

  function handleJoinWaitlist() {
    navigate("/join-newsletter");
  }

  function handleScheduleDemo() {
    window.open("https://calendly.com/austin-replyassist/demo-call", "_blank");
}


  function goHome() {
    navigate("/");
  }

  const navigateToExternal = (url) => {
    window.location.href = url;
  };

  function handleSignIn() {
    window.location.href = "https://dashboard.replyassist.com/auth/login";
  }

  return (
    <div className={styles.landing_navbar}>
      <div className={styles.menu_items}>
        <div className={styles.logo_box} onClick={goHome}>
          <img
            src="/images/ReplyAssist-New-Logo-Transparent.png"
            alt="logo"
            className={styles.logo}
          ></img>
        </div>
        <div className={styles.menu_options}>
          {navItems.map((item) => (
            <p
              key={item.path}
              className={`${styles.menu_item} ${
                location.pathname === item.path ? styles.menu_item_active : ""
              }`}
              onClick={() => navigateTo(item.path)}
            >
              {item.name}
            </p>
          ))}
        </div>
      </div>
      <div className={styles.button_options}>
        <div className={styles.login_button} onClick={handleSignIn}>
          <p>Login</p>
        </div>
        <div className={styles.action_button} onClick={handleScheduleDemo}>
          <p>Schedule Demo Call</p>
        </div>
      </div>
    </div>
  );
};

const MobileNavbar = ({ toggleMenu }) => {
  const navigate = useNavigate();

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Pricing", path: "/pricing" },
    { name: "FAQ", path: "/faq" },
    // { name: "Careers", path: "/careers" },
  ];

  const navigateTo = (path) => {
    navigate(path);
  };

  function handleLogin() {
    navigate("/partner-login");
  }

  function goHome() {
    navigate("/");
  }

  return (
    <div className={styles.landing_navbar}>
      <div className={styles.menu_items}>
        <div className={styles.logo_box} onClick={goHome}>
          <img
            src="/images/ReplyAssist-New-Logo-Transparent.png"
            alt="logo"
            className={styles.mobile_logo}
          ></img>
        </div>
        <div className={styles.menu_options}></div>
      </div>
      <HamburgerIcon onClick={toggleMenu} />
    </div>
  );
};

export default function LandingNavbar(props) {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const openUrlInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const finTechMap = "https://www.kitces.com/fintechmap";
  const advisorTechDirectory =
    "https://fintech.kitces.com/details/business-development/prospecting/replyassist";

  const bannerText = isDesktop
    ? styles.header_banner
    : styles.header_banner_mobile;

  return (
    <>
      <div className={bannerText}>
        Recently listed on the Kitces.com&nbsp;
        <span
          className={styles.link_text}
          onClick={() => openUrlInNewTab(finTechMap)}
        >
          <strong>FinTech Map</strong>
        </span>
        &nbsp;+&nbsp;
        <span
          className={styles.link_text}
          onClick={() => openUrlInNewTab(advisorTechDirectory)}
        >
          <strong>AdvisorTech Directory</strong>
        </span>
      </div>
      {isDesktop ? <Navbar /> : <MobileNavbar toggleMenu={toggleMenu} />}
      {isMenuOpen ? (
        <NavbarMenu />
      ) : (
        <div className={styles.landing_page_layout}>{props.childComponent}</div>
      )}
    </>
  );
}

//NavbarMenu
