import styles from "./agreements.module.css";

const legal_agreements = [
  {
    question: "Privacy Policy",
    answer: (
      <p className={styles.answer_text}>
        <strong>Privacy Policy Effective Date:</strong> February 2024 <br />
        <br />
        <strong>Contents</strong>
        <br />
        1. Scope
        <br />
        2. Personal Information We Collect
        <br />
        3. Why We Collect Your Personal Information
        <br />
        4. Aggregated and De-Identified Information
        <br />
        5. Sharing Personal Information
        <br />
        6. Security of Personal Information
        <br />
        7. International Transfers of Personal Information
        <br />
        8. Accessing or Updating Your Personal Information
        <br />
        9. Retention of Personal Information
        <br />
        10. Children
        <br />
        11. Special Notice for Individuals in certain States in the US
        <br />
        12. Special Notice for Individuals in the EEA, UK and Switzerland
        <br />
        13. Updates
        <br />
        14. How to Contact Us
        <br />
        <br />
        <strong>1. Scope</strong>
        <br />
        ReplyAssist, Inc. and its affiliate companies (“ReplyAssist,” “we,”
        “us,” or “our”) are committed to protecting your privacy. This Privacy
        Policy informs you how we collect, use, secure and share your personal
        information collected by us when you visit our Websites (“Website”), use
        the products or services offered by ReplyAssist (“Services”), visit our
        branded social media pages, visit our offices, receive communication
        from us, register or attend our events or webinars, or ask questions
        about our products (“Interactions”, and together with Website and
        Services, the “Offerings”).
        <br />
        <br />
        Please note that our Website may include links to other organizations’
        websites whose privacy practices may differ from those of ReplyAssist.
        If you interact with any of those sites, your information is governed by
        their privacy notices. We encourage you to carefully read the privacy
        notices of any website you visit.
        <br />
        <br />
        If you are providing personal information to us as part of the
        recruitment process for employment, please review our Employment
        Candidate Privacy Policy.
        <br />
        <br />
        If you do not feel comfortable with any part of this Privacy Policy,
        please immediately cease using our Offerings as, by doing so, you
        consent to the practices set forth in this Privacy Policy, the Website
        Terms of Use and any Services Terms of Use.
        <br />
        <br />
        <strong>2. Personal Information We Collect</strong>
        <br />
        Except as described in this Privacy Policy, ReplyAssist will not give,
        sell, rent, or loan to any third party any of the personal information
        you provide us. Personal information is generally any information that
        identifies you or makes you identifiable such as your name, email
        address, telephone number, photo, current company and position, work
        history, IP address, or user preferences.
        <br />
        <br />
        We may collect, store, and use the following kinds of personal
        information from you in relation to the Offerings:
        <br />
        <br />
        <strong>a. Information You Voluntarily Provide.</strong> We will collect
        personal information when you voluntarily provide it to us (including to
        our service providers or other parties who collect it on our behalf).
        For example, we collect personal information when you request
        information about our services or products, submit an application for
        employment, subscribe to marketing communications, request customer
        support, register for a ReplyAssist event, create a ReplyAssist account,
        or use a ReplyAssist product or service. This information may include,
        without limitation, your name, email address, gender identification,
        birthday, and mailing address. By providing such information, you
        consent to its use as provided in this Policy.
        <br />
        <br />
        <strong>b. Information About Transactions.</strong> We collect
        information about any transactions carried out between you and us on or
        in relation to the Website, including information relating to any
        purchases you make of our services, including your first and last name,
        title, position, employer, and contact information.
        <br />
        <br />
        <strong>c. Information From Offline Sources.</strong> We may also
        collect personal information from you offline, such as from an email or
        during phone calls with sales representatives. The personal information
        we collect may include contact information (such as your name, address,
        telephone number or email address), professional information (such as
        your job title, department or job role), and contact preferences.
        <br />
        <br />
        <strong>d. Information We Automatically Collect.</strong>
        <br />
        (i) <strong>Log Data.</strong> When you use our Website or the Services,
        we may automatically record information from your device, its software,
        and your activity using the Website This may include such information as
        the device’s Internet Protocol (“IP”) address, browser type, operating
        system, software version, user settings, Media Access Control (“MAC”)
        address, the web page visited before you came to our Website,
        information you search for on our Website, length of visit and number of
        page views, locale preferences, identification numbers associated with
        your devices, your mobile carrier, date and time stamps associated with
        transactions, and system configuration information. Information we
        collect may be associated with accounts and other devices. Your browsing
        activity may be tracked across different websites and different devices
        or apps. For example, we may attempt to match your browsing activity on
        your mobile device with your browsing activity on your laptop. To do
        this our technology partners may share data, such as your browsing
        patterns, geo-location and device identifiers, and will match the
        information of the browser and devices that appear to be used by the
        same person.
        <br />
        <br />
        (ii) <strong>Location Data.</strong> We may collect geolocation and
        proximity of your device if location services are enabled on your device
        (e.g., GPS-based functionality on mobile devices used to access our
        Website) and may use that information to customize the interactions with
        location-based information and features. If you access our Website
        through a mobile device and you do not want your device to provide us
        with location-tracking information, you can disable the GPS or other
        location-tracking functions on your device, provided your device allows
        you to do this.
        <br />
        <br />
        (iii) <strong>Cookies.</strong> We use “cookies” to collect information
        and improve our interactions with you. A cookie is a small data file
        that we transfer to your device. We may use “persistent cookies” to save
        your registration ID and login password for future logins to our
        Website. We may use “session ID cookies” to enable certain features of
        the Website, to better understand how your interactions with us and to
        monitor aggregate usage and web traffic routing on our Website. For more
        information on how we use Cookies and similar technologies, please see
        our Cookie Policy,
        <br />
        <br />
        (iv) <strong>Services.</strong> We create app, services, features, APIs,
        software, websites and similar related offerings that enable individuals
        and businesses to interact with their customers. Those individuals and
        businesses (“data controllers”) may submit personal information about
        their customers and prospective customers to us. This could include the
        contents of, and information contained in, conversations in third party
        platforms, websites and applications, specifically LinkedIn®. We access,
        use, and process this personal data, including all contents of your
        LinkedIn® messaging, to provide our Services in accordance with the
        terms applicable to the Services. We also collect, process, and use data
        provided in the course of using the Services including logs, usage
        reports, services used, date and time stamps, and telephone numbers.
        <br />
        <br />
        (v) <strong>Analytics Tracking - Third Parties.</strong> We may also use
        third-party service providers to collect and process analytics and other
        information on our Website and Services. These third-party service
        providers may use cookies and other technologies to collect and store
        analytics and other information. They have their own privacy policies
        addressing how they use the analytics and other information and we do
        not have access to, nor control over, third parties’ use of cookies or
        other tracking technologies.
        <br />
        <br />
        We may also collect personal information from other third-party sources.
        We may collect personal information disclosed by you on message boards,
        chat features, blogs and other services or platforms to which you are
        able to post information and materials (including third-party services
        and platforms). We process such information to better understand you, to
        maintain and improve the accuracy of the information we store about you,
        and to better promote or optimize our communications with you, including
        providing information about our services and products.
        <br />
        <br />
        If you choose to limit our collection or use of your personal
        information or choose not to provide certain personal information, some
        or all of our services, programs, or events may not be available to you,
        and we may not be able to respond to your requests.
        <br />
        <br />
        <strong>
          3. Why We Collect and How we Use Your Personal Information
        </strong>
        <br />
        In general, we collect and use personal information to create, develop,
        operate, deliver, and improve our Website and Services. There is a legal
        basis for all collection and use which may include: consent, or our
        legitimate business interest such as monitoring, proper administration,
        improving our Website and Services; communicating with use; protection
        of legal rights; or, protection of vital interests. We may use your
        personal information to:
        <br />
        <br />
        <strong>a. Respond to Requests.</strong> We may process your personal
        information when you contact us, such as with questions, concerns, and
        feedback. We share this information with third parties upon your
        request, or with our service providers or partners to the extent
        necessary to provide you a response.
        <br />
        <br />
        <strong>b. Facilitate and Evaluate Use of the Services.</strong> We may
        use your personal information to provide you with our Website and
        Services, to facilitate your use of our Services (such as facilitating
        navigation and the login process, preserving information between
        sessions and enhancing security), to improve quality, to evaluate page
        response rates and determine content.
        <br />
        <br />
        <strong>c. Improve the Accuracy of our Records.</strong> We may use the
        personal information we receive from you or third parties to better
        understand you and/or maintain and improve the accuracy of the records
        we hold about you.
        <br />
        <br />
        <strong>d. Personalize your Experience.</strong> We also may use cookies
        and similar tracking technology to personalize your experience – please
        see our Cookie Policy for more information. By personalizing our
        communications, we enable you to more easily use our Website or Services
        by tracking your preferences (e.g., your language selection, your time
        zone, etc.).
        <br />
        <br />
        <strong>
          e. Ensure the Security of the Website and Interactions.
        </strong>{" "}
        We may process your personal information to help monitor, prevent and
        detect fraud, enhance security, and combat spam, malware, malicious
        activities or security risks; improve and enforce our security measures;
        and to monitor the security of our Offerings.
        <br />
        <br />
        <strong>f. Marketing.</strong> We may use information we obtain from
        you, your interactions with ReplyAssist, our Website, our Services, and
        from third party sources to provide you with marketing and promotional
        communications, to deliver targeted and relevant advertising and
        marketing to you, to determine the effectiveness of our marketing and
        promotional campaigns, to better understand you and your preferences,
        and to position and promote our services and products. Our marketing
        will be conducted in accordance with your advertising/marketing
        preferences and as permitted by applicable law.
        <br />
        <br />
        <strong>g. Product Improvement and Development.</strong> We use your
        information to help us improve the content and functionality of the
        Website and Services, to better understand our users, to improve the
        Website and Services, and develop to new products and services. This
        includes monitoring outages and troubleshooting issues reported by you.
        <br />
        <br />
        <strong>h. Create Your Profile or Account.</strong> We may process
        account data including name and email address to create your personal
        profile or account, operate the Website, provide the Services,
        monitoring your use, ensure the security of the Offerings, maintain
        backups, and communicate with you.
        <br />
        <br />
        <strong>i. Compliance with Law.</strong> We may process or use your
        personal data where necessary for the establishment, exercise, or
        defense of legal claims, whether in court proceedings or in an
        administrative or out-of-court procedure.
        <br />
        <br />
        <strong>j. Other Purposes.</strong> In addition to the other uses
        outlined in this Policy, we may process and use personal information
        about you for the purposes of obtaining or maintaining insurance
        coverage, managing risks, or where necessary for compliance with legal
        obligations, in order to protect your vital interests or the vital
        interests of another natural person, or to help improve the safety and
        reliability of our Services including detecting, preventing, responding
        to fraud, abuse, security risks, and technical issues.
        <br />
        <br />
        If you choose to limit our collection or use of your personal
        information, some or all of our services may not be available to you,
        and we may not be able to respond to certain of your requests.
        <br />
        <br />
        <strong>4. Aggregated and De-Identified Information</strong>
        <br />
        We may use de-identified and/or aggregated information that can no
        longer be reasonably linked to you or your device from the information
        we collect. De-identified and/or aggregated information is not subject
        to this Privacy Policy, and we may use and disclose such information in
        a number of ways, including research, internal analysis, analytics, and
        any other legally permissible purposes.
        <br />
        <br />
        <strong>5. Sharing Personal Information</strong>
        <br />
        There are certain circumstances where we may share your personal
        information with third parties without further notice to you. If you are
        located in a jurisdiction where sharing requires your permission, we
        will only do so with our consent. The circumstances where we may share
        personal information are as follows:
        <br />
        <br />
        <strong>a. Service Providers, Business Partners and Others.</strong> We
        may share your personal information with third parties, such as vendors,
        consultants, agents and other service providers who work on our behalf.
        Examples include vendors and service providers who provide assistance
        with marketing, billing, processing credit card payments, data analysis
        and insight, technical support and customer service, or who provide the
        hosting or technology that underlies the services. These examples are
        not exhaustive, and we may employ service providers for additional
        functions.
        <br />
        <br />
        <strong>
          b. Compliance with Laws and Law Enforcement Requests; Protection of
          Our Rights.
        </strong>{" "}
        We may disclose your personal information to third parties when we have
        a good faith belief that disclosure is reasonably necessary to: (i)
        comply with a law, regulation, legal process or legal requests including
        to meet national security or law enforcement requirements; (ii) protect
        the safety of any person from death or serious bodily injury; (iii)
        prevent harm or illegal activities including fraud or abuse of
        ReplyAssist or its users; (iv) enforce or protect ReplyAssist’s rights
        including those under this policy and our agreements with you and
        protect against legal liability; (v) to protect the security or
        integrity of our Offerings.
        <br />
        <br />
        <strong>c. Business Transfers.</strong> If we are involved in a merger,
        acquisition, or sale of all or a portion of our assets, your information
        may be sold or transferred as part of that transaction.
        <br />
        <br />
        <strong>d. Related Companies.</strong> We may share your personal
        information with our affiliates or subsidiaries, if any, so that they
        can help provide or support our Offerings, service your account, or
        troubleshoot concerns, contact you with offers, or support other
        legitimate business operations.
        <br />
        <br />
        <strong>6. Security of Personal Information</strong>
        <br />
        We will take reasonable physical, administrative and technical steps to
        safeguard the information we collect from you to prevent the loss,
        misuse or alteration of your personal information. While we make
        reasonable efforts to help ensure the integrity and security of our
        network and systems, we cannot guarantee our security measures. Further,
        data transmission of the internet is inherently insecure. Therefore, you
        should take special care in deciding what information you send to over
        the internet. We will store all the personal information you provide on
        our secure servers. You are responsible for keeping your passwords
        confidential. We will not ask you for your passwords. We have procedures
        in place to ensure we can react to any reasonably foreseeable issue. If
        you ever find a security issue, or simply have a security related
        concern, please reach out directly to us. The quickest and most
        effective way is by sending an email to info@replyassist.com.
        <br />
        <br />
        <strong>7. International Transfers of Personal Information</strong>
        <br />
        By submitting your personal information via this website or otherwise,
        your information will be transferred to us in the United States. The
        United States may have data protection laws that are less protective
        than the laws of the jurisdiction in which you reside. If you do not
        want your personal information transferred to or processed or maintained
        in the United States, you should not use our Website or should not
        engage in Interactions with us. If you are a citizen of or reside in the
        European Economic Area (“EEA”), the United Kingdom (“UK”), or
        Switzerland, we comply with applicable laws to provide an adequate level
        of data protection for the transfer of your personal information to
        third countries. For more information, see Section 11 (Special Notice
        for Individuals in the EEA, UK, and Switzerland).
        <br />
        <br />
        <strong>8. Accessing or Updating Your Personal Information</strong>
        <br />
        We depend on you to update and correct personal information to the
        extent necessary for the purposes for which the information was
        collected or subsequently authorized by you. You may contact ReplyAssist
        as indicated below to request that we update or correct relevant
        personal information that is demonstrated to be inaccurate or
        incomplete, except where the burden or expense of providing access would
        be disproportionate to the risks to the privacy of the individual in the
        case in question or where the rights of persons other than the
        individual would be violated. We may seek to verify your identity when
        we receive your privacy rights request to ensure the security of your
        personal information.
        <br />
        <br />
        <strong>9. Retention</strong>
        <br />
        We retain your personal information for as long as is necessary to
        fulfill the purposes for which it was collected, including for the
        purposes of satisfying any legal, regulatory, accounting, or reporting
        requirements, or to resolve disputes. Consistent with these
        requirements, we will look to delete your personal information within
        the time limits imposed by applicable law, if any, upon request.
        <br />
        <br />
        <strong>10. Children</strong>
        <br />
        Our Website and Interactions are not directed to persons under 18, and
        we do not knowingly collect personal information from children under 18.
        If a parent or guardian becomes aware their child has provided us with
        personal information without their consent, they should contact us at
        info@replyassist.com. If we become aware that a child under 18 has
        provided us with personal information, we will take steps to delete such
        information from our files.
        <br />
        <br />
        <strong>
          11. Special Notice for Individuals in certain States within the US
        </strong>
        <br />
        This section supplements the other information included in this Privacy
        Policy and applies solely to individuals who reside in the United States
        and who qualify as a “consumer” as such term is defined by the state law
        applicable to such individual.
        <br />
        <br />
        <strong>a. California Residents</strong>
        <br />
        This section applies only to California residents and the terms used
        have the same meaning as set out in the California Consumer Privacy Act
        of 2018 (“CCPA”) as amended by the California Privacy Rights Act 2020
        (“CPRA”).
        <br />
        <br />
        <strong>Personal Information Collected.</strong> Below is a summary of
        the personal information categories that we collect and, where relevant,
        categories of information we have collected in the last 12 months, the
        reason we collect your personal information, where we obtain the
        personal information we collect about you, and the categories of third
        parties with whom we share your personal information. For more
        information about the personal information we collect, please refer to
        Section 2 (Personal Information We Collect).
        <br />
        <br />
        <strong>Category Collected in last 12 months?</strong>
        <br />
        <strong>
          Business or commercial purpose for collection &amp; Disclosure
        </strong>
        <br />
        <strong>
          Categories of third parties to whom the information may be disclosed
        </strong>
        <br />
        <br />
        <strong>Identifiers</strong> No
        <br />
        We may collect identifiers such as a name, address, unique personal
        identifiers, email, phone number, your device’s IP address, software and
        identification numbers associated with your devices, and other similar
        identifiers.
        <br />
        Cloud storage service providers
        <br />
        Payment processors
        <br />
        Web analytics service providers
        <br />
        Web hosting service providers
        <br />
        Email distribution service providers
        <br />
        Financial and accounting service providers
        <br />
        Cybersecurity service providers
        <br />
        Customer relationship management tool providers
        <br />
        <br />
        <strong>Commercial Information</strong> No
        <br />
        We may collect commercial information such as records of products or
        services purchased, obtained, or considered by you.
        <br />
        <br />
        <strong>Internet or Electronic Network Activity Information</strong> No
        <br />
        We may collect information regarding your browsing history, search
        history, your interaction with our services, your interaction with an
        internet website, the web page visited before you came to our Website,
        length of visit and number of page views, locale preferences, your
        mobile carrier, date and time stamps associated with transactions, and
        system configuration information.
        <br />
        <br />
        <strong>Geolocation Data</strong> No
        <br />
        We may collect information that is sufficient to identify your general
        location, such as your IP address. For the avoidance of doubt, this does
        not currently include precise geolocation data.
        <br />
        <br />
        <strong>
          Audio, electronic, visual, thermal, olfactory, or similar information.
        </strong>{" "}
        No
        <br />
        We may collect audio recordings when you call us or participate in a
        call with us and audio/video recordings when you attend a meeting with
        us or when you attend one of our sponsored events.
        <br />
        <br />
        <strong>Professional or employment-related information.</strong> No
        <br />
        We may collect professional or employment-related information or
        education information, such as your job title, professional
        affiliations, and employment history, but only to the extent voluntarily
        provided by you or made available to us by trusted third parties.
        <br />
        <br />
        <strong>Educational information.</strong> No
        <br />
        We may collect education information, for recruiting and human resources
        or to provide the Services. Third Party HR management tools.
        <br />
        <br />
        <strong>Sensitive Personal Information</strong> No
        <br />
        When you log into or use our services or any web page hosted by us, we
        may collect account log-in data in combination with a security or access
        code, password, or credentials to authenticate and enable access to your
        accounts. We collect such information directly from you through your
        interactions with our services or web pages and, in some cases, from
        third-party user identification or authentication services that you
        utilize. We do not sell or share (as such terms are defined by the CCPA)
        this information.
        <br />
        <br />
        We collect the above information for the purposes described in Section 3
        (Why We Collect Your Personal Information) as well as any other purposes
        separately notified to you. Further, we share the personal information
        described above with the parties identified in Section 4 (Information
        Sharing).
        <br />
        <br />
        <strong>California Residents Rights.</strong> California residents have
        the following rights (“Rights”) listed below. Certain rights are not
        absolute, such as your right to know and right to deletion and are
        subject to certain exceptions. For instance, we cannot disclose specific
        pieces of personal information if the disclosure would create a
        substantial, articulable, and unreasonable risk to the security of the
        personal information, your account with us, or the security of the
        business’s systems of networks.
        <br />
        <br />• <strong>Right to Know.</strong> You have the right to know what
        personal information about you we have collected, used, disclosed, sold,
        and shared, if applicable, during the preceding 12 months. You have the
        right to request in writing from us a copy of the categories of personal
        information we have collected about you, the categories of sources from
        which we collected that information, why we collected information about
        you, and the business or commercial purpose for selling, sharing, or
        disclosing your personal information (if applicable), the categories of
        third parties with whom we disclosed, sold, or shared your personal
        information, and the categories of personal information that we
        disclosed, sold, or shared about you for a business purpose. We are only
        required to respond twice per calendar year to your right-to- know
        requests.
        <br />
        <br />• <strong>Right to Deletion.</strong> You have the right to
        request that we delete any personal information we have collected from
        you or maintain about you. We honor such requests unless an exception
        applies, such as when the information is necessary to complete the
        transaction or contract for which it was collected or when information
        is being used to detect, prevent, or investigate security incidents,
        comply with laws or legal obligation, identify and repair bugs, or
        ensure another consumer’s ability to exercise their free speech rights
        or other rights provided by law.
        <br />
        <br />•{" "}
        <strong>
          Right to Opt-Out of the Sale of Your Personal Information.
        </strong>{" "}
        If a business sells your personal information, you have the right to
        opt-out. We will not sell any of your personal information unless we
        first notify you separately in writing. You may opt out of the sale of
        your personal information by utilizing the “do not sell or share my
        personal information” banner on our Website.
        <br />
        <br />• <strong>
          Right to Information on Personal Data Sharing.
        </strong>{" "}
        You may have the right to request information from us regarding whether
        we share certain categories of your personal information with third
        parties for the third parties’ direct marketing purposes, including the
        categories of information we disclosed to third parties for the third
        parties’ direct marketing purposes during the preceding calendar year;
        the names and addresses of third parties that received such information,
        or if the nature of their business cannot be determined from the name,
        then examples of the products or services marketed. To the extent we
        participate in such sharing, you are entitled to receive a copy of this
        information in a standardized format and the information will not be
        specific to you individually.
        <br />
        <br />•{" "}
        <strong>
          Right to Opt-Out of the Sharing of Your Personal Information.
        </strong>{" "}
        You have the right to opt out of a business sharing your personal
        information with third parties. We may engage in “sharing” under the
        CCPA, which is broadly defined as disclosing personal information for
        purposes of cross-context behavioral advertising, including instances
        where companies target advertising based on personal information
        obtained from a consumer’s activity across distinctly branded websites
        or services. We share information as outlined in our Cookie Policy and
        Section 5 (Information Sharing). You may opt out of the “sharing” of
        your personal information by utilizing the “do not sell or share my
        personal information” banner on our Website.
        <br />
        <br />•{" "}
        <strong>Right to Correct Inaccurate Personal Information.</strong> You
        have the right to request that we correct inaccurate personal
        information. We will use commercially reasonable efforts to correct your
        information as directed by you or provide you with instructions on how
        you can correct your information.
        <br />
        <br />• <strong>Right to Non-Discrimination.</strong> We will not
        discriminate against you for exercising your rights. Specifically, we
        will not deny you services, charge you different prices or rates for
        services, or provide you a different level or quality of services,
        because you elected to exercise your rights.
        <br />
        <br />•{" "}
        <strong>
          Right to limit the use of Sensitive Personal Information.
        </strong>{" "}
        The CCPA allows you to limit certain uses and disclosures of your
        sensitive personal information to certain purposes specified by law
        (e.g., providing you with services you request or preventing fraud, or
        for other purposes that don’t involve deriving your attributes). Because
        of our limited use of your sensitive personal data, we are not required
        to offer you this opt-out right.
        <br />
        <br />
        <strong>Exercising Your Rights.</strong> You may exercise your rights by
        contacting us as outlined in Section 13 (How to Contact Us). To verify
        your identity, we may ask you to verify personal information we already
        have on file for you. If we cannot verify your identity from this
        information, we may request additional information, which will only be
        used to verify your identity, and for security or fraud-prevention
        purposes. In some instances, we may seek for you to identify at least
        three pieces of your personal information maintained by the business and
        submit a signed declaration under penalty of perjury that you are a
        California consumer whose personal information is the subject of the
        request. You may designate an authorized agent to make a request to
        exercise your rights outlined in this Privacy Policy. We will respond to
        your authorized agent’s request if they submit proof that they are
        registered with the California Secretary of State to be able to act on
        your behalf or submit evidence you have provided them with power of
        attorney pursuant to Probate Code section 4000 to 4465. We may deny
        requests from authorized agents who do not submit proof that they have
        been authorized by you to act on their behalf. In certain situations,
        you may have the right to appeal the denial of your request to exercise
        your rights. Please submit an appeal using the contact information in
        Section 13 (How to Contact Us).
        <br />
        <br />
        <strong>Do Not Track Disclosure.</strong> ReplyAssist does not have a
        mechanism in place for responding to browser “do not track” signals or
        other similar mechanisms used to limit collection of information for use
        in online behavioral advertising.
        <br />
        <br />
        <strong>b. United States residents generally:</strong> In states where
        residents are allowed additional individual rights, we are committed to
        honoring those rights for such residents, including requests that may
        limit how we use and share your data, or requests to delete or correct
        inaccurate data, consistent with applicable law. Note that the
        applicable law may exempt certain personal information from such
        requests. To submit a request based on local law, please contact us as
        indicated in Section 14 (How to Contact Us). We will respond to your
        request as required by law. If any circumstances cause a material delay
        in our response, you will be promptly notified. Under certain state
        laws, certain residents may opt out of the sale or sharing of their
        personal information, or the use of their personal information for
        targeted advertising or profiling purposes. We will not sell your
        personal information unless we first provide you with a separate notice
        prior to such sale. To exercise a legal right, you have to opt-out of or
        limit these activities, please contact using the methods outlined in
        Section 14 (How to Contact Us). In certain situations, You may have the
        right to appeal the denial of your request to exercise your rights.
        Please submit an appeal using the contact information in Section 14 (How
        to Contact Us).
        <br />
        <br />
        <strong>
          12. Special Notice for Individuals in the EEA, UK, and Switzerland
        </strong>
        <br />
        This section only applies to interactions with individuals who are
        citizens of, or at the time of data collection reside in the EEA, UK or
        Switzerland (collectively, the “Designated Countries”).
        <br />
        <br />
        <strong>a. Role.</strong> ReplyAssist is a data controller with respect
        to any personal information collected from the Website and Interactions.
        Any third parties that handle your personal information in accordance
        with our instructions are our service providers and are “data
        processors.” You are a “user.” Users are individuals providing personal
        information to us via the Website and Interactions.
        <br />
        <br />
        <strong>b. Marketing.</strong> We will only contact individuals located
        in the Designated Countries by electronic means (including email or SMS)
        based on our legitimate interests, as permitted by applicable law, or
        the individual’s consent. If you do not want us to use your personal
        information in this way, please go to the email settings for your
        account to opt out, click an unsubscribe link in your emails, or contact
        us at info@replyassist.com. You can object to direct marketing at any
        time and free of charge.
        <br />
        <br />
        <strong>c. Additional Privacy Rights.</strong> We provide you with the
        rights described below. We may limit these privacy rights requests (i)
        where denial of access is required or authorized by law, (ii) when
        granting access would have a negative impact on others’ privacy, (iii)
        to protect our rights and properties, or (iv) where the request is
        frivolous or burdensome. If you would like to exercise your rights under
        applicable law, please contact us at info@replyassist.com. We may seek
        to verify your identity when we receive your privacy rights request to
        ensure the security of your personal information.
        <br />
        <br />
        <strong>o Right to withdraw consent.</strong> For any consent-based
        processing of your personal information, you have the right to withdraw
        your consent. A withdrawal of consent will not affect the lawfulness of
        our processing or the processing of any third parties based on consent
        before your withdrawal.
        <br />
        <br />
        <strong>o Right of access/right of portability.</strong> You may have
        the right to access the personal information that we hold about you, and
        in some limited circumstances, have the personal information provided to
        you so that you can provide that personal information to another
        controller.
        <br />
        <br />
        <strong>o Right to rectification.</strong> You may request to correct
        any of your personal information in our files.
        <br />
        <br />
        <strong>o Right to erasure.</strong> In certain circumstances, you may
        have a right to the erasure of your personal information that we hold
        about you.
        <br />
        <br />
        <strong>o Right to restriction.</strong> You have the right to request
        that we restrict our processing of your personal information in certain
        circumstances.
        <br />
        <br />
        <strong>o Right to object to processing.</strong> You have the right to
        object to our processing of your personal information at any time and as
        permitted by applicable law if we process your personal information on
        the legal basis of consent; contract; or legitimate interests. We may
        continue to process your personal information if it is necessary for the
        defense of legal claims, or for any other exceptions permitted by
        applicable law.
        <br />
        <br />
        <strong>o Notification to third parties.</strong> When we fulfill your
        individual rights requests for correction, erasure or restriction of
        processing, we will notify third parties also handling the relevant
        personal information unless this proves impossible or involves
        disproportionate effort.
        <br />
        <br />
        <strong>o Right to Lodge Complaint.</strong> We commit to resolve
        complaints about our collection or use of your personal information.
        EEA, UK, or Swiss individuals with inquiries or complaints regarding
        this privacy notice should first contact us at info@replyassist.com. You
        also have the right to lodge a complaint with the supervisory authority
        of your habitual residence, place of work or place of alleged
        infringement, if you consider that the processing of your personal
        information infringes applicable law.
        <br />
        <br />
        <strong>d. International Transfers.</strong> In allowing you to use
        Website and Interactions we may need to transfer your personal
        information outside the Designated Countries including to countries that
        do not provide the same level of protection for personal information. In
        such case, we will only transfer personal information to recipients that
        provide an adequate level of data protection or as permitted by
        applicable data protection laws by implementing appropriate safeguards,
        including, but not limited to, relevant data transfer agreements based
        on the EU Standard Contractual Clauses or UK Standard Contractual
        Clauses, as applicable (“Clauses”). The Clauses shall apply to any
        transfers of personal information from the Designated Countries to
        countries which do not ensure an adequate level of data protection
        within the meaning of applicable laws of the foregoing territories.
        <br />
        <br />
        <strong>e. Legal Bases for Processing Personal Information.</strong> Our
        legal bases for collecting and using the personal information described
        above will depend on the type of personal information collected, the
        specific context in which we collect it and the purposes for which it is
        used. Whenever we require your consent for the processing of your
        personal information such processing will be based on Article 6(1) lit.
        (a) GDPR or the equivalent legal bases in the applicable law. If the
        processing of your personal information is necessary for the performance
        of a contract between you and ReplyAssist or for taking any
        pre-contractual steps upon your request, such processing will be based
        on Article 6(1) lit. (b) GDPR. If this data is not processed,
        ReplyAssist will not be able to execute the contract with you. Where the
        processing is necessary for us to comply with a legal obligation, we
        will process your information on the basis of Article 6(1) lit. (c)
        GDPR, for example for complying with obligations under anti-money
        laundering laws. Where the processing is necessary for the purposes of
        ReplyAssist’s or another party’s legitimate interests, such processing
        will be made in accordance with Article 6(1) lit. (f) GDPR.
        <br />
        <br />
        <strong>13. Updates</strong>
        <br />
        We may update this Privacy Policy from time to time. If we make material
        changes to the way we collect, use, share or process the personal
        information that you provide, we will notify you by posting a notice of
        the changes in a clear and conspicuous manner on the Website, via the
        most recent email address we have on file for you, or via another
        communication channel where permitted by law.
        <br />
        <br />
        <strong>14. How to Contact Us</strong>
        <br />
        To exercise your rights, or if you have any questions about this Notice
        or our treatment of your personal information, please send us an email
        at info@replyassist.com or by mail to ReplyAssist, Inc., 2045 W. Grand
        Ave, Suite B, PMB 414451, Chicago, IL 60612-1577.
      </p>
    ),
  },
  {
    question: "Terms and Conditions",
    answer: (
      <p className={styles.answer_text}>
        <strong>SOFTWARE AS A SERVICE TERMS AND CONDITIONS</strong> <br />
        These Software as a Service Terms and Conditions (“Terms”) are
        incorporated into and made a part of Customer’s Order effective as of
        the date of purchase by Customer (“Effective Date”). Customer’s access
        to the Services is expressly conditioned upon acceptance of these Terms
        and ReplyAssist will not accept any different or additional terms and
        conditions, whether contained in Customer’s request for proposal,
        request for quotation, purchase order, or otherwise. <br />
        <br />
        <strong>1. SERVICES</strong> <br />
        <strong>1.1. PURPOSE.</strong> These Terms set forth the terms and
        conditions under which ReplyAssist agrees to provide (i) certain hosted
        “software as a service” (the “SaaS”) and (ii) technical support and
        maintenance (“Support Services” together with the SaaS, the “Services”)
        related to Customer’s access to, and use of, such Services, as set forth
        on Customer’s Order. <br />
        <br />
        <strong>1.2. THE SERVICES.</strong> Subject to these Terms, during the
        Term, ReplyAssist will use commercially reasonable efforts to provide
        Customer and Authorized Users, if any, access to the Services. <br />
        <br />
        <strong>1.3. USE.</strong> Subject to these Terms, during the Term,
        ReplyAssist hereby grants Customer and Authorized Users a non-exclusive,
        non-sublicensable, non-transferable, restricted worldwide right to
        access and use the SaaS, solely for Customer’s internal business
        purposes. All use of the Services is also subject to ReplyAssist’s
        website terms of use, set forth at www.replyassist.com/terms-of-service,
        as amended from time to time (“Website Terms and Conditions”). In the
        event of a conflict between the Website Terms and Conditions and these
        Terms, these Terms will control. <br />
        <br />
        <strong>1.4. SUPPORT SERVICES.</strong> ReplyAssist will provide
        reasonable customer support services to Customer during normal business
        hours. <br />
        <br />
        <strong>1.5. CHANGES TO PLATFORM.</strong> ReplyAssist may, in its sole
        discretion, make any changes to any SaaS that it deems necessary or
        useful to (i) maintain or enhance (a) the quality or delivery of
        ReplyAssist’s products or services to its customers, (b) the competitive
        strength of, or market for, ReplyAssist’s products or services, (c) such
        Platform’s cost efficiency or performance, (ii) to comply with
        applicable law. Such changes to the SaaS will not change the rights or
        obligations of the parties under these Terms. <br />
        <br />
        <strong>1.6. ARTIFICIAL INTELLIGENCE.</strong> The SaaS includes
        artificial intelligence functionality which means that it utilizes data
        models trained by machine learning (“AI”). <br />
        <br />
        <strong>1.6.1. Training of AI.</strong> The input that Customer provides
        to tailor the AI, as well as the outputs generated, may be used to train
        ReplyAssist’s AI and Customer hereby grants a license for such use.{" "}
        <br />
        <br />
        <strong>1.6.2. Outputs Not Unique.</strong> Customer acknowledges that,
        due to the nature of machine learning and the technology powering AI
        features, the output generated by AI based upon provided input may not
        be unique and AI may generate the same or similar output to ReplyAssist
        or any third party. <br />
        <br />
        <strong>1.6.3. Limitations of AI.</strong> Customer acknowledges that AI
        is an evolving field and, that while ReplyAssist attempts to make the
        SaaS accurate, reliable, safe and beneficial, nevertheless outputs may
        not always accurately reflect real people, places, or facts. In using
        the SaaS, Customer understands and agrees, that: (i) outputs may not
        always be accurate and you should not rely on outputs as a sole source
        for truth, factual information, or as a substitute for professional
        advice; (ii) Customer must evaluate outputs for accuracy and
        appropriateness for their use case, including using human review as
        appropriate; (iii) Customer must not use outputs relating to a person
        for any purpose that could have a legal or material impact on that
        person, such as making credit, educational, employment, housing,
        insurance, legal, medical, or other important decisions about them; and,
        (iv) any incomplete, incorrect, or offensive output created does not
        represent ReplyAssist’s views, nor is any reference to third party
        product or services an endorsement or indication of affiliation with
        ReplyAssist. <br />
        <br />
        <strong>1.6.4. Restrictions on Use.</strong> Customer may not utilize
        the Services or the AI to: (i) violate these Terms, including any AI
        Content or Use Policies; (ii) infringe, violate, or misappropriate any
        rights of ReplyAssist or any third party; (iii) develop foundation
        models or other large-scale models that compete with ReplyAssist; (iv)
        mislead any person that output is solely human-generated; (v) to
        generate spam or content for dissemination in electoral campaigns; or
        (vi) in a manner that violates any technical documentation, usage
        guidelines or parameters. <br />
        <br />
        <strong>1.6.5. Compliance with Law.</strong> Customer will comply with
        all rules, laws, and restrictions relating to the use of any AI output
        including, without limitation, providing disclaimers with output if such
        output is utilized in the fields of medicine, finance, or law. <br />
        <br />
        <strong>1.6.6. Applicability of Third-Party Policies.</strong> Customer
        is responsible for use of the Services in compliance with the policies
        of OpenAI including, without limitation, their Content Policy; Sharing
        and Publication Policy; and Community Guidelines. You hereby indemnify
        and hold ReplyAssist harmless from and against any claims arising from
        or relating to your breach of the foregoing. <br />
        <br />
        <strong>2. SAAS ACCESS AND AUTHORIZED USERS</strong> <br />
        <strong>2.1. ADMINISTRATIVE USERS.</strong> During the configuration and
        set-up process for the SaaS, Customer will identify an administrative
        username and password for Customer’s ReplyAssist account. Each
        administrative user will also be considered an Authorized User.
        ReplyAssist reserves the right to refuse registration of or cancel
        usernames and passwords it deems inappropriate. <br />
        <br />
        <strong>2.2. AUTHORIZED USERS.</strong> Customer may allow such number
        of Customer’s employees and/or independent contractors to use the SaaS
        on behalf of Customer (“Authorized Users”), subject to any limitations
        set forth herein. As a condition to access and use of the SaaS, Customer
        is responsible for ensuring that any Authorized Users use of the SaaS is
        in accordance with the terms and conditions of these Terms. Customer
        will immediately notify ReplyAssist of any violation of the terms of any
        of the foregoing by any Authorized Users upon becoming aware of such
        violation. Customer is liable for any breach of these Terms by any
        Authorized User. <br />
        <br />
        <strong>2.3. ACCOUNT RESPONSIBILITY.</strong> Customer will be
        responsible for (i) all uses of any account that Customer has access to,
        whether or not Customer has authorized the particular use or user, and
        regardless of Customer’s knowledge of such use, and (ii) securing its
        ReplyAssist account, passwords (including but not limited to
        administrative and user passwords) and files. ReplyAssist is not
        responsible for any losses, damages, costs, expenses or claims that
        result from stolen or lost passwords. <br />
        <br />
        <strong>3. ADDITIONAL RESTRICTIONS AND RESPONSIBILITIES</strong> <br />
        <strong>3.1. SAAS RESTRICTIONS.</strong> Customer will not, nor permit
        or encourage any third party to, directly or indirectly (i) reverse
        engineer, decompile, disassemble, decode, decrypt, or otherwise attempt
        to discover or derive the source code, object code or underlying
        structure, ideas, know-how or algorithms relevant to the SaaS or any
        SaaS, documentation or data related to the SaaS (“IP”); (ii) modify,
        translate, adapt, alter or create derivative works based on the SaaS or
        any IP; (iii) modify, remove or obstruct any proprietary notices or
        labels; (iv) distribute, sublicense, rent, lease, loan, or grant any
        third party access to or use of the Services; or (v) use the SaaS or any
        IP in any manner to assist or take part in the development, marketing or
        sale of a product potentially competitive with such SaaS or. For the
        avoidance of doubt, the SaaS, including all user-visible aspects, are
        the Confidential Information of ReplyAssist, and Customer will comply
        with Section 4 with respect thereto. <br />
        <br />
        <strong>3.2. CUSTOMER COMPLIANCE.</strong> Customer shall use, and will
        ensure that all Authorized Users use, the SaaS, IP, and the Services in
        full compliance with these Terms and all applicable laws and
        regulations. Customer represents and warrants that it (i) has accessed
        and reviewed the Terms and Conditions, Privacy Policy, or other policies
        relating to a SaaS provided by ReplyAssist, (ii) understands the
        requirements thereof, and (iii) agrees to comply therewith. ReplyAssist
        may suspend Customer’s account and access to SaaS and performance of the
        Services at any time and without notice if ReplyAssist believes that
        Customer is in violation of these Terms. Although ReplyAssist has no
        obligation to monitor Customer’s use of the SaaS, ReplyAssist may do so
        and may prohibit any use it believes may be (or alleged to be) in
        violation of the foregoing. <br />
        <br />
        <strong>3.3. COOPERATION.</strong> Customer shall provide all
        cooperation and assistance as ReplyAssist may reasonably request to
        enable ReplyAssist to exercise its rights and perform its obligations
        under, and in connection with, these Terms. <br />
        <br />
        <strong>3.4. TRAINING AND EDUCATION.</strong> Customer shall use
        commercially reasonable efforts to cause Authorized Users to be, at all
        times, educated and trained in the proper use and operation of the SaaS
        and to ensure that the SaaS is used in accordance with applicable
        manuals, instructions, specifications and documentation provided by
        ReplyAssist from time to time. <br />
        <br />
        <strong>3.5. CUSTOMER SYSTEMS.</strong> Customer shall be responsible
        for obtaining and maintaining both the functionality and security of any
        equipment and ancillary services needed to connect to, access or
        otherwise use the SaaS, including modems, hardware, servers, SaaS,
        operating systems, networking, web servers and the like. <br />
        <br />
        <strong>3.6. RESTRICTIONS ON EXPORT.</strong> Customer may not remove or
        export from the United States or allow the export or re-export of the
        SaaS, or any direct product thereof in violation of any restrictions,
        laws or regulations of any United States or foreign agency or authority.{" "}
        <br />
        <br />
        <strong>4. CONFIDENTIALITY</strong> <br />
        <strong>4.1. CONFIDENTIAL INFORMATION.</strong> Each party (the
        “Receiving Party”) understands that the other party (the “Disclosing
        Party”) has been, and may be, exposed to or acquired business, technical
        or financial information relating to the Disclosing Party’s business
        (hereinafter referred to as “Confidential Information”). Confidential
        Information of ReplyAssist includes, without limitation, non-public
        information regarding features, functionality, and performance of the
        SaaS, and ReplyAssist IP. Confidential Information of Customer includes,
        without limitation, non-public data provided by Customer to ReplyAssist
        to enable the provision of access to, and use of, the Services
        (“Customer Data”), The terms and conditions of these Terms, including
        all pricing and related metrics, are ReplyAssist’s Confidential
        Information. <br />
        <br />
        <strong>4.2. EXCEPTIONS.</strong> Notwithstanding anything to the
        contrary contained herein, Confidential Information shall not include
        any information that the Receiving Party can document (i) is or becomes
        generally available to the public, (ii) was in its possession or known
        by it prior to receipt from the Disclosing Party, (iii) was rightfully
        disclosed to it without restriction by a third party, or (iv) was
        independently developed without use of any Confidential Information of
        the Disclosing Party. <br />
        <br />
        <strong>4.3. NON-USE AND NON-DISCLOSURE.</strong> With respect to
        Confidential Information of the Disclosing Party, the Receiving Party
        agrees to: (i) use the same degree of care to protect the
        confidentiality, and prevent the unauthorized use or disclosure, of such
        Confidential Information it uses to protect its own proprietary and
        confidential information of like nature, which shall not be less than a
        reasonable degree of care, (ii) hold all such Confidential Information
        in strict confidence and not use, sell, copy, transfer, reproduce, or
        divulge such Confidential Information to any third party, (iii) not use
        such Confidential Information for any purposes whatsoever other than the
        performance of, or as otherwise authorized by, these Terms. <br />
        <br />
        <strong>4.4. COMPELLED DISCLOSURE.</strong> Notwithstanding Section 4.3,
        the Receiving Party may disclose Confidential Information of the
        Disclosing Party to the extent necessary to comply with a court order or
        applicable law; provided, however, that the Receiving Party delivers
        reasonable advance notice of such disclosure to the Disclosing Party and
        uses reasonable efforts to secure confidential treatment of such
        Confidential Information, in whole or in part. <br />
        <br />
        <strong>
          4.5. REMEDIES FOR BREACH OF OBLIGATION OF CONFIDENTIALITY.
        </strong>{" "}
        The Receiving Party acknowledges that breach of its obligation of
        confidentiality may cause irreparable harm to the Disclosing Party for
        which the Disclosing Party may not be fully or adequately compensated by
        recovery of monetary damages. Accordingly, in the event of any
        violation, or threatened violation, by the Receiving Party of its
        obligations under this Section, the Disclosing Party shall be entitled
        to seek injunctive relief from a court of competent jurisdiction in
        addition to any other remedy that may be available at law or in equity,
        without the necessity of posting bond or proving actual damages. <br />
        <br />
        <strong>5. PROPRIETARY RIGHTS</strong> <br />
        <strong>5.1. OWNERSHIP.</strong> Customer owns all right, title and
        interest in and to the Customer Data. ReplyAssist owns and retains all
        right, title and interest in and to (i) the SaaS, IP and the Services,
        including AI inputs and outputs, and all improvements, enhancements or
        modifications thereto, (ii) any SaaS, applications, inventions or other
        technology developed in connection with the Services, and (iii) all
        intellectual property and proprietary rights in and related to any of
        the foregoing (collectively, “Services IP”). To the extent Customer
        acquires any right, title or interest in any Services IP, Customer
        hereby assigns all of its right, title and interest in such Services IP
        to ReplyAssist. <br />
        <br />
        <strong>
          5.2. CUSTOMER DATA AND REPLYASSIST INFORMATION LICENSE.
        </strong>{" "}
        In addition to the rights granted in Section 1.6, Customer hereby grants
        to ReplyAssist a non-exclusive, transferable, sublicensable, worldwide
        and royalty-free license to use and otherwise exploit (i) Customer Data
        to provide the Services to Customer hereunder and as necessary or useful
        to monitor and improve the SaaS, both during and after the Term. For the
        avoidance of doubt, ReplyAssist may use, reproduce, and disclose SaaS
        and Services related information, data and material that is anonymized,
        de-identified, or otherwise rendered not reasonably associated or linked
        to Customer or any other identifiable individual person or entity for
        product improvement and other lawful purposes, all of which information,
        data and material will be owned by ReplyAssist. It is Customer’s sole
        responsibility to back-up Customer Data during the Term, and Customer
        acknowledges that it will not have access to Customer Data through
        ReplyAssist or any SaaS following the expiration or termination of these
        Terms. Notwithstanding anything to the contrary in these Terms, and in
        addition to the rights ReplyAssist has according to its Privacy Policy
        (found at www.replyassist.com/privacy-policy, as amended from time to
        time) ReplyAssist has the right to collect and analyze data, including
        Customer Data, and other information relating to the provision, use and
        performance of various aspects of the Services and is free to: (i) use
        such information and data to improve and enhance the Services and for
        other development, diagnostic and corrective purposes in connection with
        the Services and other ReplyAssist offerings, and (ii) use and disclose
        such data solely in aggregate or other de-identified form in connection
        with its business, or in any other manner permitted by these Terms or
        the privacy policy. <br />
        <br />
        <strong>5.3. NO OTHER RIGHTS.</strong> No rights or licenses are granted
        except as expressly set forth herein. Customer acknowledges and agrees
        that the SaaS and IP are the confidential, proprietary, trade secret and
        copyrighted work of ReplyAssist and any use of the SaaS or IP beyond the
        terms of these Terms constitutes an infringement of ReplyAssist’s rights
        entitling ReplyAssist to seek all remedies available to it, including,
        without limitation, those under title 17 of the United States Code with
        respect to copyright infringement. <br />
        <br />
        <strong>6. FEES &amp; PAYMENT</strong> <br />
        <strong>6.1. FEES.</strong> In exchange for the Services, Customer will
        pay ReplyAssist the fees described in any Order. All fees are payable
        within thirty (30) days of the invoice date. Fees are non-refundable,
        except as expressly provided in these Terms. Customer will have five (5)
        days following a due date to cure such late payment, but if Customer
        fails to pay such amount(s), ReplyAssist will have the right to suspend
        Customer’s access to the Services and/or charge a late payment fee on
        the unpaid balance of one percent (1.0%) per month, or the highest rate
        allowed by law, whichever is lower. In addition to ReplyAssist’s other
        remedies, Customer shall reimburse ReplyAssist for all costs or costs
        incurred in collecting any overdue and unpaid amounts with respect to
        any invoice, including reasonable legal fees. <br />
        <br />
        <strong>6.2. PAYMENT DISPUTES.</strong> If Customer believes that
        ReplyAssist has billed Customer incorrectly, Customer must contact
        ReplyAssist no later than thirty (30) days after the closing date on the
        first billing statement in which the believed error or problem appeared
        in order to receive an adjustment or credit. Inquiries should be
        directed to ReplyAssist’s customer support department or the applicable
        account manager. Any disputes not raised within this time period are
        deemed waived. <br />
        <br />
        <strong>6.3. TAXES.</strong> Customer shall pay, and shall be liable
        for, all taxes relating to ReplyAssist’s provision of the Services
        hereunder except ReplyAssist shall pay, and shall be liable for, taxes
        based on its net income or capital. <br />
        <br />
        <strong>6.4. NO DEDUCTIONS OR SETOFFS.</strong> All amounts payable to
        ReplyAssist hereunder shall be paid by Customer to ReplyAssist in full
        without any setoff, recoupment, counterclaim, deduction, debit or
        withholding for any reason except as may be required by applicable law.{" "}
        <br />
        <br />
        <strong>6.5. SUBPOENA EXPENSES.</strong> If ReplyAssist has to provide
        information in response to a subpoena related to Customer’s account,
        then ReplyAssist may charge Customer for ReplyAssist’s costs. Such
        charges may include fees for attorney and employee time spent retrieving
        records, preparing documents and participating in depositions or other
        legal processes as well as other costs incurred in complying with such
        legal processes. <br />
        <br />
        <strong>7. TERM AND TERMINATION</strong> <br />
        <strong>7.1. TERM.</strong> These Terms will commence on the Effective
        Date and continue in full force and effect for the duration of the
        period set forth on the Order, unless terminated earlier as provided
        below (the “Initial Term”). Following the Initial Term, unless
        prohibited by law, the Terms will automatically renew for subsequent
        one-year terms (each a “Renewal Term”) unless either party provides
        written notice of non-renewal at least sixty days prior to the end of
        the then-current Term. The Pricing for the Services for any Renewal Term
        will be charged at ReplyAssist’s then-current market rates. <br />
        <br />
        <strong>7.2. Termination by ReplyAssist.</strong> ReplyAssist may
        terminate these Terms upon thirty (30) days written notice to Customer
        if: <br />
        <br />
        <strong>7.2.1.</strong> Customer has a receiver or an administrative
        receiver appointed, or the Licensee becomes subject, as debtor, to the
        jurisdiction of any bankruptcy court, enters into any voluntary
        arrangement with its creditors or ceases, threatens to cease to carry on
        business or any of the foregoing occurs under a local law equivalent.
        Any termination under this Section 7.2.1 relieves ReplyAssist of the
        obligation for the performance of these Terms; <br />
        <br />
        <strong>7.2.2.</strong> The Customer fails to pay within thirty (30)
        days of its due date, or within any agreed period of grace, any sum due
        under these Terms or any exhibit; or <br />
        <br />
        <strong>7.2.3.</strong> The Customer commits any material breach of any
        other term of these Terms which is not remedied within thirty (30) days
        of a written request to do so. <br />
        <br />
        <strong>7.3. TERMINATION BY CUSTOMER.</strong> Customer may terminate
        these Terms upon thirty (30) days written notice to ReplyAssist if
        ReplyAssist commits any material breach of these Terms which is not
        remedied within thirty (30) days of a written request to do so, or if
        such remedy is not reasonably capable of being completed in such thirty
        (30) day time period, for which a remedy has not been commenced and
        consistently pursued. <br />
        <br />
        <strong>7.4. EFFECT OF TERMINATION.</strong> Upon termination of the
        Terms, Customer shall immediately cease all use of, and all access to,
        the Services and ReplyAssist shall immediately cease providing the
        Services. Termination relieves ReplyAssist of any obligations of
        performance with respect to the Services. All Fees paid as of the date
        of termination are non-refundable, except to the extent expressly
        provided by these Terms. Any and all Fees that would have become payable
        had the Terms remained in effect will become immediately due and
        payable. <br />
        <br />
        <strong>7.5. SURVIVAL.</strong> Sections 3.1, 4–6, 7.2, 7.4, and 9–16
        survive any termination or expiration of these Terms. Additionally, any
        other portions of these Terms which, by their nature, are intended to
        survive expiration or termination of these Terms, will so survive. All
        other rights and obligations shall be of no further force or effect.{" "}
        <br />
        <br />
        <strong>8. WARRANTY AND DISCLAIMER</strong> <br />
        <strong>8.1. WARRANTY BY CUSTOMER.</strong> <br />
        <br />
        <strong>8.1.1. Ownership of LinkedIn® Account.</strong> In order to
        provide the SaaS, ReplyAssist requires account access to Customer or
        Customer’s Authorized User’s LinkedIn® Account. Customer represents and
        warrants that it has ownership of such LinkedIn® Account and is
        authorized to provide access to ReplyAssist. If the details of such
        LinkedIn® Account are changed in a manner to prevent access by
        ReplyAssist, some or all features of the SaaS may not be available.
        Customer is solely liable for updating ReplyAssist on any changes to its
        LinkedIn® Account. <br />
        <br />
        <strong>8.1.2. Privacy Policy and Compliance with Law.</strong> Customer
        represents and warrants that it will: (i) maintain a legally adequate
        privacy policy as necessary to comply with any applicable laws, rules or
        regulations; (ii) provide all necessary disclosures to its prospects and
        customers; (iii) obtain all consents necessary from its prospects and
        customers to utilize the content of their LinkedIn® conversations as
        required by the Services; (iv) comply with all terms applicable to
        third-party services used in connection with the Services; and (v) not
        submit, collect, or use any Regulated Data with the Services. For the
        purposes of these Terms, Regulated Data shall mean: (a) any patient,
        medical, or other protected or regulated health information; (b) any
        government IDs, financial information (including bank account or payment
        card numbers); or (c) any other information subject to regulation or
        protection under specific laws or regulations (other than Data
        Protection Laws). <br />
        <br />
        <strong>8.2. LIMITED SAAS WARRANTY.</strong> ReplyAssist will use
        reasonable efforts consistent with prevailing industry standards to
        provide the SaaS in accordance with the SLA. If no SLA is attached,
        ReplyAssist’s warranty for the SaaS is solely to use reasonable efforts
        to minimize errors and interruptions in the SaaS. <br />
        <br />
        <strong>8.3. LIMITED SUPPORT SERVICES WARRANTIES.</strong> ReplyAssist’s
        sole warranty for the Support Services, and the sole remedies for any
        breach thereof, is set forth in the SLA. If no SLA is attached, then no
        warranty is provided and any such warranty for the Support Services is
        hereby disclaimed. <br />
        <br />
        <strong>8.4. WARRANTY OF AUTHORITY.</strong> Each party represents and
        warrants that it has the legal power to enter into these Terms.
        Additionally, Customer warrants that (i) Customer owns or has a license
        to use and has obtained all consents and approvals necessary for the
        provision and use of all of the Customer Data that is placed on,
        transmitted via or recorded by the SaaS and the Services; (ii) the
        provision and use of Customer Data as contemplated by these Terms does
        not violate any Customer’s privacy policy, terms-of-use or other
        agreement to which Customer is a party or any law or regulation to which
        Customer is subject to; and (iii) no Customer Data will include social
        security numbers or other government-issued identification numbers,
        financial account numbers, credit card or debit card numbers, credit
        report information or other personal financial information, health or
        medical information or other information that is subject to
        international, federal, state, or local laws or ordinances now or
        hereafter enacted regarding data protection or privacy, including, but
        not limited to, the Health Insurance Portability and Accountability Act,
        the Health Information Technology for Economic and Clinical Health Act,
        the Fair Credit Reporting Act, the Children’s Online Privacy Protection
        Act and the Gramm-Leach-Bliley Act. <br />
        <br />
        <strong>8.5. DISCLAIMER.</strong> EXCEPT AS EXPRESSLY PROVIDED HEREIN,
        REPLYASSIST DOES NOT WARRANT THAT ACCESS TO THE SAAS OR SERVICES WILL BE
        UNINTERRUPTED OR ERROR FREE, NOR DOES REPLYASSIST MAKE ANY WARRANTY AS
        TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES. FURTHER,
        REPLYASSIST MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO
        SERVICES PROVIDED BY THIRD PARTY TECHNOLOGY SERVICE PROVIDERS RELATING
        TO OR SUPPORTING THE SAAS, INCLUDING HOSTING AND MAINTENANCE SERVICES,
        AND ANY CLAIM OF CUSTOMER ARISING FROM OR RELATING TO SUCH SERVICES
        SHALL, AS BETWEEN REPLYASSIST AND SUCH SERVICE PROVIDER, BE SOLELY
        AGAINST SUCH SERVICE PROVIDER. THE PLATFORMS, SAAS AND SERVICES ARE
        PROVIDED “AS IS,” AND REPLYASSIST DISCLAIMS TO THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW ALL WARRANTIES, EXPRESS, IMPLIED OR
        STATUTORY, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
        NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        LICENSEE ACKNOWLEDGES THAT, EXCEPT AS PROVIDED IN THIS TERMS, NO
        REPRESENTATIONS HAVE BEEN MADE RESPECTING THE SAAS, THE SERVICES, OR ANY
        OTHER GOODS OR SERVICES PROVIDED, AND THAT LICENSEE HAS NOT RELIED ON
        ANY REPRESENTATION NOT INCLUDED IN THIS TERMS. LICENSOR DISCLAIMS ANY
        EXPRESS OR IMPLIED WARRANTY THAT THE SERVICES WILL MEET LICENSEE'S
        REQUIREMENTS; THAT THE SAAS WILL OPERATE IN COMBINATIONS WITH OTHER
        HARDWARE, SAAS, SYSTEMS, OR DATA NOT PROVIDED BY LICENSOR. IN ADDITION
        TO THE FOREGOING DISCLAIMERS AND NOTWITHSTANDING ANYTHING HEREIN TO THE
        CONTRARY, ANY USE OR ACCESS OF THE SERVICES THAT IS PROVIDED AS PART OF
        A TRIAL OR BETA TEST IS PROVIDED ON AN “AS IS” BASIS AND WITHOUT ANY
        EXPRESS OR IMPLIED WARRANTY OF ANY KIND. <br />
        <br />
        <strong>8.6. ARTIFICIAL INTELLIGENCE WARRANTY DISCLAIMER.</strong> In
        addition to the foregoing, as it pertains to any part of the SaaS that
        utilizes Artificial Intelligence, REPLYASSIST DOES NOT MAKE ANY WARRANTY
        AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF ARTIFICIAL
        INTELLIGENCE OR THE ACCURACY OF ANY OTHER INFORMATION OBTAINED THROUGH
        ARTIFICIAL INTELLIGENCE. YOU UNDERSTAND AND AGREE THAT ANY MATERIAL
        AND/OR DATA OBTAINED THROUGH THE USE OF ANY ARTIFICIAL INTELLIGENCE
        FEATURE IS DONE AT YOUR SOLE RISK. YOU SHOULD NOT RELY ON FACTUAL
        ASSERTIONS IN OUTPUT WITHOUT INDEPENDENTLY FACT CHECKING THEIR ACCURACY.
        OUTPUT THAT APPEARS ACCURATE BECAUSE OF ITS DETAIL OF SPECIFICITY MAY
        STILL CONTAIN MATERIAL INACCURACIES. ARTIFICIAL INTELLIGENCE CANNOT
        DYNAMICALLY RETRIEVE INFORMATION, AND OUTPUT MAY NOT ACCOUNT FOR EVENTS
        OR CHANGES TO UNDERLYING FACTS OCCURRING AFTER THE ARTIFICIAL
        INTELLIGENCE MODEL WAS TRAINED. NO INFORMATION OR ADVICE, WHETHER ORAL
        OR WRITTEN, OBTAINED BY YOU FROM REPLYASSIST OR THROUGH ARTIFICIAL
        INTELLIGENCE SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. <br />
        <br />
        <strong>9. INDEMNITY AND LIMITATION OF LIABILITY</strong> <br />
        <strong>9.1. INDEMNIFICATION BY REPLYASSIST.</strong> ReplyAssist will
        defend Customer against any claim, suit, demand, or action made or
        brought against Customer by a third party alleging that the Services, or
        Customer’s use or access thereof in accordance with these Terms,
        infringes any intellectual property rights of such third party, and will
        indemnify and hold harmless Customer from any damages, losses,
        liabilities, costs and fees (including reasonable attorney’s fees)
        finally awarded against Customer in connection with or in settlement of
        any such claim, suit, demand, or action. The foregoing obligations do
        not apply with respect to portions or components of the Services (i) not
        supplied by ReplyAssist, (ii) made in whole or in part in accordance
        with Customer specifications, (iii) that are modified after delivery, or
        granting of access, by ReplyAssist, (iv) combined with other products,
        processes or materials where the alleged infringement relates to such
        combination, (v) where Customer continues allegedly infringing activity
        after being notified thereof or after being informed of modifications
        that would have avoided the alleged infringement, or (vi) where
        Customer’s use of the Services is not strictly in accordance with these
        Terms. If, due to a claim of infringement, a SaaS is held by a court of
        competent jurisdiction to be or is believed by ReplyAssist to be
        infringing, ReplyAssist may, at its option and expense (a) replace or
        modify such SaaS to be non-infringing provided that such modification or
        replacement contains substantially similar features and functionality,
        (b) obtain for Customer a license to continue using such Platform, or
        (c) if neither of the foregoing is commercially practicable, terminate
        these Terms and Customer’s rights hereunder and provide Customer a
        refund of any prepaid, unused fees for such Platform. This Section
        states Customer’s sole and exclusive remedies for claims of
        infringement. <br />
        <br />
        <strong>9.2. INDEMNIFICATION BY CUSTOMER.</strong> Customer will
        indemnify and defend ReplyAssist and its officers, directors, employees,
        agents and affiliates from and against any and all claims, demands,
        suits, fees, judgements, damages, losses, costs and expenses, including
        reasonable attorneys’ fees and costs incurred in responding to or
        defending such claims, that ReplyAssist might suffer or incur
        proximately caused by: Customer’s breach of these Terms or applicable
        law. <br />
        <br />
        <strong>9.3. LIMITATION OF LIABILITY.</strong> In no event shall: <br />
        <br />
        <strong>9.3.1.</strong> TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO
        EVENT WILL EITHER PARTY’S LIABILITY ARISING OUT OF OR RELATED TO THIS
        TERMS, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY
        INCLUDING DATA PROTECTION CLAIMS EXCEED IN THE AGGREGATE THE TOTAL FEES
        PAID OR OWED BY CUSTOMER FOR THE SERVICES HEREUNDER DURING THE TWELVE
        (12) MONTHS IMMEDIATELY PRECEDING THE DATE OF THE EVENT GIVING RISE TO
        THE CLAIM (SUCH AMOUNT BEING INTENDED AS A CUMULATIVE CAP AND NOT PER
        INCIDENT), OR <br />
        <br />
        <strong>9.3.2.</strong> TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO
        EVENT WILL EITHER PARTY HAVE ANY LIABILITY TO THE OTHER FOR ANY LOST
        PROFITS, LOST DATA, LOST GOODWILL, LOST BUSINESS, LOST REVENUES OR DOWN
        TIME OR FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, COVER, SPECIAL,
        EXEMPLARY OR PUNITIVE DAMAGES, HOWEVER CAUSED, WHETHER IN CONTRACT, TORT
        OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS
        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. <br />
        <br />
        <strong>9.3.3.</strong> NOTWITHSTANDING ANYTHING HEREIN TO THE CONTRARY,
        TO THE EXTENT THAT ANY CLAIM IS BASED UPON A FREE ACCESS SUBSCRIPTION,
        SERVICES TRIAL, OR BETA TEST/RELEASE, REPLYASSIST’S TOTAL LIABILITY WILL
        NOT EXCEED, IN THE AGGREGATE, $50.00 USD. <br />
        <br />
        <strong>10. GOVERNING LAW AND DISPUTE RESOLUTION.</strong> These Terms
        are governed in all respects by the laws of the State of Illinois,
        without giving effect to its rules relating to conflict of laws. Neither
        any adoption of the Uniform Computer Information Transactions Act nor
        the U.N. Convention on the International Sale of Goods applies to these
        Terms or to the rights or duties of the parties under these Terms. Any
        dispute arising out of or relating to these Terms, or its subject
        matter, shall be resolved exclusively by binding arbitration under the
        Commercial Arbitration Rules of the American Arbitration Association
        (“AAA”). Either party may send a notice to the other party of its
        intention to file a case with the AAA under this Section (“Arbitration
        Notice”). The arbitration will be conducted in Cook County, Illinois, by
        a single arbitrator knowledgeable in government contracting matters and
        the commercial aspects of “software as a service” arrangements and
        intellectual property. The parties will mutually appoint an arbitrator
        within thirty (30) days of the Arbitration Notice. If the parties are
        unable to agree on an arbitrator, then the AAA will appoint an
        arbitrator who meets the foregoing knowledge requirements. The
        arbitration hearing will commence within sixty (60) days after the
        appointment of the arbitrator and the hearing will be completed and an
        award rendered in writing within sixty (60) days after the commencement
        of the hearing. Prior to the hearing, each party will have the right to
        take up to four (4) evidentiary depositions, and exchange two (2) sets
        of document production requests and two sets, each, of not more than ten
        (10) interrogatories. The arbitrator will provide detailed written
        explanations to the parties to support their award and regardless of
        outcome, each party shall pay its own costs and expenses (including
        attorneys’ fees) associated with the arbitration proceeding and fifty
        percent (50%) of the fees of the arbitrator and the AAA. The arbitration
        award will be final and binding and may be enforced in any court of
        competent jurisdiction. <br />
        <br />
        <strong>11. SECURITY.</strong> ReplyAssist may, from time to time, host
        and/or maintain software using a third-party technology service provider
        and Customer acknowledges that ReplyAssist cannot offer any additional
        or modified procedures other than those put in place by such technology
        provider with respect to such technology service. <br />
        <br />
        <strong>12. PUBLICITY.</strong> Customer agrees that ReplyAssist may
        identify Customer as a customer and use Customer’s logo and trademark in
        ReplyAssist’s promotional materials. Customer may request that
        ReplyAssist stop doing so by submitting an email to info@replyassist.com
        at any time. Customer acknowledges that it may take ReplyAssist up to
        thirty (30) days to process such request. Notwithstanding anything
        herein to the contrary, Customer acknowledges that ReplyAssist may
        disclose the existence and terms and conditions of these Terms to its
        advisors, actual and potential sources of financing and to third parties
        for purposes of due diligence. <br />
        <br />
        <strong>13. NOTICES.</strong> All notices, consents, and other
        communications between the parties under or regarding these Terms must
        be in writing (which includes email and facsimile) and be addressed
        according to information provided by Customer in the Order and, if to
        ReplyAssist, to the following address: 2045 W Grand Ave Ste B PMB
        414451, Chicago, IL 60612. All notices, consents and other
        communications between the parties will be sent to the recipient’s
        address specified thereon. All communications will be deemed to have
        been received on the date. <br />
      </p>
    ),
  },
  {
    question: "Terms of Use",
    answer: (
      <p className={styles.answer_text}>
        Thank you for visiting www.replyassist.com and all of their subdomains
        and aliases (collectively, the “Website” or “Site”) or utilizing a
        ReplyAssist subscription service (“Services”). This Website is owned and
        operated ReplyAssist, Inc. (referred to herein as “ReplyAssist”, “we”,
        “us”, “our” and “ours”). <br />
        <br />
        The following terms and conditions (the “Terms of Use”) form a binding
        agreement between you and us. Unless otherwise indicated, the terms
        “you”, “your” and “yours” when used in these Terms of Use means any user
        or visitor to the Website or the Services. <br />
        <br />
        Please read these Terms of Use carefully. Your access to and use of this
        Website and any of the information, materials, data, demos, blogs,
        products, services and other materials made available on or through this
        Website (collectively, “Materials”) or the Services are subject to all
        applicable laws, rules and regulations and to the terms and conditions
        set forth in these Terms of Use. By accessing or using this Website or
        the Services, you agree to be bound by these Terms of Use and our
        Privacy Policy. <br />
        <br />
        If you are accessing or using this Website or the Services on behalf of
        an entity or business, you represent and warrant that you have the
        authority to bind such entity or business to these Terms of Use. In
        addition, when accessing the Website or the Services you shall be
        subject to any posted guidelines or rules applicable to the Website or
        the Services, which may be posted and modified from time to time. All
        such guidelines or rules are hereby incorporated by reference into these
        Terms. If you do not wish to be bound by these Terms of Use, please exit
        this Website and cease use of all Materials or the Services, as you are
        not permitted to access or use this Website or other such Materials or
        Services. <br />
        <br />
        The Services, Website and Materials may not be accessed for purposes of
        monitoring their availability, performance or functionality, or for any
        other benchmarking or competitive purposes, or as otherwise restricted
        by these Terms of Use. ReplyAssist’s direct competitors are prohibited
        from accessing or using any portion of the Services, Website or
        Materials including, without limitation, downloading or accessing
        whitepapers, accessing free or paid accounts, signing up or
        participating in demos, webcasts, or accessing other Materials intended
        for prospects, customers or partners of ReplyAssist. You are prohibited
        from accessing the Services, Website or any Materials under false
        pretenses. You are prohibited from falsifying or concealing your
        identity in your use and access of the Services, Website or of any
        Materials. <br />
        <br />
        <strong>
          YOUR ACCESS TO THE SERVICES OR THIS WEBSITE (OR PORTIONS THEREOF) AND
          USE OF ANY MATERIALS (OR PORTIONS THEREOF) IS NOT INTENDED, AND IS
          PROHIBITED, WHERE SUCH ACCESS OR USE VIOLATES ANY APPLICABLE LAWS,
          RULES OR REGULATIONS.
        </strong>{" "}
        <br />
        <br />
        <strong>Privacy Policy</strong> <br />
        All information we collect on the Website or within the Services is
        subject to our Privacy Policy. By using the Services, the Website, or
        any Materials, you consent to all actions taken by us with respect to
        your information in compliance with the Privacy Policy. <br />
        <br />
        <strong>Changes to the Terms of Use</strong> <br />
        These Terms of Use may be changed by us, in our sole discretion, from
        time to time with or without notice to you. Any changes or modifications
        to these Terms of Use will be effective upon posting on the Website or
        within the Services. You should always review these Terms of Use prior
        to your access or use of the Services, Website, or Materials to ensure
        that you understand the current Terms of Use that apply to your access
        or use thereof. Your continued use of the Services, Website, or
        Materials following the posting of any changes or modifications will
        constitute your acceptance of such changes or modifications. If you
        cannot access these Terms of Use via the Internet, we can provide a copy
        of the Terms of Use then in effect by email, upon request. <br />
        <br />
        <strong>Product & Services Information</strong> <br />
        All references on the Website to information, materials, products and/or
        services apply to information, materials, products and services
        available in the countries or jurisdictions specified only with respect
        to such information, materials, products and/or services only, unless
        otherwise stated. <br />
        <br />
        <strong>Eligibility</strong> <br />
        The Services, Website, and any Materials are offered and available only
        to users who are of the legal age of consent as defined by applicable
        law (i.e. 18 years of age or older in the United States). Neither the
        Services nor the Website is intended for use with minors (as defined by
        applicable law). By using the Services, the Website, or the Materials,
        you represent and warrant that you are of legal age to form a binding
        contract with us and meet all of the foregoing eligibility requirements.
        If you do not meet all of these requirements, you must not access or use
        the Services, Website, or Materials. <br />
        <br />
        <strong>Making an Account</strong> <br />
        If you elect to make an account with us for a subscription, you must
        complete the registration process and will be subject to additional
        terms and conditions for such subscription. You will choose a username
        and password and you are entirely responsible for maintaining the
        confidentiality of your username and password and preventing
        unauthorized access to your subscription. You are entirely responsible
        for all activities that occur under your account and we are not liable
        for any loss that may incur as a result of someone else using your
        account. <br />
        <br />
        <strong>Ownership and Permitted Use of Materials</strong> <br />
        Unless otherwise indicated, ReplyAssist owns all rights, title, and
        interest in the Services and the Website, including without limitation
        the underlying source code, algorithms, design, text, content, files,
        selection and arrangement of elements, organization, graphics,
        compilation, translations, digital conversion and other matters related
        to, and all trademarks, logos, service marks, symbols, trade dress and
        all Materials provided on or through the Services or Website are
        protected by all applicable copyright laws, trademark laws and/or
        international conventions and treaties. All Materials are the exclusive
        and proprietary property of ReplyAssist and/or its third-party
        licensors. ALL RIGHTS RESERVED. The posting, display or other provision
        of any Materials on the Services or the Website shall not constitute a
        waiver of any right or interest of ReplyAssist and/or its third-party
        licensors in such Materials. Except as expressly provided herein,
        nothing contained in these Terms of Use, the Services, or the Website
        shall be construed as conferring by implication, estoppel or otherwise
        any license, interest or right in or to the Materials under any
        copyright, trademark or proprietary rights of ReplyAssist or of any
        third party. Further, except as otherwise provided herein, no Materials
        (or portions thereof) may be used, copied, reproduced, distributed or
        redistributed, published or republished, downloaded, modified,
        displayed, posted, broadcasted, imitated, adapted, translated, or
        transmitted in any form or by any means, including, but not limited to,
        electronic, mechanical, photocopying, recording, or otherwise, or sold,
        offered for sale, licensed or sublicensed, reverse engineered,
        disassembled, decompiled or incorporated into compilations or used in
        the creation of derivative works, without our express prior written
        permission or that of the respective third-party owner. <br />
        <br />
        Subject to your compliance with the foregoing, permission is hereby
        granted to you to the extent necessary for you to lawfully: (a) access
        the Services, the Website, and/or Materials made available by
        ReplyAssist; and (b) display, download, archive and print in hard copy
        portions of the Services or the Website for your personal or internal
        business, non-commercial and informational use only. <br />
        <br />
        <strong>Prohibited Uses</strong> <br />
        You agree not to: (a) to modify the Materials (or any portion thereof),
        (b) use the Services, the Website, or the Materials in a manner that is
        fraudulent, libelous, defamatory, tortious, obscene, threatening,
        abusive, malicious, offensive, hateful, objectionable, constitutes or
        encourages a criminal offense, discriminatory of a protected class,
        violates the rights of another (including, without limitation, any
        copyright, trademark, patent, trade secret, privacy, moral or publicity
        rights or other intellectual property or proprietary rights of others),
        is likely to deceive any person, causes annoyance, inconvenience, or
        embarrassment, alarm or annoy any other person, or otherwise violates or
        conflicts with these Terms of Use or any applicable law; (c) impersonate
        any person or entity, or otherwise misrepresent your affiliation with
        any person or entity; (d) to interfere, disrupt or attempt to gain
        unauthorized access to the Materials, other ReplyAssist accounts, any
        restricted portions of the Website or any computer network or equipment;
        (e) use any manual or automated software, devices or other processes to
        “crawl” or “spider” any webpages contained in the Website (including,
        without limitation, the use of robots, bots, spiders, scrapers or any
        other means to extract any Materials or other data from the Website);
        (f) frame or otherwise simulate the appearance or functions of the
        Services or the Website (or any portion thereof); (g) remove or alter
        any copyright, trademark and other proprietary notices and legends
        contained in the Materials or the Services or on the Website (and any
        printouts thereof); (h) give the impression that your communications
        emanate from or are endorsed by us or any other person or entity if that
        is not the case. <br />
        <br />
        The foregoing permission is for the sole purpose of enabling you to use
        the Services, Website and enjoy the benefit of the Materials in the
        manner permitted by these Terms of Use. We may modify the Materials from
        time to time and you are solely responsible for ensuring that you are
        using current and permitted Materials only. The foregoing permission
        terminates automatically if you breach any of these Terms of Use. Upon
        termination, you must immediately destroy any and all downloaded,
        archived or printed copies of the Materials and, if requested by us,
        certify to us in writing that you have complied with the foregoing.{" "}
        <br />
        <br />
        <strong>Violations</strong> <br />
        ReplyAssist has the right, in its sole discretion, to terminate your use
        and access, or the use and access of any Authorized User, of the
        Services, the Website and any Materials in any and all appropriate
        circumstances, such as your violation of these Terms of Use. <br />
        <br />
        <strong>Disclaimer of Endorsement</strong> <br />
        Any references to business or entities, products, processes, or
        services, or other organizations or individuals that are included in the
        Services or on the Website by trade name, trademark or otherwise are
        provided solely for your information. These references are not intended
        to reflect the opinions of ReplyAssist concerning the significance,
        priority or importance to be given the referenced business, entity,
        product, service, organization or individual. Such references, including
        any that may relate to our Materials, are not endorsements or approvals
        by ReplyAssist, and should not be quoted or reproduced for the purpose
        of stating or implying endorsement or approval by ReplyAssist.
        ReplyAssist does not endorse or update statements on third-party
        websites. Furthermore, ReplyAssist is not responsible for content
        provided by third-party websites, including those to which you may be
        redirected. LinkedIn is a registered trademark of LinkedIn Corporation
        (“LinkedIn”). ReplyAssist is not in any way affiliated with LinkedIn and
        neither the Website nor the Services are endorsed, administered, or
        sponsored by LinkedIn. <br />
        <br />
        <strong>User-Generated Content</strong> <br />
        You may from time to time be permitted to post content on the Services
        or the Website, submit emails, or otherwise provide content to us in
        connection with your use of the Website or Materials (“User-Generated
        Content”). Any communication or material you post or transmit to us over
        the Internet is and will be treated as non-confidential and
        non-proprietary and is and will be solely your responsibility. This does
        not include User-Generated Content collected by us or provided to us by
        way of our paid products and services that are governed by the
        subscription agreement. Upon the transmission of any User-Generated
        Content to us, you expressly and hereby grant us a perpetual, worldwide,
        royalty-free, irrevocable, transferable, sublicensable and non-exclusive
        permission to reproduce, display, publish, modify, distribute,
        disseminate and/or otherwise use or exploit such content for any lawful
        purpose, without compensation, notice or obligation to you, subject to
        our Privacy Policy. We are not required to use or return any
        User-Generated Content submitted to us. You represent and warrant that
        you own or otherwise control all rights, including, but not limited to,
        copyrights, in and to any such User-Generated Content, that all moral
        rights in and to any such User-Generated Content have been waived, and
        that the use of such User-Generated Content as permitted hereunder does
        not and will not infringe or violate the rights of any third party. All
        derivative works or other developments arising from or based on
        User-Generated Content, if created by us, shall be our exclusive
        property. The posting or transmittal of any unlawful, threatening,
        libelous, defamatory, obscene, pornographic or profane material or any
        material that could constitute or encourage conduct that would be
        considered a criminal offense or violation of any law is strictly
        prohibited. Under no circumstances shall we be liable for any such
        material posted or transmitted by you by or through the Services or the
        Website. We reserve the right to monitor your communications with us
        whether by mail, voice, fax, email or any other form of transmission for
        the purposes of quality control, security and other business needs.{" "}
        <br />
        <br />
        <strong>Feedback</strong> <br />
        You may provide us with suggestions, comments or other feedback
        (collectively, “Feedback”) with respect to our products, services,
        content, this website, or other materials. Feedback is voluntary. We are
        not obligated to hold it in confidence. We own all Feedback and may use
        Feedback for any purpose without obligation of any kind. To the extent a
        license is required to make use of any intellectual property in any such
        Feedback, you hereby grant us an irrevocable, non-exclusive, perpetual,
        royalty-free license to use the Feedback in connection with
        ReplyAssist’s business, including the enhancement of our products,
        services, content, this website, or other materials. <br />
        <br />
        <strong>Statements on Website</strong> <br />
        The documents contained on (or directly accessible from) the Services or
        the Website may contain a number of forward-looking statements. Any
        statements that are not statements of historical fact (including,
        without limitation, statements to the effect that ReplyAssist or its
        management “believes,” “expects,” “anticipates” “plans” and other
        similar expressions) should be considered forward-looking statements and
        should not be relied upon. There are a number of important factors that
        could cause ReplyAssist’s actual results to differ materially from those
        indicated by such forward-looking statements, including general economic
        conditions, our continued ability to develop and introduce products, the
        introduction of new products by competitors, pricing practices of
        competitors, expansion of its sales distribution capability, the cost
        and availability of components, undetected software errors or bugs, our
        ability to control costs and other risk factors. ReplyAssist has no
        obligation to update or alter forward-looking statements. ReplyAssist is
        not responsible for the content accessible via this Website, or any
        website with which it is linked, including the accuracy, completeness or
        reliability of historical data. <br />
        <br />
        <strong>No Warranties for Website or Materials</strong> <br />
        YOU EXPRESSLY AGREE THAT THE USE OF THE WEBSITE IS AT YOUR OWN RISK. WE
        MAKE NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THIS WEBSITE OR
        THE MATERIALS, WHICH ARE PROVIDED “AS IS.” WE DISCLAIM ALL WARRANTIES,
        EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
        WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, QUALITY AND
        FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO THIS WEBSITE, THE
        MATERIALS AND ANY WEBSITE WITH WHICH THIS WEBSITE IS LINKED. YOU ARE
        SOLELY RESPONSIBLE FOR PROVIDING, MAINTAINING AND ENSURING THE
        COMPATIBILITY OF ALL HARDWARE, SOFTWARE AND OTHER COMPUTER REQUIREMENTS
        NECESSARY FOR YOUR ACCESS TO AND USE OF THE WEBSITE AND THE MATERIALS.
        WE ALSO MAKE NO REPRESENTATIONS OR WARRANTIES AS TO WHETHER (A) THE
        INFORMATION ACCESSIBLE VIA THIS WEBSITE, OR ANY WEBSITE WITH WHICH IT IS
        LINKED, IS ACCURATE, RELIABLE, COMPLETE, OR CURRENT, (B) USE OF THE
        WEBSITE AND ACCESS TO THE MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE,
        (C) THE WEBSITE AND THE MATERIALS WILL BE AVAILABLE AT ANY PARTICULAR
        TIME, OR (D) THE WEBSITE AND THE MATERIALS ARE FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS. NEITHER WE NOR ANY THIRD-PARTY LICENSOR WILL BE
        LIABLE WITH RESPECT TO ANY DECISIONS MADE BY YOU OR ANY OTHER PERSON AS
        A RESULT OF RELIANCE ON THE WEBSITE OR ANY MATERIALS. BECAUSE SOME
        JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF CERTAIN WARRANTIES, THESE
        EXCLUSIONS MAY NOT APPLY TO YOU. YOU ACCEPT THAT OUR SHAREHOLDERS,
        OWNERS, OFFICERS, DIRECTORS, EMPLOYEES AND OTHER REPRESENTATIVES SHALL
        HAVE THE BENEFIT OF THIS CLAUSE. <br />
        <br />
        Your use of the Website and the Materials is at your sole risk. It is
        your responsibility to evaluate (or consider seeking professional advice
        on) the accuracy and completeness of all information, statements,
        opinions and other material on this Website or any website with which it
        is linked. Your statutory rights as a consumer, if any, are not affected
        by these provisions, and we do not seek to exclude or limit liability
        for fraudulent misrepresentation. <br />
        <br />
        <strong>Exclusion of Liability</strong> <br />
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER WE NOR ANY OF
        OUR SHAREHOLDERS, OFFICERS, DIRECTORS, REPRESENTATIVES, EMPLOYEES,
        AGENTS, THIRD-PARTY LICENSORS, SUPPLIERS, AND CONTRACTORS SHALL BE
        LIABLE FOR ANY LOSSES AND/OR EXPENSES OF WHATEVER NATURE, HOWSOEVER
        ARISING AND REGARDLESS OF THE LEGAL THEORY OF LIABILITY, INCLUDING,
        WITHOUT LIMITATION, ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL
        OR CONSEQUENTIAL DAMAGES, LOSS OF OR INABILITY TO USE, LOSS OF DATA,
        LOSS CAUSED BY A VIRUS, LOSS OF INCOME OR PROFIT, LOSS OF OR DAMAGE TO
        PROPERTY, CLAIMS OF THIRD PARTIES, OR OTHER LOSSES OF ANY KIND OR
        CHARACTER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES, EXPENSES OR LOSSES AND EVEN IF AN EXCLUSIVE REMEDY PROVIDED FOR
        HEREIN FAILS OF ITS ESSENTIAL PURPOSE, ARISING OUT OF OR IN CONNECTION
        WITH YOUR ACCESS OR USE OF, OR INABILITY TO USE, THIS WEBSITE OR THE
        MATERIALS OR ANY WEBSITE WITH WHICH THIS WEBSITE IS LINKED. IF YOU ARE
        DISSATISFIED WITH THE WEBSITE OR ANY MATERIALS, YOUR SOLE AND EXCLUSIVE
        REMEDY SHALL BE TO DISCONTINUE USE OF THE WEBSITE AND MATERIALS AND
        TERMINATE THESE TERMS OF USE IN ACCORDANCE WITH ITS TERMS. TO THE
        MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL REPLYASSIST’S OR ANY
        THIRD-PARTY LICENSOR’S TOTAL LIABILITY TO YOU FOR DAMAGES, LOSSES AND
        CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED
        TO, NEGLIGENCE OR OTHERWISE) EXCEED IN THE AGGREGATE AMOUNT PAID BY YOU,
        IF ANY, FOR ACCESSING THE WEBSITE OR MATERIALS. <br />
        <br />
        <strong>Warranties and Liability Exclusions for Services</strong> <br />
        THE TERMS OF ANY WARRANTY AND LIABILITY ARE SET OUT IN THE TERMS AND
        CONDITIONS ASSOCIATED WITH THE SERVICES. IF NO TERMS AND CONDITIONS ARE
        AGREED UPON BY THE PARTIES, ALL OF THE FOREGOING APPLY TO THE SERVICES.{" "}
        <br />
        <br />
        <strong>Indemnity and Release</strong> <br />
        You agree to defend, indemnify, release and hold us harmless as well as
        our shareholders, officers, directors, agents, representatives,
        employees and third-party licensors from any and all claims,
        liabilities, losses, expenses or demands, including reasonable legal
        fees, based on, arising from, or otherwise related to: (a) your breach
        or violation of these Terms of Use; (b) your access or use of this
        Website or any of the information, materials, products or services made
        available on or through this Website; (c) any infringement or
        misappropriation by you of any intellectual property or other rights of
        ReplyAssist or any third party; or (d) any negligence or willful
        misconduct by you. <br />
        <br />
        <strong>Modification and Discontinuation</strong> <br />
        We reserve the right at any time and from time to time to modify, edit,
        delete, suspend or discontinue, temporarily or permanently, this Website
        (or any portion thereof) and/or the information, materials, products
        and/or services made available on or through this Website (or any part
        thereof) with or without notice. You agree that we shall not be liable
        to you or to any third party for any such modification, editing,
        deletion, suspension or discontinuance of this Website. <br />
        <br />
        <strong>Termination and Survival</strong> <br />
        These Terms of Use will remain in effect until terminated by you or us.
        You may terminate these Terms of Use at any time by ceasing your use and
        access of the Website and Materials and by destroying all materials that
        you have saved, printed or downloaded from the Website. Notwithstanding
        any of the terms and conditions contained herein, we reserve the right,
        without notice and at our sole discretion, to terminate your permission
        to use the Website, and to block or prevent future access to and use of
        the Website and Materials. The provisions of these Terms of Use that by
        their nature and content are intended to survive the expiration or
        termination of these Terms of Use will survive such termination or
        expiration. <br />
        <br />
        <strong>Entire Understanding</strong> <br />
        These Terms of Use contain the entire understanding between you and us
        with respect to this Website and your use and access hereof and
        supersedes all representations, statements, inducements, understandings,
        arrangements and agreements, oral or written, between you and us
        relating thereto unless otherwise signed in writing by authorized
        representatives of the parties, provided should there be a conflict
        between these Terms of Use and any written agreement between you and
        ReplyAssist related to your use of the Website, the terms of such
        written agreement shall control. Should any part of these Terms of Use
        for any reason be declared invalid, void or unenforceable by a court of
        competent jurisdiction, such determination shall not affect the validity
        of any remaining portions and such remaining portions shall remain and
        continue in full force and effect to the maximum extent permitted by
        law. Our failure to enforce any provision of these Terms of Use shall
        not operate as or be deemed a waiver by us of any other breach of any
        such provision or any other provision herein or of the right to enforce
        such provision(s). <br />
        <br />
        <strong>Trade Compliance Restrictions</strong> <br />
        The sale, resale or other disposition of ReplyAssist’s products and any
        related technology or documentation are subject to various economic
        sanctions, export control laws, and other restrictive trade measures
        administered by the U.S. and other applicable governments. Because these
        laws can have extraterritorial effect, you agree that you will comply
        with all such measures where applicable including, without limitation,
        (1) the Export Administration Act of 1979, as amended (50 U.S.C. app.
        2401-2420) and the Export Administration Regulations, 15 C.F.R. §§
        730-774 (“EAR”); (2) the Arms Export Control Act, 22 U.S.C. § 2778, and
        the corresponding International Traffic in Arms Regulations (“ITAR”);
        (3) the economic sanctions laws and regulations enforced by the U.S.
        Department of the Treasury’s Office of Foreign Assets Control (“OFAC”),
        31 C.F.R. Part 500 et seq., and the U.S. Department of State; and (iv)
        the anti-boycott regulations, guidelines, and reporting requirements
        under the Export Administration Regulations and Section 999 of the
        Internal Revenue Service Code. You understand and acknowledge that you
        are solely responsible for complying with such laws whenever applicable.
        You further understand and acknowledge that you shall not directly or
        indirectly export, import, sell, disclose, or otherwise transfer any
        ReplyAssist products or any related technology or documentation to any
        country or party subject to such restrictions, and that it is solely
        responsible for obtaining any license(s) to export, re-export or import
        that may be required. <br />
        <br />
        <strong>Applicable Law & Jurisdiction</strong> <br />
        While ReplyAssist provides global products and services, our operations
        are located in Illinois with AWS data centers geographically spread
        worldwide, and these Terms of Use are based on United States law. Access
        to, or use of, this Website or any information, materials, products
        and/or services made available on or through this Website may be
        prohibited by law in certain countries or jurisdictions. You are solely
        responsible for ensuring compliance with any applicable laws of the
        country from which you are accessing this Website. We make no
        representation that the information contained herein is appropriate or
        available for use in any location. You agree that these Terms of Use are
        made and partially performed in the State of Illinois and that the state
        and district courts located within or closest to Cook County, Illinois
        without giving effect to any principles of conflicts of law, shall have
        exclusive jurisdiction to resolve any controversy or claim of whatever
        nature arising out of or relating to your access and use of this
        Website, and that the laws of the State of Illinois shall govern such
        dispute or claim. You hereby consent and submit to the exercise of
        personal jurisdiction over you by such courts for the purposes of
        litigating any such action and waive any defenses of lack of minimum
        contacts or forum non conveniens. Notwithstanding the foregoing, we
        reserve the right to bring legal proceedings in any jurisdiction where
        we believe that infringement of these Terms of Use may be taking place
        or originating. The rights and remedies provided by these Terms of Use
        are cumulative and the use of any right or remedy by us shall not
        preclude or waive our rights to use any or all other remedies. <br />
        <br />
        <strong>Contacting Us</strong> <br />
        If you have any questions regarding these Terms of Use or any other
        matter, you may contact us in by writing to us at: ReplyAssist, Inc.,
        2045 W. Grand Ave, Suite B, PMB 414451, Chicago, IL 60612-1577 or by
        emailing info@replyassist.com. All notices pertaining to contractual or
        legal matters (i.e. breach of contract, termination, indemnifiable
        claims, etc.) must clearly be identified and marked as Legal Notices.
        Any rights not expressly granted herein are reserved.
      </p>
    ),
  },
];

export default legal_agreements;
