import React from "react";
import { useEffect, useRef } from "react";
import styles from "./fine_tuning.module.css";
import { useState, useContext } from "react";
import { ChatbotGuidelinesContext } from "../../../context/ChatbotGuidelinesContext";
import { DynamicFormField } from "../../portal_components/universal_components/DynamicFormField/DynamicFormField";
import { ChatbotTuningContext } from "../../../context/ChatbotTuningContext";
import CircularLoader from "../../portal_components/universal_components/CircularLoader/CircularLoader";
import { toast } from "react-toastify";

export default function CreateFineTuningPage(props) {
  const { prompts } = useContext(ChatbotGuidelinesContext);

  const { trainingData, createModel } = useContext(ChatbotTuningContext);

  const [deletePopup, setDeletePopup] = useState(false);

  const [file, setFile] = useState(null);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setIsLoadingDocument(true);

      const documentData = new FormData();
      documentData.append("file", selectedFile);
      documentData.append("type", "Public");

      try {
        // Perform your updateField or upload logic here
        updateField("manual", {
          name: selectedFile.name,
          file: selectedFile.content,
        });
      } catch (error) {
        toast.error(
          "Something went wrong with the document upload, please try again"
        );
      } finally {
        setIsLoadingDocument(false);
      }
    }
  };

  const modelNames = [
    "gpt-3.5-turbo-0125", // new
    "gpt-3.5-turbo-1106", // recommended
    "gpt-3.5-turbo-0613",
    "babbage-002",
    "davinci-002",
    "gpt-4-0613", // experimental
  ];

  const [fineTuneRequest, setFineTuneRequest] = useState({
    model: "",
    promptVersion: "",
    training_file: "",
    validation_file: null,
    suffix: "",
    hyperparameters: {
      n_epochs: null,
      batch_size: null,
      learning_rate_multiplier: null,
    },
  });

  const updateField = (field, value) => {
    if (field in fineTuneRequest) {
      setFineTuneRequest({ ...fineTuneRequest, [field]: value });
    } else if (field in fineTuneRequest.hyperparameters) {
      setFineTuneRequest({
        ...fineTuneRequest,
        hyperparameters: { ...fineTuneRequest.hyperparameters, [field]: value },
      });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const canCreate =
    fineTuneRequest.model !== "" &&
    fineTuneRequest.promptVersion !== "" &&
    fineTuneRequest.training_file !== "" &&
    fineTuneRequest.suffix !== "";

  const [isCreating, setIsCreating] = useState(false);

  const handleSave = async () => {
    if (!canCreate) {
      return;
    }

    setIsCreating(true);

    try {
      await createModel(fineTuneRequest);
      props.back();
    } catch (error) {
      console.error("Error creating fine-tuned model:", error);
    } finally {
      setIsCreating(false);
    }
  };

  const chatbotMenuRef = useRef();
  const handleClickOutside = (event) => {
    if (
      chatbotMenuRef.current &&
      !chatbotMenuRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.edit_guideline_modal}>
      <div className={styles.back_box}></div>
      <div className={styles.form_field_container_margin}>
        <DynamicFormField
          title="Name"
          name="suffix"
          content={fineTuneRequest.suffix}
          onValueChange={(value) => updateField("suffix", value)}
        />
      </div>
      <div className={styles.section_divider}></div>
      <div className={styles.form_field_container}>
        <DynamicFormField
          title="Model"
          name="model"
          dropdown={true}
          dropDownData={modelNames}
          content={fineTuneRequest.model}
          onValueChange={(value) => updateField("model", value)}
        />
        <DynamicFormField
          title="Prompt Version"
          name="promptVersion"
          dropdown={true}
          dropDownData={prompts.SystemPromptsFineTuning.versions}
          customDropdown={true}
          content={fineTuneRequest.promptVersion}
          onValueChange={(value) => updateField("promptVersion", value)}
        />
      </div>
      <div className={styles.section_divider}></div>
      <div className={styles.form_field_container}>
        <DynamicFormField
          title="Training Data"
          name="training_file"
          dropdown={true}
          dropDownData={trainingData}
          customDropdown={true}
          trainingData={true}
          content={fineTuneRequest.training_file}
          onValueChange={(value) => updateField("training_file", value)}
        />
        {/* <DynamicFormField
          title="Validation Data"
          name="validation_file"
          content={fineTuneRequest.validation_file}
          onValueChange={(value) => updateField("validation_file", value)}
        /> */}
      </div>
      <div className={styles.section_divider}></div>

      {/* <div className={styles.form_field_container}>
        <DynamicFormField
          title="Validation Data"
          name="validation_file"
          content={fineTuneRequest.validation_file}
          onValueChange={(value) => updateField("validation_file", value)}
        />
        <UploadValidation />
      </div>
      <div className={styles.section_divider}></div> */}

      <div className={styles.form_field_container}>
        <DynamicFormField
          title="Number of Epochs"
          name="n_epochs"
          numberOnly={true}
          optional={true}
          content={fineTuneRequest.hyperparameters.n_epochs}
          onValueChange={(value) => updateField("n_epochs", value)}
        />
        <DynamicFormField
          title="Batch Size"
          name="batch_size"
          numberOnly={true}
          optional={true}
          content={fineTuneRequest.hyperparameters.batch_size}
          onValueChange={(value) => updateField("batch_size", value)}
        />
        <DynamicFormField
          title="Learning Multiplyer"
          name="learning_rate_multiplier"
          numberOnly={true}
          optional={true}
          content={fineTuneRequest.hyperparameters.learning_rate_multiplier}
          onValueChange={(value) =>
            updateField("learning_rate_multiplier", value)
          }
        />
      </div>
      <div className={styles.section_divider}></div>
      <div className={styles.modal_footer}>
        <div className={styles.buttons}>
          <div className={styles.cancel_button} onClick={props.back}>
            Cancel
          </div>
          {isCreating ? (
            <div className={styles.save_button_loading}>
              <CircularLoader size="25px" color="rgba(0, 61, 130, 1)" />
            </div>
          ) : (
            <>
              {canCreate ? (
                <div className={styles.save_button} onClick={handleSave}>
                  Create Model
                </div>
              ) : (
                <div className={styles.save_button_disabled}>Create Model</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );

  function UploadValidation() {
    return (
      <div>
        <div>
          {isLoadingDocument ? (
            <div className={styles.document_layout}>
              <CircularLoader />
            </div>
          ) : (
            <div className={styles.document_layout}>
              {!file ? (
                <div className={styles.drag_image}>
                  <div>
                    <div className={styles.audioDropzone}>
                      <img
                        src="/fileUpload.png"
                        alt="upload"
                        className={styles.drag_track_image}
                      ></img>
                      <p className={styles.drag_track_text}>
                        Drag or Upload Document
                      </p>
                      <p className={styles.drag_track_text_browse}>
                        Browse Files
                      </p>

                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        onChange={onFileChange}
                        disabled={isLoadingDocument}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.chosen_document}>
                  <DisplayDocument
                    file={file}
                    setDeletePopup={(data) => setDeletePopup(data)}
                    desc={"Are you sure you want to delete?"}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  function DisplayDocument(props) {
    const [isReferanceHovered, setIsReferanceHovered] = useState(false);
    const { fileName, setDeletePopup, setDeleteName, type, file } = props;

    function openDocument() {
      window.open(file, "_blank").focus();
    }

    return (
      <div
        className={
          isReferanceHovered
            ? `${styles.document_display} ${styles.hovered}`
            : styles.document_display
        }
      >
        <img
          src="/deleteOptionEx.png"
          alt="Check"
          className={styles.delete_icon_corner}
          onMouseEnter={() => setIsReferanceHovered(true)}
          onMouseLeave={() => setIsReferanceHovered(false)}
          onClick={() => {
            setDeletePopup(true);
            setDeleteName(fileName);
          }}
        />
        <p className={styles.document_file_title} onClick={openDocument}>
          {fileName}
        </p>
      </div>
    );
  }
}
