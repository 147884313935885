import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import FAQComponents from "../../components/landing_components/faq_page/faq_page";
import TWOFA from "../../components/landing_components/2faPage/2fa";

const TwoFA = () => {
  return (
    <>
      <LandingNavbar childComponent={<TWOFA />} activeText="TwoFA" />
    </>
  );
};

export default TwoFA;
