import React from "react";
import { useEffect } from "react";
import styles from "./settings_tabs.module.css";
import { useState, useContext } from "react";
import { ChatbotGuidelinesContext } from "../../../../context/ChatbotGuidelinesContext";
import SearchBarHeader from "../../../portal_components/universal_components/search_bar_header";
import { AdminContext } from "../../../../context/AdminContext";

const CustomChatbotSettings = () => {
  const { customChatbots } = useContext(AdminContext);
  //   console.log();
  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Custom Chatbots (2)</p>
      <div className={styles.divider}></div>
      {customChatbots.map((chatbot) => (
        <>
          <div className={styles.global_guideline}>
            <p className={styles.global_title}>{chatbot.fine_tuned_model}</p>
          </div>
          <div className={styles.divider}></div>
        </>
      ))}
    </div>
  );
};

export default CustomChatbotSettings;
