import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import LandingPage from "../../components/landing_components/landing_page/landing_page";
import ContactSalesComponents from "../../components/landing_components/ContactSales/ContactSales";

const ContactSalesPage = () => {
  return (
    <div>
      <LandingNavbar
        childComponent={<ContactSalesComponents />}
        activeText="Contact Sales"
      />
    </div>
  );
};

export default ContactSalesPage;
