import React from "react";
import { useEffect } from "react";
import styles from "./fine_tuning.module.css";
import { useState, useContext } from "react";
import { ChatbotGuidelinesContext } from "../../../context/ChatbotGuidelinesContext";
import { ChatbotTuningContext } from "../../../context/ChatbotTuningContext";
import SearchBarHeader from "../../portal_components/universal_components/search_bar_header";
import CreateFineTuningPage from "./CreateFineTuningPage";
import CircularLoader from "../../portal_components/universal_components/CircularLoader/CircularLoader";
import { useNavigate } from "react-router";

const FineTuningPage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedGuideline, setSelectedGuideline] = useState(null);
  const { fineTunedModels } = useContext(ChatbotTuningContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredGuidelines, setFilteredGuidelines] = useState(fineTunedModels);

  // useEffect(() => {
  //   const filtered = fineTunedModels.filter((model) =>
  //     model.id.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredGuidelines(filtered);
  // }, [searchTerm, fineTunedModels]);

  function handleModalNav(selectedGuideline) {
    setSelectedGuideline(selectedGuideline);
    setIsEditing(!isEditing);
  }

  function back() {
    setIsEditing(!isEditing);
    setSelectedGuideline(null);
  }

  const handleAdd = () => {
    setSelectedGuideline({
      intent: "",
      page: "",
      guidelines: "",
    });
    setIsEditing(true);
  };

  return (
    <div className={styles.main_layout}>
      {!isEditing ? (
        <p className={styles.page_title_margin}>Fine Tuning</p>
      ) : (
        <div className={styles.title_area_create}>
          <div className={styles.back_button_small} onClick={back}>
            <img
              className={styles.back_icon_opacity}
              src={"/images/BackIcon.png"}
              alt="back"
            ></img>
          </div>
          <p className={styles.page_title}>Create New Model</p>
        </div>
      )}
      <div className={styles.edit_guidelinees_modal}>
        {!isEditing ? (
          <>
            <div className={styles.guideline_controls}>
              <SearchBarHeader
                type="EditChatbot"
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <div className={styles.guideline_add} onClick={handleAdd}>
                <img
                  src="/add.png"
                  className={styles.add_guideline}
                  alt="add"
                ></img>
              </div>
            </div>
            <div className={styles.divider}></div>

            {fineTunedModels && fineTunedModels.length !== 0 && (
              <div className={styles.guideline_layout}>
                {fineTunedModels.map((model, index) => {
                  return <FineTunedModel model={model} index={index} />;
                })}
              </div>
            )}
            <div className={styles.bottom_section_divider}></div>
          </>
        ) : (
          <CreateFineTuningPage
            selectedGuideline={selectedGuideline}
            back={back}
          />
        )}
      </div>
    </div>
  );
};

function FineTunedModel(props) {
  const navigate = useNavigate();

  const model = props.model;
  console.log("MODEL", model);

  async function openTrainingData() {
    navigate(`/admin/training-data/${model.training_file}`);
  }

  return (
    <div className={styles.fine_tune_model} key={props.index}>
      <div className={styles.model_information_top}>
        <div className={styles.model_information}>
          <div className={styles.model_info_section}>
            <p className={styles.model_section_title}>Model</p>
            {/* <p className={styles.title}>{model.fine_tuned_model}</p> */}
            <p className={styles.model_section_text}>
              {model.fine_tuned_model
                ? model.fine_tuned_model
                : "Model Name Unavailable"}
            </p>
            <p className={styles.model_section_text}>
              Base Model: {model.model}
            </p>
            <p className={styles.model_section_text}>
              Trained Tokens:{" "}
              {model.fine_tuned_model ? model.trained_tokens : "N/A"}
            </p>
          </div>
          <div className={styles.parmater_info_section}>
            <p className={styles.model_section_title}>Hyper Parameters</p>
            <p className={styles.model_section_text}>
              Epoch:{" "}
              {model.hyperparameters && model.hyperparameters.n_epochs
                ? model.hyperparameters.n_epochs
                : "N/A"}
            </p>
            <p className={styles.model_section_text}>
              Batch Size:{" "}
              {model.hyperparameters && model.hyperparameters.batch_size
                ? model.hyperparameters.batch_size
                : "N/A"}
            </p>
            <p className={styles.model_section_text}>
              Learning Rate:{" "}
              {model.hyperparameters &&
              model.hyperparameters.learning_rate_multiplier
                ? model.hyperparameters.learning_rate_multiplier
                : "N/A"}
            </p>
          </div>
        </div>
        <div className={styles.top_left}>
          {model.status === "succeeded" && (
            <div className={styles.status_box_success}>Succeded</div>
          )}
          {model.status === "failed" && (
            <div className={styles.status_box_failed}>Failed</div>
          )}
          {model.status !== "failed" && model.status !== "succeeded" && (
            <div className={styles.status_box_building}>
              Building Model <CircularLoader size="12px" />
            </div>
          )}
        </div>
      </div>
      <div className={styles.model_information_bottom}>
        <div className={styles.view_information_buttons}>
          {model.training_file ? (
            <div className={styles.training_data} onClick={openTrainingData}>
              View Training Data
            </div>
          ) : (
            <div className={styles.training_data_disabled}>View Training Data</div>
          )}
          {model.validation_file ? (
            <div className={styles.training_data}>View Validation Data</div>
          ) : (
            <div className={styles.training_data_disabled}>View Validation Data</div>
          )}
        </div>
        {model.created_at ? (
          <p className={styles.model_created_at}>
            Created: {formatTimestamp(model.created_at)}
          </p>
        ) : (
          <p className={styles.model_created_at}>Creating Model</p>
        )}
      </div>
    </div>
  );
}

function formatTimestamp(timestamp) {
  try {
    // Convert the timestamp from seconds to milliseconds
    const date = new Date(parseInt(timestamp, 10) * 1000);

    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString(undefined, options);
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}

export default FineTuningPage;
