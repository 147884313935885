const main_menu = [
  {
    id: 1,
    title: "Admin",
    icon_image: "/images/SidebarIcons/SidebarAdminIcon.svg",
    iconKey: "stroke",
    url: "/admin",
    isSelf: true,
  },
  {
    id: 2,
    title: "Dashboard",
    icon_image: "/images/SidebarIcons/SidebarDashboardIcon.svg",
    iconKey: "stroke",
    url: "/dashboard",
    isSelf: true,
  },
  {
    id: 3,
    title: "Inbox",
    icon_image: "/images/SidebarIcons/SidebarInboxIcon.svg",
    iconKey: "stroke",
    url: "/inbox",
    isSelf: true,
  },
  {
    id: 4,
    title: "Follow Ups",
    icon_image: "/images/SidebarIcons/FollowUpsIcon.svg",
    iconKey: "stroke",
    url: "/followups",
    isSelf: true,
  },
  {
    id: 5,
    title: "Deal Stages",
    icon_image: "/images/SidebarIcons/SidebarUpcomingIcon.svg",
    iconKey: "stroke",
    url: "/dealstages",
    isSelf: true,
  },
  {
    id: 6,
    title: "Prospects",
    icon_image: "/images/SidebarIcons/SidebarContactsIcon.svg",
    iconKey: "stroke",
    url: "/prospects",
    isSelf: true,
  },

  {
    id: 7,
    title: "Settings",
    icon_image: "/images/SidebarIcons/SidebarSettingsIcon.svg",
    iconKey: "stroke",
    url: "/settings",
    isSelf: true,
  },
  {
    id: 8,
    title: "Feedback",
    icon_image: "/images/SidebarIcons/SidebarSupportIcon.svg",
    iconKey: "stroke",
    url: "/feedback",
    isSelf: true,
  },
];

export default main_menu;

// {
//   id: 2,
//   title: "Campaigns",
//   icon_image: "/images/SidebarIcons/CampaignsIcon.svg",
//   iconKey: "stroke",
//   url: "/campaigns",
//   isSelf: true,
// },
// {
//   id: 7,
//   title: "Integrations",
//   icon_image: "/images/SidebarIcons/SidebarIntegrationsIcon.svg",
//   iconKey: "stroke",
//   url: "/integrations",
//   isSelf: true,
// },
// {
//   id: 7,
//   title: "Chatbot Testing",
//   icon_image: "/images/SidebarIcons/ChatbotTestingIcon.svg",
//   iconKey: "stroke",
//   url: "/chatbottesting",
//   isSelf: true,
// },
// {
//   id: 8,
//   title: "Feedback",
//   icon_image: "/images/SidebarIcons/SidebarSupportIcon.svg",
//   iconKey: "stroke",
//   url: "/feedback",
//   isSelf: true,
// },
