import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import ResourcesComponents from "../../components/landing_components/resourcesPage/ResourcesComponents";

const ResourcesPage = () => {
  return (
    <div>
      <LandingNavbar childComponent={<ResourcesComponents />} activeText="Resources" />
    </div>
  );
};

export default ResourcesPage;
