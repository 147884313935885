import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";

import DealStages from "../../components/portal_components/deal_stages_page/deal_stages";

const DealStagesPage = () => {
  return (
    <>
      <Sidebar childComponent={<DealStages />} activeText="Deal Stages" />
    </>
  );
};

export default DealStagesPage;
