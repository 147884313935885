import React from "react";
import { useEffect, useRef } from "react";
import styles from "./editchatbot.module.css";
import { useState, useContext } from "react";
import { ChatbotGuidelinesContext } from "../../../context/ChatbotGuidelinesContext";

import { toast } from "react-toastify";

export default function EditGuidelinePage(props) {
  const { createNewPrompt, updatePrompt, updateCurrentActivePrompt } =
    useContext(ChatbotGuidelinesContext);

  const promptType = props.promptType;

  const selectedGuideline = props.selectedGuideline;

  const [workingPrompt, setWorkingPrompt] = useState(props.activePrompt);

  const handleChange = (field, value) => {
    setWorkingPrompt((prev) => ({ ...prev, [field]: value }));
  };

  const isGlobal = true;
  const [isOpen, setIsOpen] = useState(false);

  function createNewVersion() {
    setWorkingPrompt((prev) => ({
      documentId: "NEW",
      guidelines: "",
      version_title: "",
      temperature: 1,
      lastUpdated: Date.now(),
    }));
  }

  function switchVersions(version) {
    setWorkingPrompt(version);
    updateCurrentActivePrompt(version, promptType);
  }

  const saveGuideline = async () => {
    try {
      workingPrompt.lastUpdated = Date.now();
      await updatePrompt(workingPrompt, promptType);
      toast.success("Prompt Saved Successfully");
    } catch (error) {
      toast.error("Error Saving Prompt");
    }
  };

  const createGuideline = async () => {
    if (
      !workingPrompt.version_title ||
      workingPrompt.version_title.trim() === ""
    ) {
      toast.error("Prompt Title Must Not Be Empty");
      return;
    }

    if (!workingPrompt.guidelines || workingPrompt.guidelines.trim() === "") {
      toast.error("System Prompt Must Not Be Empty");
      return;
    }

    workingPrompt.lastUpdated = Date.now();
    try {
      return await createNewPrompt(workingPrompt, promptType);
    } catch (error) {
      toast.error("Failed to Create New Prompt");
      return null;
    }
  };

  const handleSave = async () => {
    if (workingPrompt.documentId === "NEW") {
      const newId = await createGuideline();
      if (newId) {
        workingPrompt.documentId = newId;
        updateCurrentActivePrompt(workingPrompt, promptType);
        toast.success(`Created ${workingPrompt.version_title}`);
      }
    } else {
      await saveGuideline();
    }
  };

  const [hover, setHover] = useState(false);

  const chatbotMenuRef = useRef();
  const handleClickOutside = (event) => {
    if (
      chatbotMenuRef.current &&
      !chatbotMenuRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.edit_guideline_modal}>
      <div className={styles.back_box}>
        <div
          className={styles.back_button}
          onClick={props.back}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          <img
            className={styles.back_icon}
            src={hover ? "/images/BackIcon.png" : "/images/BackIconWhite.png"}
            alt="back"
          ></img>
          <p className={styles.back_text}>Back</p>
        </div>
        <div
          className={styles.global_guidelines_dropdown}
          onClick={() => setIsOpen(!isOpen)}
          ref={chatbotMenuRef}
        >
          {workingPrompt?.version_title ? (
            <p>{workingPrompt?.version_title}</p>
          ) : (
            <p>New Version</p>
          )}

          <img
            src="/DropDownIcon.png"
            alt="dropdown"
            className={`${styles.dropdown_icon} ${
              isOpen ? styles.rotate_180 : ""
            }`}
          ></img>
          {isOpen && (
            <ul className={styles.dropdown_menu}>
              {selectedGuideline.versions.map((version, index) => (
                <div
                  key={index}
                  className={styles.dropdown_menu_item}
                  onClick={() => switchVersions(version)}
                >
                  <p className={styles.dropdown_menu_text_left}>
                    {version.version_title}
                  </p>
                  <p className={styles.dropdown_menu_text_right}>
                    {formatTimestamp(version.lastUpdated)}
                  </p>
                </div>
              ))}
            </ul>
          )}
        </div>

        <div className={styles.temp_controls}>
          <p className={styles.back_text}>
            Temperature: {workingPrompt.temperature}
          </p>
          <input
            type="range"
            min="0"
            max="2"
            step="0.01"
            value={workingPrompt.temperature}
            onChange={(e) => handleChange("temperature", e.target.value)}
            className={styles.slider}
          />
        </div>

        {/* <div className={styles.set_as_live}>
          <p className={styles.back_text}>Deploy Live</p>
        </div> */}
      </div>
      {!isGlobal && (
        <>
          <div className={styles.edit_guidline_info}>
            <input
              className={styles.edit_guideline_text}
              value={workingPrompt.intent}
              placeholder="Intent Title"
              onChange={(e) => handleChange("intent", e.target.value)}
            />
          </div>
          <div className={styles.edit_guidline_info}>
            <input
              className={styles.edit_guideline_text}
              value={workingPrompt.page}
              placeholder="Page Title"
              onChange={(e) => handleChange("page", e.target.value)}
            />
          </div>
        </>
      )}
      {isGlobal && (
        <div className={styles.version_title_info}>
          <p className={styles.version_title_word}>Version Title:</p>
          <input
            className={styles.version_title}
            value={workingPrompt.version_title}
            placeholder="Name"
            onChange={(e) => handleChange("version_title", e.target.value)}
          />
        </div>
      )}
      <div className={styles.edit_guidline}>
        <textarea
          className={styles.edit_guideline_text_area}
          value={workingPrompt.guidelines}
          placeholder="System Prompt"
          onChange={(e) => handleChange("guidelines", e.target.value)}
        />
      </div>
      <div className={styles.modal_footer}>
        <p className={styles.last_updated}>
          Last Updated: {formatTimestamp(workingPrompt.lastUpdated)}
        </p>
        {/* <div className={styles.cancel_button} onClick={props.back}>
          Cancel
        </div> */}
        <div className={styles.buttons}>
          {isGlobal && (
            <>
              {workingPrompt.documentId !== "NEW" && (
                <div className={styles.new_version} onClick={createNewVersion}>
                  Create New Version
                </div>
              )}
            </>
          )}
          {workingPrompt.documentId === "NEW" ? (
            <div className={styles.save_button} onClick={handleSave}>
              Create
            </div>
          ) : (
            <div className={styles.save_button} onClick={handleSave}>
              Save
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function formatTimestamp(timestamp) {
  try {
    const date = new Date(parseInt(timestamp, 10));

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString(undefined, options);
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}
