import React from "react";
import JoinWaitlistPage from "../../components/landing_components/waitlist_page/waitlist";

const WaitlistPage = () => {
  return (
    <>
      <JoinWaitlistPage
        childComponent={<p>Pricing Page</p>}
        activeText="Pricing"
      />
    </>
  );
};

export default WaitlistPage;
