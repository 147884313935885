/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */

import React from "react";
import styles from "./popup.module.css";
import { useState } from "react";
import CircularLoader from "../../../portal_components/universal_components/CircularLoader/CircularLoader";

export function ReplaceTrainingDataPopup(props) {
  const { openModal, closeModal, loading, actionFunction, desc } = props;

  const [file, setFile] = useState(null);

  const canCreate = file !== null;

  if (!openModal) {
    return null;
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const modalStyle = getModalStyle();

  function handleFileUpload(event) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  }

  function deleteFile() {
    setFile(null);
  }

  return (
    <div open={openModal} onClose={closeModal} className={styles.overlay}>
      <div style={modalStyle} className={styles.modalPaper}>
        <div className={styles.modal_header}></div>
        <div className={styles.modal_body}>
          <div className={styles.modal_icon}>
            <img
              className={styles.image_icon}
              src="/overwrite.png"
              alt="overwrite"
            ></img>
          </div>
          <p className={styles.popup_text}> {desc}</p>

          {file ? (
            <div className={styles.input_file_box}>
              <p className={styles.input_file_text}>{file.name}</p>
              <div className={styles.cross_background} onClick={deleteFile}>
                <CrossIcon />
              </div>
            </div>
          ) : (
            <div className={styles.input_file_box}>
              <input
                type="file"
                className={styles.hidden_input_file}
                hidden_input_file
                accept=".jsonl"
                onChange={(e) => handleFileUpload(e)}
              />
              <p className={styles.input_file_text_grayed}>{"Training Data"}</p>
              <p className={styles.browse_files}>Browse Files</p>
            </div>
          )}

          <div className={styles.popup_buttons}>
            <div
              className={styles.cancel_button}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </div>
            {canCreate ? (
              <div
                className={styles.create_button}
                onClick={() => actionFunction(file)}
              >
                {loading ? (
                  <CircularLoader size="24px" color="rgba(255, 255, 255, 1)" />
                ) : (
                  "Overwrite"
                )}
              </div>
            ) : (
              <div className={styles.create_button_disabled}>Overwrite</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
//
function CrossIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18M6 6l12 12"
        className={styles.red_cross_svg}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
