import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

const GeneralSignUp = ({ onNext, onBack }) => {
  const { createAccount } = useContext(AuthContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [error, setError] = useState(null);

  const canContinue =
    firstName !== "" &&
    lastName !== "" &&
    email !== "" &&
    password !== "" &&
    password === rePassword;

  async function handleCreateAccount() {
    try {
      const userCredential = await createAccount(
        email,
        password,
        firstName,
        lastName
      );

      if (userCredential) {
        const userId = userCredential.user.uid;
        onNext(userId);
      } else {
        console.log("Account creation failed.");
      }
    } catch (error) {
      error.message = error.message.replace("Firebase: ", "");
      setError(error.message);
    }
  }

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content_right}>
        <div className={styles.sidebar_right}>
          <p className={styles.page_title}>Create your account</p>
          <div className={styles.two_column_inputs}>
            <div className={styles.half_input_object}>
              <p className={styles.login_input_titles}>First Name</p>
              <div className={styles.email_input}>
                <input
                  onChange={(event) => setFirstName(event.target.value)}
                  className={styles.input}
                ></input>
              </div>
            </div>
            <div className={styles.half_input_object}>
              <p className={styles.login_input_titles}>Last Name</p>
              <div className={styles.email_input}>
                <input
                  onChange={(event) => setLastName(event.target.value)}
                  className={styles.input}
                ></input>
              </div>
            </div>
          </div>
          <p className={styles.login_input_titles}>Email Address</p>
          <div className={styles.email_input}>
            <input
              onChange={(event) => setEmail(event.target.value)}
              className={styles.input}
            ></input>
          </div>
          <p className={styles.login_input_titles}>Password</p>
          <div className={styles.email_input}>
            <input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              className={styles.input}
            ></input>
          </div>
          <p className={styles.login_input_titles}>Confirm Password</p>
          <div className={styles.email_input}>
            <input
              type="password"
              onChange={(event) => setRePassword(event.target.value)}
              className={styles.input}
            ></input>
          </div>
          {error && <p className={styles.error_text}>{error}</p>}
          {canContinue ? (
            <div onClick={handleCreateAccount} className={styles.page_button}>
              <p className={styles.join_button_text}>Create Account</p>
            </div>
          ) : (
            <div className={styles.page_button_inactive}>
              <p className={styles.join_button_text}>Create Account</p>
            </div>
          )}
          <p className={styles.note}>
            Already have an account?{" "}
            <span className={styles.clickable_text}>Sign In</span>
          </p>
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/images/LandingPage.png"
        alt="landing"
      ></img>
    </div>
  );
};

export default GeneralSignUp;
