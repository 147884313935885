import React from "react";
import styles from "./universal.module.css";

function CircularLoader() {
  return (
    <svg viewBox="25 25 50 50" className={styles.circular_loader}>
      <circle
        r="20"
        cy="50"
        cx="50"
        className={styles.circular_loader_circle}
      ></circle>
    </svg>
  );
}

export default CircularLoader;
