import React from "react";
import styles from "./integrations.module.css";
import SearchBarHeader from "../universal_components/search_bar_header";

function Integrations() {
  const integrations = [
    {
      id: "1",
      name: "Calendly",
      type: "CRM",
      summary:
        "Enhance your scheduling with ReplyAssist's integration with Calendly. Eliminate tedious email exchanges and ensure timely responses. Our AI seamlessly syncs with your Calendly slots, automates responses, and intelligently manages time zones.",
      logo_image: "/images/IntegrationLogos/calendly_logo.png",
      color: "rgba(0, 107, 255, 0.2)",
      connected: true,
    },
    {
      id: "2",
      name: "Hubspot",
      type: "Meeting Scheduler",
      summary: "This is ...",
      logo_image: "/images/IntegrationLogos/hubspot_logo.png",
      color: "rgba(245, 119, 34, 0.2)",
      connected: false,
    },
  ];

  function redirectToCalendlyAuth() {
    const clientId = "WMCng7JZhNR4aMtRlZ7BP-QO284lHxzw756gki9Kbrw";
    const redirectUri = "https://my.site.com/auth/calendly";
    const url = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;

    window.location.href = url;
  }

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Integrations</p>
      <SearchBarHeader />

      <div className={styles.integration_tiles}>
        {integrations.map((integration, index) => {
          return (
            <div className={styles.tile}>
              <div
                className={styles.tile_header}
                style={{ backgroundColor: integration.color }}
              >
                <img
                  className={styles.logo_image}
                  src={integration.logo_image}
                  alt="logo"
                ></img>
              </div>
              <div className={styles.content}>
                <p className={styles.title}>{integration.name}</p>
                <p className={styles.type}>{integration.type}</p>
                <p className={styles.summary}>{integration.summary}</p>
                <div
                  className={styles.connect_button}
                  onClick={redirectToCalendlyAuth}
                >
                  Connect
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Integrations;
